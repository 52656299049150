import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { get, isEmpty, unescape } from "lodash";
import { v4 as uuid } from "uuid";
import classNames from "classnames";
import Confetti from "react-confetti";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { selectHotelInfo, selectPricePolicyInfo } from "../HotelInfo";
import {
  selectHotelBookCallbackRes,
  selectHotelBookRes,
  selectLeadGuestInfo,
} from "./index";
import { selectHotelBookReqBody } from "./hotelBooking.selector";
import { selectHotelSearchFilters } from "../../components/organisms/Search";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Spinner, { SPINNER_NAMES } from "../../components/organisms/Spinner";
import { ROUTES, DEFAULT_VALUES, WINDOWS_EVENTS } from "../../constants";
import config from "../../config.json";
import {
  BookingSummary,
  BookingPricing,
  BookingImportantInfo,
  BookingLinks,
} from "../../components/organisms/HotelBookingInfoSections";

const { brandName } = config;
const { HOME } = ROUTES;
const { EMPTY_STRING, EMPTY_ARRAY, EMPTY_OBJECT, ONE, ZERO } = DEFAULT_VALUES;
const { POPSTATE } = WINDOWS_EVENTS;
const { HOTEL_BOOK_CALLBACK, HOTEL_BOOKING } = SPINNER_NAMES;
const CONFIRMED = "CONFIRMED";
const BookingStatusLoader = () => (
  <SkeletonTheme baseColor="lightgray" highlightColor="#14b8a5">
    <div className="relative w-full z-0">
      <Skeleton width={"100%"} height={180} duration={2} borderRadius={0} />
      <div className="absolute top-16 left-12 z-10 text-xl text-contrast-900 font-bold">
        Please wait, Confirming your booking with the hotel...
      </div>
    </div>
  </SkeletonTheme>
);

const HotelBookingResult = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const hotelBookingResponse = useSelector(selectHotelBookRes);
  const hotel = useSelector(selectHotelInfo);
  const hotelBookingRequest = useSelector(selectHotelBookReqBody);
  const hotelBookCallbackResponse = useSelector(selectHotelBookCallbackRes);
  const leadGuest = useSelector(selectLeadGuestInfo);
  const searchFilters = useSelector(selectHotelSearchFilters);
  const pricePolicyInfo = useSelector(selectPricePolicyInfo);
  const [bookingRequest, setBookingRequest] = useState(EMPTY_OBJECT);
  const [primaryGuest, setPrimaryGuest] = useState(EMPTY_OBJECT);
  const [paymentDetails, setPaymentDetails] = useState(EMPTY_OBJECT);
  const [isSpinnerActive, setIsSpinnerActive] = useState(true);

  const isVoucherBooking = get(
    hotelBookCallbackResponse,
    "output.voucherStatus",
    false
  );
  const bookingResponse =
  get(
    hotelBookCallbackResponse,
    "output"
  ) ||
  get(hotelBookingResponse, "output") ||
  EMPTY_ARRAY;

  const hotelRoomInfo = get(bookingResponse, "hotelRoomsDetails", []);
  const Inclusions = hotelRoomInfo.flatMap((room) => room.inclusion);
  const splittedInclusions = Inclusions.flatMap((inc) => inc.split(","));
  const hotelInformation = get(
    pricePolicyInfo,
    "blockRoomResult",
    EMPTY_OBJECT
  );
  const dayRates = get(
    hotelInformation,
    "hotelRoomsDetails[0].dayRates",
    EMPTY_ARRAY
  );

  const tavaBookingId = get(
    hotelBookingRequest,
    "hotelBookingRequest.bookingRequest.tavaBookingId",
    EMPTY_STRING
  );
  const hotelImages = get(
    hotel,
    "priceResult.hotelDetails.images",
    EMPTY_ARRAY
  );
  const lastCancellationDate = get(
    pricePolicyInfo,
    "blockRoomResult.hotelRoomsDetails[0].cancellationPolicies[0].fromDate",
    EMPTY_STRING
  );
  const { starRating } = hotelInformation || EMPTY_OBJECT;
  const {
    noOfNights = 0,
    noOfRooms = 0,
    roomGuests = [],
  } = searchFilters || EMPTY_OBJECT;
  const {
    hotelBookingStatus = EMPTY_STRING,
    bookingId = EMPTY_STRING,
    confirmationNo,
    invoiceNo,
  } = bookingResponse;
  const {
    hotelName = EMPTY_STRING,
    hotelRoomsDetails: hotelRoomsInfo = [],
    hotelPolicyDetail = EMPTY_STRING,
    addressLine1 = EMPTY_STRING,
    addressLine2 = EMPTY_STRING,
    hotelCode = EMPTY_STRING,
  } = bookingRequest;
  const cancellationPolicies =
    hotelRoomsInfo[0]?.CancellationPolicy || EMPTY_STRING;
  const cancellationMsgArr = cancellationPolicies
    ?.split("#^#")
    [ONE]?.replaceAll("#!#", "")
    ?.split("|")
    ?.filter((item) => item && item !== "undefined")
    ?.map((msg) => (
      <li className="list-item" key={uuid()}>
        {msg}
      </li>
    ));
  const totalAdults = roomGuests.reduce(
    (acc, curr) => acc + curr.noOfAdults,
    ZERO
  );
  const totalChilds = roomGuests.reduce(
    (acc, curr) => acc + curr.noOfChild,
    ZERO
  );
  const { title, firstName, lastName, email } = primaryGuest;
  const { amount, currency } = paymentDetails;
  const decodedHotelInfo = unescape(hotelPolicyDetail);
  const isBookingSuccessful = hotelBookingStatus.toUpperCase() === CONFIRMED;
  const onBackButtonEvent = (e) => {
    e.preventDefault();
    navigate(HOME);
  };

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener(POPSTATE, onBackButtonEvent);

    return () => window.removeEventListener(POPSTATE, onBackButtonEvent);
  }, []);

  useEffect(() => {
    if (!isEmpty(hotelBookingRequest)) {
      setBookingRequest(hotelBookingRequest.hotelBookingRequest.blockRoomRes?.blockRoomResult);
      setPaymentDetails(hotelBookingRequest.hotelBookingRequest.paymentRequest);
    }
  }, [hotelBookingRequest]);

  useEffect(() => {
    if (!isEmpty(leadGuest)) setPrimaryGuest(leadGuest.profileDetails);
  }, [leadGuest]);

  const hotelInfo = {
    hotelBookingStatus: hotelBookingStatus,
    addressLine1: addressLine1,
    addressLine2: addressLine2,
    hotelImages,
    hotelName: hotelName,
    hotelCode: hotelCode,
    starRating: starRating,
    cancellationMsgArr,
    decodedHotelInfo,
  };
  const bookingInfo = {
    lastCancellationDate,
    tavaBookingId,
    bookingId: bookingId,
    invoiceNo: invoiceNo,
    dayRates: dayRates,
    noOfNights: noOfNights,
    noOfRooms: noOfRooms,
    amount,
    currency,
    splittedInclusions,
  };
  const customerInfo = {
    firstName,
    lastName,
    title,
    email,
    adults: totalAdults,
    children: totalChilds,
  };

  return (
    <div className="relative z-0">
      <Helmet>
        <title>{brandName} | Hotel Booking Confirmation</title>
      </Helmet>
      {isSpinnerActive && (
        <div className="bg-red-700 text-white text-sm w-full px-8 py-2 text-center sticky top-[75px] z-10">
          {t("bookingResult.warningMsg")}
        </div>
      )}
      <Spinner
        name={[HOTEL_BOOK_CALLBACK, HOTEL_BOOKING]}
        loaderComponent={<BookingStatusLoader />}
        setIsSpinnerActive={setIsSpinnerActive}
      >
        {isBookingSuccessful && (
          <Confetti
            numberOfPieces={500}
            width={window.innerWidth * 0.95}
            initialVelocityX={10}
            gravity={0.1}
            recycle={false}
            tweenDuration={4000}
          />
        )}
        <div
          className={classNames("relative", {
            "bg-teal-200": isBookingSuccessful,
            "bg-red-200": !isBookingSuccessful,
          })}
        >
          <div
            className={classNames(
              "absolute w-full h-full top-0 left-0 bg-gradient-to-r from-contrast-900/0",
              {
                "to-teal-100/50": isBookingSuccessful,
                "to-red-900/50": !isBookingSuccessful,
              }
            )}
          ></div>
          <div className="container px-8 pt-8 pb-16 mx-auto relative">
            <h4 className="text-xl text-contrast-900 mb-1 font-bold">
              {isBookingSuccessful
                ? "Booking Successful"
                : "Booking Unsuccessful"}
            </h4>
            <div className="flex items-center gap-2 mb-2">
              {isBookingSuccessful && (
                <p className="text-contrast-600 text-sm">
                  {t("bookingResult.confirmationNumber")}:{" "}
                  <span className="mx-1 font-semibold text-contrast-900">
                    {confirmationNo}
                  </span>
                </p>
              )}
              <p className="text-contrast-600 text-sm">
                Tava Booking ID:{" "}
                <span className="mx-1 font-semibold text-contrast-900">
                  {tavaBookingId}
                </span>
              </p>
              {isBookingSuccessful && (
                <span className="font-semibold text-xs px-3 py-1 rounded-3xl text-teal-800 bg-teal-100">
                  {hotelBookingStatus}
                </span>
              )}
            </div>
            {isBookingSuccessful && (
              <div className="text-gray-800 text-sm">
                Confirmation and E-Vouchers sent to{" "}
                <span className="font-semibold text-teal-900">{email}</span>
              </div>
            )}
            {!isBookingSuccessful && (
              <div className="w-full text-md bg-red-100 p-4 rounded-md text-red-600 my-1 font-medium">
                {t("bookingResult.failureMsg")}
              </div>
            )}
          </div>
        </div>
      </Spinner>

      <div className="pb-16 relative">
        <div className="container px-8 mx-auto -mt-8">
          <div className="grid grid-cols-12 gap-8 ">
            <div className="col-span-12 xl:col-span-8 flex flex-col gap-4">
              <div className="flex flex-col gap-8">
                <BookingSummary
                  hotelInfo={hotelInfo}
                  bookingInfo={bookingInfo}
                  customerInfo={customerInfo}
                  isVoucherBooking={isVoucherBooking}
                  isSpinnerActive={isSpinnerActive}
                  showNavigateToMyTripOption
                />
              </div>

              <BookingPricing
                bookingInfo={bookingInfo}
                hotelRoomsInfo={hotelRoomsInfo}
                customerInfo={customerInfo}
              />
              <BookingImportantInfo decodedHotelInfo={decodedHotelInfo} />
            </div>
            <BookingLinks
              isSpinnerActive={isSpinnerActive}
              tavaBookingId={tavaBookingId}
              isVoucherBooking={isVoucherBooking}
              showNavigateToMyTripOption
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HotelBookingResult;
