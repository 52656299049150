import { DEFAULT_VALUES, BOOKING_STATUS } from "../../../constants";
import { DownloadIcon, RenderSVG } from "../../../assets/icons";

const { EMPTY_STRING } = DEFAULT_VALUES;
const { PENDING, CONFIRMED, CANCELLED, CAPTURED } = BOOKING_STATUS;

const getFormattedDate = (value) => {
  const date = new Date(value);
  const options = { weekday: "short", month: "long", day: "numeric" };
  return date.toLocaleDateString(undefined, options);
};

const getdownloadIcon = () => (
  <div>
    <RenderSVG Svg={DownloadIcon} className="cursor-pointer h-6 text-centre" />
  </div>
);

const getStyledCell = (value) => {
  const bgColor =
    {
      [PENDING]: { backgroundColor: "#fef3c7", color: "#b45309" },
      [CONFIRMED]: { backgroundColor: "#ccfbf1", color: "#0f766e" },
      [CANCELLED]: { backgroundColor: "#ffe4e6", color: "#be123c" },
      [CAPTURED]: { backgroundColor: "#ccfbf1", color: "#0f766e" },
    }[value] || EMPTY_STRING;
  const cellStyle = {
    textAlign: "center",
    padding: "4px 15px",
    borderRadius: "15px",
    ...bgColor,
  };

  return (
    <div>
      <div>
        <span style={cellStyle}>
          {value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()}
        </span>
      </div>
    </div>
  );
};

const StyledCellContent = ({ columnKey, value }) => {
  if (
    columnKey === "status" ||
    columnKey === "paymentStatus" ||
    columnKey === "ticketingStatus"
  )
    return getStyledCell(value);
  else if (columnKey === "createdAt" || (columnKey === "updatedAt" && value))
    return getFormattedDate(value);
  else if (columnKey == "bookingData") return getdownloadIcon();
  else return value;
};

export default StyledCellContent;
