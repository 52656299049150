import { createAsyncThunk } from "@reduxjs/toolkit";
import asyncAction from "../../infrastructure/asyncAction";
import { SPINNER_NAMES } from "../../components/organisms/Spinner";
import { REQUEST_METHODS } from "../../constants";

const { AUTH } = SPINNER_NAMES;
const { POST } = REQUEST_METHODS;

export const signUp = createAsyncThunk(
  "auth/sign-up",
  async (body, thunkArgs) => {
    return await asyncAction({
      url: `sign-up`,
      spinner: AUTH,
      methodType: POST,
      body,
      errorMessage: "Unable to register your acount, please try again later.",
      showErrorToast: true,
      ...thunkArgs,
    });
  }
);

export const login = createAsyncThunk("login", async (body, thunkArgs) => {
  return await asyncAction({
    url: "login",
    methodType: POST,
    spinner: AUTH,
    errorMessage: "Unable to login, please try again later",
    showErrorToast: true,
    body,
    ...thunkArgs,
  });
});

export const sendOtp = createAsyncThunk("send-otp", async (body, thunkArgs) => {
  return await asyncAction({
    url: "send-otp",
    methodType: POST,
    spinner: AUTH,
    errorMessage: "Unable to send-otp, please try again later",
    showErrorToast: true,
    body,
    ...thunkArgs,
  });
});

export const verfiyOtp = createAsyncThunk(
  "verify-otp",
  async (body, thunkArgs) => {
    return await asyncAction({
      url: "verify-otp",
      methodType: POST,
      spinner: AUTH,
      errorMessage: "Unable to verify-otp, please try again later",
      showErrorToast: true,
      body,
      ...thunkArgs,
    });
  }
);

export const resetPassword = createAsyncThunk(
  "auth/forgot-password",
  async (body, thunkArgs) => {
    return await asyncAction({
      url: "forgot-password",
      methodType: POST,
      spinner: AUTH,
      errorMessage: "Unable to reset password, please try again later",
      showErrorToast: true,
      body,
      ...thunkArgs,
    });
  }
);

export const regenerateAccessToken = createAsyncThunk(
  "auth/regenerate-access-token",
  async ({refreshToken, spinner}, thunkArgs) => {
    return await asyncAction({
      url: "token",
      methodType: POST,
      body: { refreshToken },
      abortOnPageChange: false,
      errorMessage: "Failed to refresh the session details.",
      spinner,
      ...thunkArgs,
    });
  }
);
