import { createAsyncThunk } from "@reduxjs/toolkit";
import asyncAction from "../../infrastructure/asyncAction";
import { REQUEST_METHODS } from "../../constants";
import { SPINNER_NAMES } from "../../components/organisms/Spinner";

const { POST } = REQUEST_METHODS;
const { FETCH_HOTEL_INFO, VERIFY_HOTEL_PRICE_PLOICY } = SPINNER_NAMES;

export const getHotelInfo = createAsyncThunk(
  "hotel-details",
  async (body, thunkArgs) => {
    return await asyncAction({
      url: "hotels/price",
      methodType: POST,
      body,
      spinner: FETCH_HOTEL_INFO,
      ErrorMessage: "Failed to load hotel info",
      ...thunkArgs,
    });
  }
);

export const verifyHotelPricePolicy = createAsyncThunk(
  "verify-price-policy",
  async (body, thunkArgs) => {
    return await asyncAction({
      url: "hotels/verify-price-policy",
      methodType: POST,
      body,
      spinner: VERIFY_HOTEL_PRICE_PLOICY,
      abortOnPageChange: false,
      ErrorMessage: "Failed to verify hotel price policy",
      ...thunkArgs,
    });
  }
);
