import { useEffect, useState } from "react";
import { get, isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import {
  DEFAULT_VALUES,
  CACHE_KEYS,
  ROUTES,
  DEFAULT_CURRENCY_CODE,
  BOOKING_STATUS,
  SEARCH_SECTION,
} from "../../../../constants";
import { closeRazorPayModal, getFromSessionStorage } from "../../../../helper";
import {
  bookCallback,
  setBookCallbackResponse,
  selectSelectedTravelers,
  updateBookingStatus,
  reissuanceCallback,
  getPaymentMethod,
} from "../index";
import { selectCountryInfo } from "../../../Profile";
import { selectFlightPriceReq } from "../../../FlightResults";
import config from "../../../../config.json";
import logEvent from "../../../../utils/GATracker";

const { brandName } = config;
const { EMPTY_OBJECT, EMPTY_STRING, ZERO, EMPTY_ARRAY } = DEFAULT_VALUES;
const { BOOKING_INFORMATION } = CACHE_KEYS;
const { FLIGHT_CONFIRM, FLIGHT_PRICE } = ROUTES;
const { CANCELLED } = BOOKING_STATUS;
const { FLIGHT } = SEARCH_SECTION;

const ADULT_TRAVELER_TYPE = "Adult";
const DEFAULT_PAYMENT_GATEWAY = "RAZORPAY";
const FLIGHTS_PAYMENT_MODAL_TITLE = "TavaTrip | Flights";
const MOCK_SESSION_ID = "MOCK_SESSION_ID";

const Payment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [razorpayPaymentObject, setRazorpayPaymentObject] = useState({});

  const selectedCountryInfo = useSelector(selectCountryInfo);
  const selectedTravelersInfo = useSelector(selectSelectedTravelers);
  const flightPriceReq = useSelector(selectFlightPriceReq) || EMPTY_ARRAY;

  const bookingInformation = getFromSessionStorage(BOOKING_INFORMATION);
  const currency = get(
    selectedCountryInfo,
    "currency.code",
    DEFAULT_CURRENCY_CODE
  );
  const { session, isReissuanceFlight, reissuanceDetails } = bookingInformation;

  const id = get(session, "id", EMPTY_STRING);
  const sessionId = get(session, "session_id", EMPTY_STRING);
  const grandTotal = get(session, "amount", ZERO);
  const tavaBookingId = get(bookingInformation, "tavaBookingId", EMPTY_STRING);

  const firstAdultTraveler = selectedTravelersInfo.find(
    (traveler) => traveler.travelerType === ADULT_TRAVELER_TYPE
  );
  const {
    email,
    phoneNumber,
    firstName: name,
  } = get(firstAdultTraveler, "profileDetails", EMPTY_OBJECT);
  const { source, endUserIp, resultIndex, traceId } =
    flightPriceReq[ZERO] || EMPTY_OBJECT;

    const handlePaymentFailure = (razorpayPaymentObject) => {
      const queryParams = {
        oderId: sessionId,
        serviceType: FLIGHT,
      };
      dispatch(
        getPaymentMethod({
          queryParams,
        })
      ).then(() => {
        navigate(FLIGHT_CONFIRM);
        closeRazorPayModal(razorpayPaymentObject);
        const razorpayContainer = document.querySelector(".razorpay-container");
        // Check if the Razorpay container exists, then remove it
        if (razorpayContainer) {
          razorpayContainer.remove();
        }
        const body = document.querySelector('body');
        // Remove the inline style for overflow
        body.style.overflow = '';
      });
    };
    
  const handleRazorpayPayment = () => {
    const bookCallbackQueryParams = {
      source,
      paymentSessionId: id,
      tavaBookingId,
      paymentGateway: DEFAULT_PAYMENT_GATEWAY,
      currency,
    };

    const updateBookingStatusRequest = {
      source,
      bookingStatus: CANCELLED,
      paymentStatus: CANCELLED,
    };

    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY,
      currency,
      amount: grandTotal,
      order_id: sessionId,
      name: FLIGHTS_PAYMENT_MODAL_TITLE,
      description: EMPTY_STRING,
      handler: (e) => {
        dispatch(setBookCallbackResponse(EMPTY_OBJECT));
        const paymentId = e.razorpay_payment_id;
        const queryParams = { ...bookCallbackQueryParams, paymentId };
        const reissuanceCallbackRequest = isReissuanceFlight && {
          queryParams,
          requestBody: {
            ...reissuanceDetails,
            endUserIp,
            ResultIndex: resultIndex,
            TraceId: traceId,
            tavaBookingId,
            isRefundable: false,
          },
        };
        isReissuanceFlight
          ? dispatch(reissuanceCallback(reissuanceCallbackRequest))
          : dispatch(
              bookCallback({
                queryParams,
              })
            );
        logEvent(`payment_success_navigating_to_flight_confirmation_page`);
        navigate(FLIGHT_CONFIRM);
      },
      modal: {
        ondismiss: () => {
          logEvent(`razorpay_payment_dismissed`);
          dispatch(setBookCallbackResponse(EMPTY_OBJECT));
          dispatch(
            updateBookingStatus({
              requestBody: updateBookingStatusRequest,
              id: tavaBookingId,
            })
          );
          const queryParams = {
            oderId: sessionId,
            serviceType: FLIGHT,
          };
          dispatch(
            getPaymentMethod({
              queryParams,
            })
          )
          navigate(FLIGHT_CONFIRM);
        },
      },
      prefill: {
        name,
        email,
        contact: phoneNumber,
      },
    };
    const rp = new window.Razorpay(options);
    setRazorpayPaymentObject(rp);
    document.body.style.overflow = "visible";
    rp.on("payment.failed", () => {
      handlePaymentFailure(rp); 
    });
  };

  useEffect(() => {
    if (!isEmpty(sessionId) && !isEmpty(selectedTravelersInfo)) {
      if (id === MOCK_SESSION_ID) {
        dispatch(bookCallback({ queryParams: EMPTY_OBJECT }));
        navigate(FLIGHT_CONFIRM);
      } else handleRazorpayPayment();
    } else navigate(FLIGHT_PRICE);

    return () => {
      if (!isEmpty(razorpayPaymentObject))
        closeRazorPayModal(razorpayPaymentObject);
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(razorpayPaymentObject)) {
      razorpayPaymentObject.open();
    }
  }, [razorpayPaymentObject]);

  return (
    <Helmet>
      <title>{brandName} | Flight Payment</title>
    </Helmet>
  );
};

export default Payment;
