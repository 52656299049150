import { useState, useEffect } from "react";
import classNames from "classnames";
import { DRAWER_SIZE } from "../../../constants";
import { Cross, RenderSVG } from "../../../assets/icons";
import { useTranslation } from "react-i18next";

const { FULL, LARGE, MD, SM } = DRAWER_SIZE;
const WINDOW_MAX_WIDTH = 1200;

const Drawer = ({
  size,
  heading,
  subHeading,
  children,
  position,
  handleReset = () => {},
  handleClose = () => {},
  footer,
  selectedDrawer,
  allowScrollBar,
  shouldOpen,
}) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(!!selectedDrawer || shouldOpen);
  }, [selectedDrawer, shouldOpen]);

  useEffect(() => {
    if (show) {
      window.onpopstate = () => {
        window.onpopstate = () => {};
        handleClose();
      };
    }
  }, [show, handleClose]);

  const handleResetValues = () => {
    handleReset();
    handleClose();
  };

  useEffect(() => {
    const handleResize = () =>
      window?.innerWidth >= WINDOW_MAX_WIDTH && handleClose();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (show)
    return (
      <div
        onClick={(e) => e.stopPropagation()}
        className={classNames(
          "fixed h-[calc(100vh-5rem)] flex flex-col shadow-xl bg-white top-20 z-[100] transition-all duration-900",
          {
            "w-full": size === FULL,
            "left-0": position === "left" && show,
            "d:-left-[400px] -left-80": position === "left" && !show,
            "right-0": position === "right" && show,
            "md:-right-[400px] -right-80": !show && size !== FULL,
            "-right-full": !show && size === FULL,
            "w-80 md:w-[900px]": size === LARGE,
            "w-[400px]": size === MD,
            "w-[250px]": size === SM,
            "scrollbar overflow-auto": allowScrollBar,
          }
        )}
      >
        <div
          className={classNames(
            "p-6 flex items-center border-b border-contrast-200",
            {
              "bg-contrast-800 border-b border-contrast-700": size === FULL,
              "bg-white": size !== FULL,
            }
          )}
        >
          <div>
            <h6
              className={classNames(
                "font-bold flex-1 text-2xl text-contrast-900",
                {
                  "mb-2": subHeading,
                }
              )}
            >
              {t(heading)}
            </h6>
            <p
              className={classNames("text-sm", {
                "text-contrast-500": size !== FULL,
                "text-contrast-50": size === FULL,
              })}
            >
              {t(subHeading)}
            </p>
          </div>
          <button
            type="button"
            className="ml-auto rounded-md fill-black"
            onClick={handleResetValues}
          >
            <RenderSVG Svg={Cross} alt="close" />
          </button>
        </div>
        <div className="scrollbar overflow-auto">
          {children}
          {footer}
        </div>
      </div>
    );
};

export default Drawer;
