import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import {
  cloneDeep,
  differenceWith,
  get,
  isEmpty,
  isEqual,
  size,
  toPairs,
} from "lodash";
import HotelsMapModal from "../../components/organisms/AppModals/HotelsMapModal/HotelsMapModal";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Helmet } from "react-helmet-async";
import { Filter, RenderSVG } from "../../assets/icons";
import {
  getObjectFromQueryParams,
  setToSessionStorage,
  getFormattedAddress,
  renderStars,
  generateHotelAddress,
  getHotelImageUrl,
} from "../../helper";
import {
  setHotelInfo,
  setHotelInfoReqBody,
  setSelectedHotelViewMore,
} from "../HotelInfo";
import Spinner, { SPINNER_NAMES } from "../../components/organisms/Spinner";
import {
  selectCurrentSearchLevel,
  selectFilteredHotels,
  selectHotelSearchFilters,
  selectHotels,
  selectHotelsActiveFilters,
  selectHotelsCount,
  selectHotelsSortingValues,
  setFilteredHotels,
  setHotelsActiveFilters,
  setHotelsCount,
  setHotelsSortingValues,
} from "../../components/organisms/Search";
import { setSelectedDrawer } from "../../components/organisms/Drawer";
import { selectCountryInfo } from "../Profile";
import NoHotelResults from "./NoHotelResults";
import FilterHotelsDropDown from "./HotelFilters/FilterHotelsDropDown";
import SearchHotelSection from "../../components/organisms/Search/SearchHotelSection";

import { DRAWERS } from "../../components/organisms/AppDrawers/drawer.constants";
import {
  ROUTES,
  CACHE_KEYS,
  DEFAULT_VALUES,
  INITIAL_HOTEL_VALUES,
  INITIAL_HOTEL_FILTERS,
  CURRENCY_SYMBOLS,
} from "../../constants";
import config from "../../config.json";
import { formatPriceWithCommas } from "../../helper/priceFormatter";
import { MODALS } from "../../components/organisms/AppModals";
import { setSelectedModal } from "../../components/organisms/Modal";
import { isEmptyObject } from "../../utils/validateObject";

const { brandName } = config;
const { HOTEL_INFO_REQUEST_BODY } = CACHE_KEYS;
const { HOTEL_INFO } = ROUTES;
const { FETCH_HOTEL_RESULTS, FETCH_HOTEL_STATIC_DATA } = SPINNER_NAMES;
const { ZERO, ONE, TWO, THREE, EMPTY_STRING, EMPTY_ARRAY, EMPTY_OBJECT } =
  DEFAULT_VALUES;
const { SHOW_HOTEL_FILTERS_DRAWER } = DRAWERS;
const { INR } = CURRENCY_SYMBOLS;

const Header = ({ resetFilters, setResetFilters, cityName }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [showHotelMap, setShowHotelMap] = useState(false);
  const selectedHotelResults = useSelector(selectHotels);
  const filteredHotels = useSelector(selectFilteredHotels);
  const hotelFilters = useSelector(selectHotelsActiveFilters);
  const actualfilters = useSelector(selectHotelsSortingValues);
  const hotelResults = get(selectedHotelResults, "hotelResults", EMPTY_ARRAY);
  const filtersCount = useSelector(selectHotelsCount);
  const [isFilterReseting, setIsFilterReseting] = useState(false);

  const getFiltersLength = (filters) => {

    if (isEmpty(filters)) return ZERO;
    const differencesCount = size(
      differenceWith(toPairs(actualfilters.price), toPairs(filters.price), isEqual)
    );

    let count = 0;
    if(differencesCount>=1){
      count = 1;
    }
    const diff = size(differenceWith(toPairs(filters), toPairs(INITIAL_HOTEL_FILTERS), isEqual))
    count += diff-1;
    return count;
  };

  useEffect(() => {
    const filterCount = getFiltersLength(hotelFilters);
    dispatch(setHotelsCount(filterCount));
    if (filterCount == 0) setIsFilterReseting(false);
  }, [hotelFilters]);

  const handleResetFilters = () => {
    setIsFilterReseting(true);
    dispatch(setFilteredHotels([]));
    dispatch(
      setHotelsActiveFilters({
        ...actualfilters,
        starRatings: [],
        locality: [],
        categories: [],
        price: {
          minPrice: actualfilters.minPrice,
          maxPrice: actualfilters.maxPrice,
        },
      })
    );
    // dispatch(setHotelsActiveFilters(actualfilters));
    setResetFilters(true);
    setIsFilterReseting(false);
    dispatch(setHotelsCount(0));
  };

  const hotelsWithLocation = filteredHotels.filter(
    (hotel) => !isEmpty(hotel.latitude) && !isEmpty(hotel.longitude)
  );

  const handleMapModalClose = () => setShowHotelMap(false);

  return (
    <div
      id="header"
      className="px-6 py-3 self-stretch justify-start items-center gap-6 inline-flex border rounded-md shadow-sm"
    >
      <div className="grow shrink  flex-col justify-center items-start inline-flex">
        <div className="self-stretch text-base font-semibold leading-7">
          <div>
            {cityName} {" : "}
            {filteredHotels.length}{" "}
            {filteredHotels.length === ONE
              ? "Property found"
              : "Properties found"}
          </div>
        </div>
      </div>
      <div className="pr-4 justify-start items-center gap-4 flex">
        {!isEmpty(hotelsWithLocation) && (
          <div
            onClick={() => setShowHotelMap(true)}
            className="lg:hidden text-primary-600 hover:text-primary-700 font-semibold hover:cursor-pointer justify-center items-center gap-2 flex relative"
          >
            Show On Map
          </div>
        )}
        {filtersCount > 0 && (
          <Spinner
            showSpinner={isFilterReseting}
            spinnerClassName={classNames(
              "w-full border border-contrast-300 rounded-md  bg-white hover:bg-contrast-50 active:bg-white shadow-sm px-4 py-2",
              { "px-12 py-[6px]": isFilterReseting }
            )}
          >
            <button
              className="flex items-center gap-2 text-sm text-contrast-600 font-medium justify-end"
              onClick={handleResetFilters}
              type="button"
            >
              <RenderSVG Svg={Filter} alt="Filter Icon" />
              {t("flightResults.resetFilter")}
            </button>
          </Spinner>
        )}
        {!isEmpty(hotelResults) && (
          <div className="lg:hidden pl-[15px] pr-[17px] py-[9px] bg-white rounded-md shadow border border-contrast-300 justify-center items-center gap-2 z-10 flex relative">
            <FilterHotelsDropDown
              resetFilters={resetFilters}
              setResetFilters={setResetFilters}
            />
          </div>
        )}
      </div>
      {showHotelMap && (
        <HotelsMapModal
          hotels={hotelsWithLocation}
          handleClose={handleMapModalClose}
        />
      )}
    </div>
  );
};

const HotelResultCard = ({ hotelInformation }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const selectedHotelResults = useSelector(selectHotels);
  const sourceData = get(selectedHotelResults, "sourceData", EMPTY_ARRAY);
  const filteredHotels = useSelector(selectFilteredHotels);

  const [imageError, setImageError] = useState(false);
  const [showHotelMapModal, setShowHotelMapModal] = useState(false);
  const searchFilters = useSelector(selectHotelSearchFilters);
  const { HOTEL_VIEW_MORE_MODAL } = MODALS;

  const selectedCountryInfo = useSelector(selectCountryInfo);
  const hotelSearchFilters = useSelector(selectHotelSearchFilters);

  const {
    resultIndex,
    hotelCode,
    hotelName,
    hotelAddress = {},
    starRating,
    price: { otherCharges, tax, roomPrice, tavaMarkup },
    hotelPicture,
    supplierHotelCodes,
    hotelPolicy,
    hotelImages,
    hotelAmenities = [],
    userRatings,
    hotelReviewCount,
    freeBreakfast,
    freeCancellation,
    payAtHotel,
    source,
    latitude,
    longitude,
  } = hotelInformation;

  const { checkInDate, checkOutDate } = searchFilters;

  const findTraceId = (sourceData, source) => {
    const data = sourceData.find((item) => item.source === source);
    return data ? data.traceId : null;
  };

  const traceId = findTraceId(sourceData, source);

  const displayedAmenities = hotelAmenities?.slice(0, 3);
  const [isExpanded, setExpanded] = useState(false);
  const totaltax = otherCharges + tax;
  const currencySymbol = get(selectedCountryInfo, "currency.symbol", INR);
  const categoryId = get(supplierHotelCodes, "[0].categoryId", EMPTY_STRING);

  const handleSelectHotel = () => {
    const { ip: endUserIp } = selectedCountryInfo;
    const hotelInfoAPIReq = {
      resultIndex,
      hotelCode,
      categoryId,
      endUserIp,
      traceId,
      checkInDate: checkInDate.replaceAll("/", "-"),
      checkOutDate: checkOutDate.replaceAll("/", "-"),
      source: "TBO", //TODO Need to eliminate use of specific provider name
    };
    dispatch(setHotelInfoReqBody(hotelInfoAPIReq));
    setToSessionStorage(HOTEL_INFO_REQUEST_BODY, hotelInfoAPIReq);
    dispatch(setHotelInfo({}));
    navigate(HOTEL_INFO.replace(":hotelId", hotelCode));
  };

  const addressLines = generateHotelAddress(hotelAddress);
  const hotelImage = getHotelImageUrl(hotelImages);

  const hotelsWithLocation = filteredHotels.filter(
    (hotel) => !isEmpty(hotel.latitude) && !isEmpty(hotel.longitude)
  );

  const handleMapModalClose = () => setShowHotelMapModal(false);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setExpanded(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [isExpanded]);
  }

  const showHotelAmenitiesButton = () => {
    dispatch(setSelectedModal(HOTEL_VIEW_MORE_MODAL));
    dispatch(
      setSelectedHotelViewMore({
        hotelAmenities,
        hotelImages,
        hotelPolicy,
        addressLines,
        hotelName,
      })
    );
  };

  const viewMoreHandler = () => {
    return hotelAmenities?.length > ZERO
      ? hotelAmenities?.length
      : !isEmptyObject(hotelImages)
      ? Object.keys(hotelImages)?.length
      : !isEmptyObject(hotelPolicy)
      ? Object.keys(hotelPolicy)?.length
      : ZERO;
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <div className="w-full mx-auto relative" ref={wrapperRef}>
      {freeBreakfast && (
        <div className="z-20 absolute top-[36px] -left-3 ">
          <div className="absolute w-[18px] h-[12px] skew-x-[45deg] rotate-45 -bottom-1 -left-0.5  bg-green-800" />
          <div className="relative bg-green-800 text-white text-xs text-center rounded-md rounded-bl-none p-1">
            Breakfast included
          </div>
        </div>
      )}
      <div className="border rounded-lg bg-white mb-3 hover:shadow-lg transition duration-400 ease-in-out w-full overflow-hidden">
        <div className="flex justify-between">
          <div className="relative px-4 flex flex-col items-center justify-between text-end">
            {userRatings && (
              <div className="absolute top-2 right-2">
                <div className="w-8 py-2 text-white text-center bg-primary-600 rounded-md font-medium text-xs rounded-bl-none">
                  {userRatings}
                </div>
                <div>
                  {hotelReviewCount && (
                    <span className="text-nowrap text-[10px]">
                      ({formatPriceWithCommas(hotelReviewCount)} reviews)
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="p-4 pr-5 w-full grid grid-cols-1 sm:grid-cols-3 gap-4 min-h-60">
          {imageError ? (
            <div className="flex justify-center items-center rounded-lg h-full w-full bg-black bg-opacity-30 px-4 py-2">
              <span className="text-white font-bold">{hotelName}</span>
            </div>
          ) : (
            <img
              src={hotelImage || hotelPicture}
              alt={hotelName}
              onError={() => setImageError(true)}
              className={"rounded-md object-cover aspect-square"}
              height="232"
              width="252"
            />
          )}

          <div className="col-span-2 flex flex-col sm:flex-row justify-between">
            <div className="flex flex-col justify-between overflow-wrap-anywhere">
              <div>
                <div className="flex items-center justify-between">
                  <div className="flex flex-col items-start">
                    <h2 className="text-start text-lg font-bold text-primary-900">
                      {hotelName[ZERO]?.toUpperCase() + hotelName.slice(ONE)}{" "}
                    </h2>
                    {starRating > 0 && (
                      <div className="text-sm text-start">
                        {renderStars(starRating)}
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  {addressLines && (
                    <p className="mt-1 text-[14px] text-start">
                      {addressLines}
                    </p>
                  )}
                  {latitude && longitude && (
                    <div
                      className="text-primary-700 text-[12px] text-start cursor-pointer hover:text-primary-900 w-fit"
                      onClick={() => {
                        setShowHotelMapModal(true);
                      }}
                    >
                      Show on Map
                    </div>
                  )}
                </div>
              </div>

              <div className="flex flex-col sm:flex-row justify-between ">
                <div className="mb-2 sm:mb-0">
                  <div className="pb-2 text-start">
                    {freeBreakfast && (
                      <div className="mt-1 text-green-800 font-semibold text-[10px]">
                        Breakfast included
                      </div>
                    )}
                    {freeCancellation && (
                      <div className="flex gap-2">
                        <RenderSVG
                          Svg={Check}
                          width="12"
                          height="12"
                          className="text-green-700"
                        />
                        <p className=" text-green-800 font-semibold text-[10px]">
                          Free Cancellation
                        </p>
                      </div>
                    )}
                    {payAtHotel && (
                      <div className="flex gap-2">
                        <RenderSVG
                          Svg={Check}
                          width="12"
                          height="12"
                          className="text-green-700"
                        />
                        <p className=" text-green-800 font-semibold text-[10px]">
                          No prepayment needed - pay at the property
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col border-l-2 pt-1 text-start">
                    {displayedAmenities?.map((amenity) => (
                      <div key={amenity} className="text-[12px] px-2">
                        {amenity}
                      </div>
                    ))}
                  </div>

                  <div>
                    {(!isEmpty(hotelPolicy) ||
                      !isEmpty(hotelAmenities) ||
                      !isEmpty(hotelImages)) && (
                      <button
                        onClick={showHotelAmenitiesButton}
                        className="flex text-primary-700 text-xs font-medium whitespace-nowrap pt-2"
                      >
                        {viewMoreHandler() > THREE && (
                          <span>
                            {isExpanded
                              ? "View Less"
                              : `View More (+${viewMoreHandler()})`}
                          </span>
                        )}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col justify-end items-end">
              <div className="flex md:flex-col md:items-end items-start justify-between mt-2 w-full">
                <p className="font-semibold text-lg">
                  {currencySymbol}&nbsp;
                  {formatPriceWithCommas(roomPrice + tavaMarkup)}
                </p>
                <p className="text-[9px] text-gray-800 text-end">
                  + {currencySymbol} {formatPriceWithCommas(totaltax)} taxes and
                  charges
                </p>
              </div>
              <button
                className="md:w-fit w-full mt-4 bg-primary-600 text-sm font-medium hover:bg-primary-800 text-white p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-600 text-nowrap disabled:cursor-not-allowed disabled:opacity-70"
                onClick={handleSelectHotel}
                // disabled={source != "TBO"}
              >
                See availability
              </button>
            </div>
          </div>
        </div>
      </div>

      {showHotelMapModal && (
        <HotelsMapModal
          hotels={hotelsWithLocation}
          handleClose={handleMapModalClose}
          defaultSelectedHotel={hotelInformation}
        />
      )}
    </div>
  );
};

const HotelResults = () => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const selectedHotelResults = useSelector(selectHotels);
  const hotelSearchFilters = useSelector(selectHotelSearchFilters);
  const hotelResults = get(selectedHotelResults, "hotelResults", EMPTY_ARRAY);
  const sourceData = get(selectedHotelResults, "sourceData", EMPTY_ARRAY);

  const { cityName } = getObjectFromQueryParams(search);
  const defaultExpandedValue = !useMediaQuery({ maxWidth: 1000 });

  const filteredHotels = useSelector(selectFilteredHotels);
  const hotelsActiveFilters = useSelector(selectHotelsActiveFilters);

  const [resetFilters, setResetFilters] = useState(false);
  const [loading, setLoading] = useState(false);
  const previousFiltersRef = useRef(hotelsActiveFilters);
  const containerRef = useRef(null);
  const [visibleHotels, setVisibleHotels] = useState([]);
  const [nextIndex, setNextIndex] = useState(0);
  const hotelsPerPage = 10;

  const loadMoreHotels = () => {
    if (loading) return;

    setLoading(true);
    const newHotels = filteredHotels.slice(
      nextIndex,
      nextIndex + hotelsPerPage
    );
    if (isEmpty(newHotels)) {
      setLoading(false);
      return;
    }
    setVisibleHotels((prev) => {
      const combinedHotels = [...prev, ...newHotels];
      const uniqueHotels = Array.from(
        new Set(combinedHotels.map((hotel) => hotel.resultIndex))
      ).map((resultIndex) =>
        combinedHotels.find((hotel) => hotel.resultIndex === resultIndex)
      );
      return uniqueHotels;
    });

    setNextIndex((prev) => prev + hotelsPerPage);
    setLoading(false);
  };

  const handleScroll = () => {
    if (!containerRef.current) return;
    const scrollTop = containerRef.current.scrollTop;
    const containerHeight = containerRef.current.clientHeight;
    const scrollHeight = containerRef.current.scrollHeight;
    if (scrollTop + containerHeight >= scrollHeight - 100) {
      setLoading(true);
      loadMoreHotels();
    }
  };

  useEffect(() => {
    if (isEmpty(filteredHotels)) {
      setVisibleHotels([]);
      return;
    }
    setVisibleHotels(filteredHotels.slice(0, hotelsPerPage));
    setNextIndex(0);
    loadMoreHotels();
  }, [filteredHotels]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [nextIndex, filteredHotels]);

  const getHotelPrice = (hotel) => {
    const {
      price: { roomPrice, tax, tavaMarkup, otherCharges },
    } = hotel;
    const totalPrice = roomPrice + tax + tavaMarkup + otherCharges;
    return totalPrice;
  };

  useEffect(() => {
    if (!hotelResults.length) return;
    dispatch(setHotelsActiveFilters(INITIAL_HOTEL_FILTERS));
    const updatedSortingValues = hotelResults.reduce((values, hotel) => {
      const price = getHotelPrice(hotel);
      const location = hotel.hotelLocation;
      const stars = hotel.starRating;
      const category = hotel.hotelCategory;

      values.price.minPrice = values.price.minPrice
        ? Math.min(values.price.minPrice, Math.floor(price))
        : Math.floor(price);
      values.price.maxPrice = values.price.maxPrice
        ? Math.max(values.price.maxPrice, Math.ceil(price))
        : Math.ceil(price);

        if (location) {
          values.locality[location] = (values.locality[location] || ZERO) + ONE;
        }
      stars &&
        (values.starRatings[stars] = (values.starRatings[stars] || ZERO) + ONE);
      category &&
        (values.categories[category] =
          (values.categories[category] || ZERO) + ONE);

      return values;
    }, cloneDeep(INITIAL_HOTEL_VALUES));

    updatedSortingValues.locality = Object.entries(updatedSortingValues.locality).map(
      ([name, count]) => ({ name, count })
    );
    updatedSortingValues.categories = Object.entries(updatedSortingValues.categories).map(
      ([name, count]) => ({ name, count })
    );

    updatedSortingValues.price.currency = get(
      hotelResults[0],
      "price.currencyCode",
      EMPTY_STRING
    );

    dispatch(setHotelsSortingValues(updatedSortingValues));
    dispatch(
      setHotelsActiveFilters({
        ...updatedSortingValues,
        starRatings: [],
        locality: [],
        categories: [],
        price: {
          minPrice: updatedSortingValues.price.minPrice,
          maxPrice: updatedSortingValues.price.maxPrice,
          currency: updatedSortingValues.price.currency,
        },
      })
    );
  }, [hotelResults, hotelSearchFilters]);

  useEffect(() => {
    // if (!isEqual(previousFiltersRef.current, hotelsActiveFilters)) {
    //   previousFiltersRef.current = hotelsActiveFilters;
      const filteredHotelResults = hotelResults.filter((hotel) => {
        const {
          price: { roomPrice },
        } = hotel;
        const price = getHotelPrice(hotel);
        const location = hotel.hotelLocation;
        const stars = hotel.starRating;
        const category = hotel.hotelCategory;
        
        const locationCheck =
          !isEmpty(hotelsActiveFilters.locality) &&
          !hotelsActiveFilters.locality.includes(location);
        const starsCheck =
          !isEmpty(hotelsActiveFilters.starRatings) &&
          !hotelsActiveFilters.starRatings.includes(String(stars));
        const categoryCheck =
          !isEmpty(hotelsActiveFilters.categories) &&
          !hotelsActiveFilters.categories.includes(category);
        const maxPriceCheck =
          hotelsActiveFilters.price.maxPrice &&
          price >= hotelsActiveFilters.price.maxPrice;
        const minPriceCheck =
          hotelsActiveFilters.price.minPrice &&
          price <= hotelsActiveFilters.price.minPrice;

        return !(
          locationCheck ||
          starsCheck ||
          categoryCheck ||
          maxPriceCheck ||
          minPriceCheck
        );
      });

      dispatch(setFilteredHotels(filteredHotelResults));
    // }
  }, [hotelsActiveFilters, hotelResults]);


  useEffect(() => {
    if (!isEmpty(filteredHotels)) {
      setVisibleHotels(filteredHotels.slice(0, hotelsPerPage));
      setNextIndex(hotelsPerPage);
    }
  }, [filteredHotels]);

  const renderEmptyLayout = () => {
    return (
      <div className="m-auto w-full overflow-hidden">
        {search ? (
          <NoHotelResults totalHotels={hotelResults.length} />
        ) : (
          <EmptyLayout />
        )}
      </div>
    );
  };
  
  return (
    <div className="flex-grow h-full mx-auto bg-white">
      <Helmet>
        <title>{brandName} | Hotels</title>
      </Helmet>

      <main className="relative pt-4 bg-white">
        <div className="sticky top-0 px-8 p-3 -mt-4 border-b z-30 bg-white border-contrast-200">
          <SearchHotelSection
            showEditSearch={true}
            defaultExpandedValue={defaultExpandedValue}
          />
        </div>
        <div className="container-hotel px-4 md:px-24 mx-auto">
          <div className="pt-3 h-full flex flex-col align-middle relative m-auto text-center">
            <Spinner
              name={[FETCH_HOTEL_RESULTS, FETCH_HOTEL_STATIC_DATA]}
              message={t("hotelResults.searching")}
              size="w-10 h-10"
            >
              <div className="flex flex-row gap-2 w-full">
                {!isEmpty(hotelResults) && (
                  <div className="hidden lg:block mr-2">
                    <FilterHotelsDropDown
                      resetFilters={resetFilters}
                      setResetFilters={setResetFilters}
                      showSideFilters={true}
                    />
                  </div>
                )}
                <div className="flex flex-col gap-2 w-full">
                  <div className="bg-white border-b-0 border-contrast-200 rounded-lg text-start flex-col justify-start items-start gap-6 inline-flex ml-2">
                    {!isEmpty(selectedHotelResults) && (
                      <Header
                        resetFilters={resetFilters}
                        setResetFilters={setResetFilters}
                        cityName={cityName}
                      />
                    )}
                  </div>

                  <div className="h-full w-full">
                    {!isEmpty(filteredHotels) ? (
                      <div
                        className="h-full lg:max-h-[69vh] pl-2 self-stretch flex-col justify-start items-start gap-2 flex overflow-auto "
                        ref={containerRef}
                      >
                        {visibleHotels.map((hotelInformation) => (
                          <HotelResultCard
                            key={hotelInformation.resultIndex}
                            hotelInformation={hotelInformation}
                            sourceData={sourceData}
                          />
                        ))}
                      </div>
                    ) : (
                      renderEmptyLayout()
                    )}
                  </div>
                </div>
              </div>
            </Spinner>
          </div>
        </div>
      </main>
    </div>
  );
};

export default HotelResults;
