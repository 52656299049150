import { useState, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import get from "lodash/get";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedModal } from "../Modal";
import LanguageSelector from "../LanguageSelector";
import { MODALS } from "../AppModals";
import { selectCountryInfo, selectUserInfo } from "../../../screens/Profile";
import { actions as profileActions } from "../../../screens/Profile/profile.reducer";
import {
  ROUTES,
  AUTHENTICATED_NAVBAR_CONTENT,
  DEFAULT_VALUES,
  USER_AUTH_FIELDS,
  AUTH_ROLES,
  DEFAULT_LANGUAGE_CODE,
  DEFAULT_CURRENCY,
  LOGO_WITH_BRAND,
} from "../../../constants";
import useClickOutside from "../../../helper/useClickOutside";
import {
  ChevronDown,
  Globe,
  Logout,
  Menu,
  UserCircle,
  LineSeparator,
  RenderSVG,
} from "../../../assets/icons";
import config from "../../../config.json";
import { signout, getFromLocalStorage } from "../../../helper";
import Spinner, { SPINNER_NAMES } from "../Spinner";
import { NavBarSkeleton } from "../AppSkeletons";

const { setCountryInfo } = profileActions;

const { logo, primaryColor, contrast } = config;
const {
  HOME,
  FLIGHT_RESULTS,
  SIGNUP,
  LOGIN,
  PROFILE,
  ADMIN_BOOKINGS,
  HOTEL_RESULTS,
} = ROUTES;
const { EMPTY_STRING } = DEFAULT_VALUES;
const { EMAIL } = USER_AUTH_FIELDS;
const { LOGIN_MODAL, SIGNUP_MODAL } = MODALS;
const { ADMIN } = AUTH_ROLES;
const { FETCH_USER, AUTH, PENDING } = SPINNER_NAMES;

const CURRENCY_TYPES = [
  { id: 1, currency: "Indian Rupee", code: "INR", symbol: "₹" },
  { id: 2, currency: "US Dollar", code: "USD", symbol: "$" },
  { id: 3, currency: "Euro", code: "EUR", symbol: "€" },
  { id: 4, currency: "Pound", code: "GBP", symbol: "£" },
  { id: 5, currency: "Dirham", code: "AED", symbol: "د.إ" },
  { id: 6, currency: "Sri Lankan Rupee", code: "LKR", symbol: "Rs" },
];

const AUTH_TABS = [
  {
    id: 2,
    name: "navbar.login",
    type: LOGIN,
  },
  {
    id: 3,
    name: "navbar.register",
    type: SIGNUP,
  },
];

const NavbarTabs = ({
  tabs,
  isAuthenticated,
  isAdmin,
  handleLoginSignUp,
  routesToHideHomeButton,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const updatedTabs = isAuthenticated ? tabs : AUTH_TABS;
  const isAuthtab = (name) =>
    ["navbar.login", "navbar.register"].includes(name);

  return (
    <>
      {routesToHideHomeButton && (
        <button
          className="text-primary-600 hover:text-primary-800 font-medium cursor-pointer text-start"
          onClick={() => navigate(HOME)}
        >
          {t("navbar.home")}
        </button>
      )}
      {updatedTabs.map(({ id, name, routePath, type }) => (
        <li
          key={id}
          className={classNames({ "lg:hidden": isAuthtab(name) || isAdmin })}
        >
          <div
            className={classNames(
              "text-primary-600 hover:text-primary-800 font-medium cursor-pointer",
              { "text-primary-800": location.pathname === routePath }
            )}
            onClick={() =>
              routePath ? navigate(routePath) : handleLoginSignUp(type)
            }
          >
            {t(name)}
          </div>
        </li>
      ))}
    </>
  );
};

const Selector = ({ options, title, selected }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleUserCurrencyChange = (currency, code, symbol) => {
    const updatedCurrency = {
      currency: {
        code,
        name: currency,
        symbol,
      },
    };
    dispatch(setCountryInfo(updatedCurrency));
  };

  return (
    <div className="w-auto sm:w-40">
      <h4 className="text-contrast-900 font-semibold text-base mb-6 whitespace-nowrap overflow-x-hidden">
        {t(title)}
      </h4>
      <ul className="flex flex-col gap-4">
        {options.map(({ id, currency, code, symbol }) => (
          <li className="py-0.5" key={id}>
            <label className="flex items-center gap-2 cursor-pointer">
              <input
                type="radio"
                className="text-primary-600 focus:ring-0"
                name={title}
                checked={code === selected}
                onChange={() =>
                  handleUserCurrencyChange(currency, code, symbol)
                }
              />
              <span className="text-sm font-medium text-contrast-700">
                {currency}
              </span>
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
};

const NavBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const selectedCountryInfo = useSelector(selectCountryInfo);

  const NOT_HOME_PAGE_ROUTE = location.pathname !== HOME;
  const routesToHideHomeButton = location.pathname !== HOME;
  const isFlightResultsPage = location.pathname.includes(FLIGHT_RESULTS);
  const isHotelResultsPage = location.pathname.includes(HOTEL_RESULTS);
  const userInfo = useSelector(selectUserInfo);

  const authInfo = getFromLocalStorage(AUTH);

  const isUserAuthenticated = !!userInfo?.id;
  const storedLanguageCode = get(
    selectedCountryInfo,
    "language.code",
    DEFAULT_LANGUAGE_CODE
  );
  const storedCurrency = get(selectedCountryInfo, "currency", DEFAULT_CURRENCY);
  const { code: currencyCode, symbol: currencySymbol } = storedCurrency;
  const isAdmin = userInfo?.role === ADMIN;

  const [menuBtnClicked, setMenuBtnClicked] = useState(false);
  const [selectorBtnClicked, setSelectorBtnClicked] = useState(false);
  const [profileButtonClicked, setProfileButtonClicked] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const languageSelectorRef = useRef();
  const navMenuRef = useRef();
  const profileMenuRef = useRef();

  useClickOutside(languageSelectorRef, setSelectorBtnClicked);
  useClickOutside(profileMenuRef, setMenuBtnClicked);
  useClickOutside(navMenuRef, setProfileButtonClicked);

  const PROFILE_TABS = [
    {
      id: 1,
      name: "navbar.profile",
      tabLogo: UserCircle,
      show: true,
      handleOnClick: () => {
        navigate(PROFILE);
        setMenuBtnClicked(false);
      },
    },
    {
      id: 2,
      name: "Admin",
      show: userInfo?.role === ADMIN,
      tabLogo: UserCircle,
      handleOnClick: () => {
        navigate(ADMIN_BOOKINGS);
        setMenuBtnClicked(false);
      },
    },
    {
      id: 3,
      name: "navbar.logout",
      show: true,
      tabLogo: Logout,
      handleOnClick: () => userInfo && signout(userInfo),
    },
  ];

  const handleLoginSignUp = useCallback((type) => {
    dispatch(setSelectedModal(type === LOGIN ? LOGIN_MODAL : SIGNUP_MODAL));
    setMenuBtnClicked(false);
  });

  const handleSpinner = useCallback((name, spinners) => {
    const showSpinner = name.some((spinnerName) =>
      spinners.some(
        (spinner) =>
          spinnerName === spinner || (spinner === PENDING && authInfo)
      )
    );
    setIsLoading(true);
    setShowSpinner(showSpinner);
    return showSpinner;
  }, []);

  const userEmail = get(userInfo, EMAIL, EMPTY_STRING) || EMPTY_STRING;

  return (
    <nav
      className={classNames(
        "py-3 bg-white border-b border-contrast-200 w-full z-50 print:hidden",
        { "fixed top-0": !isFlightResultsPage && !isHotelResultsPage }
      )}
    >
      <div className="px-6 flex justify-between items-center sm:gap-10 relative">
        <div
          className={classNames("flex h-9 w-36", {
            "cursor-pointer": NOT_HOME_PAGE_ROUTE,
          })}
          onClick={() => NOT_HOME_PAGE_ROUTE && navigate(HOME)}
        >
          <img
            src={LOGO_WITH_BRAND || logo}
            alt="brand-icon"
            className="hidden md:inline-block"
          />
          <img src={logo} alt="brand-icon" className="inline-block md:hidden" />
        </div>
        <div className="w-full">
          <Spinner
            size="w-full"
            name={[AUTH, FETCH_USER]}
            showSkeleton={true}
            loaderComponent={isLoading && <NavBarSkeleton />}
            customSpinnerMethod={handleSpinner}
            showSpinner={showSpinner}
          >
            <div className="flex justify-between sm:gap-10 relative">
              <div
                className={classNames(
                  `navbar-nav z-10 lg:!flex right-6 top-full absolute lg:static bg-white lg:shadow-none shadow-2xl lg:p-0 p-6 rounded-lg border lg:border-0 mt-2 lg:mt-0`,
                  { hidden: !profileButtonClicked }
                )}
                ref={navMenuRef}
              >
                <ul className="flex flex-col lg:flex-row lg:items-center gap-4 lg:gap-10">
                  <NavbarTabs
                    tabs={AUTHENTICATED_NAVBAR_CONTENT}
                    isAuthenticated={isUserAuthenticated}
                    isAdmin={isAdmin}
                    handleLoginSignUp={handleLoginSignUp}
                    routesToHideHomeButton={routesToHideHomeButton}
                  />
                </ul>
              </div>
              <div className="ms-auto flex items-center gap-2 lg:gap-5">
                {!NOT_HOME_PAGE_ROUTE && (
                  <div className="relative">
                    <div
                      className="bg-contrast-100 rounded-lg pr-2 pl-4 py-2 flex gap-2 items-center cursor-pointer"
                      onClick={() => setSelectorBtnClicked((pre) => !pre)}
                    >
                      <div className="flex gap-2">
                        <div className="icon shrink-0">
                          <RenderSVG
                            Svg={Globe}
                            alt="Globe icon"
                            className="text-contrast-600"
                          />
                        </div>
                        <span className="text-sm font-medium text-contrast-600 hidden sm:block uppercase">
                          {storedLanguageCode}
                        </span>
                      </div>

                      <RenderSVG
                        Svg={LineSeparator}
                        alt="Separator Line"
                        stroke={contrast}
                        className="text-contrast-600"
                      />

                      <div className="flex gap-2 items-center justify-center">
                        <div className="icon shrink-0 text-contrast-600">
                          {currencySymbol}
                        </div>
                        <span className="text-sm font-medium text-contrast-600 hidden sm:block">
                          {currencyCode}
                        </span>
                      </div>
                      <RenderSVG
                        Svg={ChevronDown}
                        alt="Down Arrow"
                        className={classNames("text-contrast-600", {
                          "rotate-180": selectorBtnClicked,
                        })}
                      />
                    </div>
                    {selectorBtnClicked && (
                      <div
                        className="dropdown-menu absolute z-30 top-full bg-white p-6 rounded-lg border border-contrast-200 shadow-2xl mt-2 -right-10"
                        ref={languageSelectorRef}
                      >
                        <div className="flex gap-6 flex-col lg:flex-row">
                          <LanguageSelector />
                          <Selector
                            options={CURRENCY_TYPES}
                            title="navbar.select.currency"
                            selected={currencyCode}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {!isUserAuthenticated ? (
                  <div className="gap-2 hidden lg:flex">
                    <button
                      className="py-2 px-4 rounded-md bg-primary-600 hover:bg-primary-700 active:bg-primary-600 shadow-sm text-sm text-white font-medium"
                      onClick={() => handleLoginSignUp(LOGIN)}
                    >
                      {t("navbar.login")}
                    </button>
                    <button
                      className="py-2 px-4 rounded-md bg-white hover:bg-contrast-50 active:bg-white border border-contrast-300 shadow-sm text-sm text-contrast-700 font-medium"
                      onClick={() => handleLoginSignUp(SIGNUP)}
                    >
                      {t("navbar.register")}
                    </button>
                  </div>
                ) : (
                  <div className="relative">
                    <div
                      className="p-2 border border-contrast-300 rounded-full flex gap-2 items-center cursor-pointer"
                      onClick={() => setMenuBtnClicked((pre) => !pre)}
                    >
                      <div className="avatar w-8 h-8 rounded-full bg-contrast-600 text-white grid place-content-center">
                        <span className="uppercase text-sm font-bold">
                          {userEmail.charAt(0)}
                        </span>
                      </div>
                      <RenderSVG
                        Svg={ChevronDown}
                        alt="Down Arrow"
                        width="24"
                        height="24"
                      />
                    </div>

                    {menuBtnClicked && (
                      <div
                        className="dropdown-menu w-36 absolute z-10 top-full rounded-lg border border-contrast-200 shadow-2xl mt-2 right-0"
                        ref={profileMenuRef}
                      >
                        <ul className="bg-white divide-y">
                          {PROFILE_TABS.filter(({ show }) => show).map(
                            ({ id, tabLogo, handleOnClick, name }) => (
                              <li key={id} className="cursor-pointer">
                                <div
                                  className="hover:bg-primary-100 flex items-center gap-2 p-4"
                                  onClick={handleOnClick}
                                >
                                  <div className="icon">
                                    <RenderSVG
                                      Svg={tabLogo}
                                      alt={`${name}_icon`}
                                    />
                                  </div>
                                  <span className="text-contrast-900">
                                    {t(name)}
                                  </span>
                                </div>
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                    )}
                  </div>
                )}

                <button
                  className={classNames(
                    "p-2 border border-contrast-300 rounded-full grid lg:hidden",
                    {
                      hidden: isAdmin,
                    }
                  )}
                  onClick={() => setProfileButtonClicked((pre) => !pre)}
                  ref={navMenuRef}
                >
                  <RenderSVG
                    Svg={Menu}
                    width="32"
                    height="32"
                    alt="Menu icon"
                    stroke={primaryColor}
                  />
                </button>
              </div>
            </div>
          </Spinner>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
