import { createSlice } from "@reduxjs/toolkit";
import {
  initiateHotelBooking,
  hotelBookCallback,
} from "./hotelBooking.actions";

const initialState = {
  leadGuestInfo: {},
  otherGuests: [],
  hotelBookReqBody: {},
  hotelBookRes: {},
  hotelBookCallbackRes: {},
};

const slice = createSlice({
  name: "hotelBooking",
  initialState,
  reducers: {
    setLeadGuestInfo: (state, action) => {
      state.leadGuestInfo = action.payload;
    },
    setOtherGuests: (state, action) => {
      state.otherGuests = action.payload;
    },
    setHotelBookReqBody(state, action) {
      state.hotelBookReqBody = action.payload;
    },
    setHotelBookRes(state, action) {
      state.hotelBookRes = action.payload;
    },
    setHotelBookCallbackRes(state, action) {
      state.hotelBookCallbackRes = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initiateHotelBooking.fulfilled, (state, action) => {
        if (!action.payload) return;
        state.hotelBookRes = action.payload.output;
      })
      .addCase(hotelBookCallback.fulfilled, (state, action) => {
        state.hotelBookCallbackRes = action.payload;
      });
  },
});

export const { name, reducer, actions } = slice;
export default slice;
