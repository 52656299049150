import { get } from "lodash";
import { DEFAULT_VALUES, GUEST_TYPES } from "../constants";

const { ZERO } = DEFAULT_VALUES;
const { ADULT, CHILD } = GUEST_TYPES;

export const mapGuestDetails = (
  leadGuestInfo,
  otherGuestsInfo,
  isSamePANForAllAllowed,
  roomGuests,
  index
) => {
  const {
    title = "",
    firstName = "",
    middleName = "",
    lastName = "",
    email = "",
    phoneNumber = "",
  } = get(leadGuestInfo, "profileDetails", {});
  const passportNumber = get(
    leadGuestInfo,
    "passportDetails.passportNumber",
    null
  );
  const leadGuestPAN = get(leadGuestInfo, "panCardDetails.panCardNumber", null);
  let adultGuests = [];
  let childrenGuests = [];

  otherGuestsInfo.forEach((guest) => {
    const {
      roomIndex,
      profileDetails: { isChild },
    } = guest;
    if (roomIndex === index) {
      isChild ? childrenGuests.push(guest) : adultGuests.push(guest);
    }
  });
  const firstAdultGuestId = adultGuests[ZERO]?.id;

  let mappedGuestDetails =
    index === ZERO
      ? [
          {
            title: title,
            firstName: firstName,
            middlename: null,
            lastName: lastName,
            phoneno: phoneNumber,
            email: email,
            paxType: ADULT,
            isLeadPassenger: true,
            ...(passportNumber && { PassportNo: passportNumber }),
            ...(leadGuestPAN && { pan: leadGuestPAN }),
          },
        ]
      : [];

  adultGuests.forEach((guest) => {
    const {
      title = "",
      firstName = "",
      lastName = "",
    } = get(guest, "profileDetails", {});
    const passportNumber = get(guest, "passportDetails.passportNumber", null);
    const panCardNumber = get(guest, "panCardDetails.panCardNumber", null);
    const guestPanNumber = isSamePANForAllAllowed ? leadGuestPAN : panCardNumber;

    const formattedGuestInfo = {
      title: title,
      firstName: firstName,
      middlename: middleName,
      lastName: lastName,
      phoneno: phoneNumber,
      email: email,
      paxType: ADULT,
      isLeadPassenger: index > ZERO && guest.id === firstAdultGuestId,
      ...(passportNumber && { PassportNo: passportNumber }),
      ...(guestPanNumber && { pan: guestPanNumber }),
    };
    mappedGuestDetails.push(formattedGuestInfo);
  });

  childrenGuests.forEach((guest, childIndex) => {
    const {
      title = "",
      firstName = "",
      middleName = null,
      lastName = "",
    } = get(guest, "profileDetails", {});
    const passportNumber = get(guest, "passportDetails.passportNumber", null);
    const panCardNumber = get(guest, "panCardDetails.panCardNumber", null);
    const guestPanNumber = isSamePANForAllAllowed ? leadGuestPAN : panCardNumber;

    const formattedGuestInfo = {
      title: title,
      firstName: firstName,
      middlename: middleName,
      lastName: lastName,
      phoneno: phoneNumber,
      email: email,
      paxType: CHILD,
      age: get(roomGuests, `${index}.childAge[${childIndex}]`, ZERO),
      isLeadPassenger: false,
      ...(passportNumber && { PassportNo: passportNumber }),
      ...(guestPanNumber && { pan: guestPanNumber }),
    };
    mappedGuestDetails.push(formattedGuestInfo);
  });

  return mappedGuestDetails;
};
