import * as Yup from "yup";
import { getRequiredErrorMessage } from "./getErrorMessage";
import { REGEX } from "../constants";

const { NAME, PASSPORT_NUMBER, PANCARD_NUMBER } = REGEX;

export const getPrimaryPaxValidationSchema = (t = () => {}) => ({
  firstName: Yup.string()
    .matches(NAME, t("profilePage.errors.nameFormat"))
    .required(getRequiredErrorMessage("profilePage.firstName", t))
    .min(2, t("profilePage.errors.minLength"))
    .max(25, t("profilePage.errors.maxLength")),
  middleName: Yup.string()
    .matches(NAME, t("profilePage.errors.nameFormat"))
    .max(25, t("profilePage.errors.maxLength")),
  lastName: Yup.string()
    .matches(NAME, t("profilePage.errors.nameFormat"))
    .required(getRequiredErrorMessage("profilePage.lastName", t))
    .min(2, "Last Name must have atleast 2 letters")
    .max(40, t("profilePage.errors.maxLength")),
});

export const getPassportValidationSchema = () => ({
  passportDetails: Yup.object().shape({
    passportNumber: Yup.string()
      .required("Passport Number is required")
      .matches(PASSPORT_NUMBER, "Invalid Passport Number"),
    issueCountry: Yup.object().shape({
      isoCode: Yup.string().required("Issuing Country is required"),
    }),
    issuedDate: Yup.string().required("Issuing Date is required"),
    expiryDate: Yup.string().required("Expiry Date is required"),
  }),
});

export const getPanValidationSchema = () => ({
  panCardDetails: Yup.object().shape({
    panCardNumber: Yup.string()
      .required("PAN number is required")
      .matches(PANCARD_NUMBER, "Invalid PAN number"),
  }),
});
