import classNames from "classnames";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FlightIcon,
  FlightDarkIcon,
  HotelsIcon,
  HotelsDarkIcon,
  RenderSVG,
} from "../../assets/icons";
import { DEFAULT_VALUES } from "../../constants";
import SearchSection from "../../components/organisms/Search";
import SearchHotelSection from "../../components/organisms/Search/SearchHotelSection";

const { ZERO } = DEFAULT_VALUES;

const HYBRID_SEARCH_TABS = [
  {
    id: "1",
    name: "searchSection.flights",
    activeIcon: FlightIcon,
    nonActiveIcon: FlightDarkIcon,
    renderSearchSection: <SearchSection showEditSearch={false} />,
  },
  {
    id: "2",
    name: "searchSection.hotels",
    activeIcon: HotelsIcon,
    nonActiveIcon: HotelsDarkIcon,
    renderSearchSection: <SearchHotelSection showEditSearch={false} />,
  },
];

const HybridSearch = () => {
  const [selectedTab, setSelectedTab] = useState(ZERO);
  const { t } = useTranslation();

  const handleTabClick = (index) => {
    setSelectedTab(index);
  };

  return (
    <>
      <ul className="rounded-t-2xl overflow-hidden inline-flex divide-x divide-primary-200">
        {HYBRID_SEARCH_TABS.map(
          ({ id, name, activeIcon, nonActiveIcon }, index) => (
            <li key={id}>
              <span
                className={classNames(
                  `flex gap-2 items-center text-sm p-4 sm:p-6 cursor-pointer`,
                  {
                    "bg-primary-900 text-white": selectedTab === index,
                    "bg-primary-100 text-primary-600 font-medium":
                      selectedTab !== index,
                  }
                )}
                onClick={() => handleTabClick(index)}
              >
                <RenderSVG
                  Svg={selectedTab === index ? activeIcon : nonActiveIcon}
                  className={classNames("text-primary-800", {
                    "text-inherit": name === "searchSection.flights",
                  })}
                  alt="Flight Icon"
                />
                <span>
                  {name === "searchSection.vacationPackages" ? (
                    <span>
                      {t("searchSection.vacation")}{" "}
                      <span className="hidden sm:inline">
                        {t("searchSection.packages")}
                      </span>
                    </span>
                  ) : (
                    t(name)
                  )}
                </span>
              </span>
            </li>
          )
        )}
      </ul>
      <div className="p-6 rounded-b-2xl rounded-tr-2xl bg-primary-900 text-white">
        {HYBRID_SEARCH_TABS[selectedTab].renderSearchSection}
      </div>
    </>
  );
};

export default HybridSearch;
