import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSessionInfo } from "../../screens/session";
import { actions as searchActions } from "../../components/organisms/Search/search.reducer";
import { actions as flightResultsActions } from "../../screens/FlightResults/flightResults.reducer";
import { actions as flightBookingsActions } from "../../screens/Booking/FlightBookings/flightBookings.reducer";
import {
  setToSessionStorage,
  setUserCurrentCountryConfigs,
} from "../../helper";
import { selectCountryInfo, setCountryInfo } from "../../screens/Profile";
import axios from "axios";
import { get } from "lodash";
import Loader from "../../components/organisms/Spinner/Loader";
import SessionExpiredModal from "./SessionExpiredModal";
import {
  DEFAULT_VALUES,
  CACHE_KEYS,
  ROUTES,
  API_RESPONSES,
} from "../../constants";

const { EMPTY_OBJECT } = DEFAULT_VALUES;
const { SESSION_ID } = CACHE_KEYS;
const { SESSION_EXPIRED } = API_RESPONSES;
const { FLIGHT_RESULTS, FLIGHT_PRICE } = ROUTES;

const { setSearch } = searchActions;
const { setFlightResults } = flightResultsActions;
const { setFlightBooking } = flightBookingsActions;

const SessionProvider = ({ children }) => {
  const dispatch = useDispatch();
  const countryInfo = useSelector(selectCountryInfo);
  const [shouldAllow, setShouldAllow] = useState(false);
  const [sessionId, setSessionId] = useState();
  const [isExpired, setIsExpired] = useState(false);

  const populateReduxWithSessionData = (sessionData, sessionParams) => {
    const { search, pricing, booking } = sessionData;
    const flightPriceReq = pricing.flightPriceReq;
    const searchFilterURL = search.searchFilterURL;
    const currentURL = window.location.href;
    if (currentURL.includes(FLIGHT_PRICE) && !flightPriceReq) {
      const flightResultURL = `${FLIGHT_RESULTS}${searchFilterURL}&sessionId=${sessionParams}`;
      window.location.assign(flightResultURL);
    }
    dispatch(setSearch(search));
    dispatch(setFlightResults(pricing));
    dispatch(setFlightBooking(booking));
    setShouldAllow(true);
  };

  const getSessionDetails = (data) => {
    const [id, encodedParentIP] = data.split("_") || [];
    const decodedParentIP = encodedParentIP && atob(encodedParentIP);
    return { decodedParentIP, id, encodedParentIP };
  };

  // Fetch user's system IP address & country.
  useEffect(() => {
    async function fetchSystemIpAndCountryInfo() {
      let ip,
        countryCode = "IN";
      // Attempt 1: To get system IP.
      try {
        const ipInfoRes = await axios.get("https://api.ipify.org/?format=json");
        ip = get(ipInfoRes, "data.ip", "");
      } catch (e) {
        console.log("Unable to get system IP via /api.ipify.org", e);
      }
      // Attempt 2: To get ip & country info.
      try {
        const countryInfoRes = await axios.get(`https://api.country.is`);
        if (!ip) ip = get(countryInfoRes, "data.ip", "");
        countryCode = get(countryInfoRes, "data.country", "IN");
        const countryInfo = setUserCurrentCountryConfigs(countryCode, ip);
        dispatch(setCountryInfo(countryInfo));
      } catch (e) {
        console.log(
          "Unable to get IP, countryCode via /api.country.is & /api.ipify.org",
          e
        );
      }
    }
    if (!countryInfo) fetchSystemIpAndCountryInfo();
  }, [dispatch, countryInfo]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const sessionIdFromURL = searchParams.get(SESSION_ID);
    if (!sessionIdFromURL) return setShouldAllow(true);
    else setSessionId(sessionIdFromURL);
  }, []);

  useEffect(() => {
    if (!countryInfo || !sessionId) return;
    const { decodedParentIP, id, encodedParentIP } =
      getSessionDetails(sessionId);
    dispatch(fetchSessionInfo(sessionId)).then((res) => {
      const message = get(res, "payload.data.message", "").toLowerCase().trim();
      const isSessionExpired = message === SESSION_EXPIRED;
      if (isSessionExpired) {
        setIsExpired(true);
        return;
      }
      const { payload } = res;
      if (!payload) return;
      const { ip: currentIp } = countryInfo;
      const encodedCurrentIP = btoa(currentIp);
      const sessionParams = `${id}_${encodedCurrentIP}`;
      if (currentIp !== decodedParentIP) {
        const url = new URL(window.location.href);
        url.searchParams.set(SESSION_ID, sessionParams);
        window.history.replaceState(null, null, url);
      }
      const sessionDetails = {
        sessionParams,
        encodedParentIP,
        encodedCurrentIP,
        decodedParentIP,
        sessionId: id,
      };
      setToSessionStorage(SESSION_ID, sessionDetails);

      const { reduxState: sessionData } = payload.session || EMPTY_OBJECT;
      if (sessionData) populateReduxWithSessionData(sessionData, sessionParams);
      else {
        sessionStorage.removeItem("sessionId");
        let url = new URL(window.location.href);
        url.searchParams.delete("sessionId");
        window.location.assign(url);
      }
    });
  }, [dispatch, countryInfo]);

  if (shouldAllow) return <>{children}</>;
  else if (isExpired) return <SessionExpiredModal />;
  else
    return (
      <div className="w-full items-center flex justify-center h-screen flex-col gap-3">
        <Loader size="w-12 h-10" />
        <span className="">Loading... Please wait!</span>
      </div>
    );
};

export default SessionProvider;
