import AirlineFilters from "../../../screens/FlightResults/FlightFilters/AirlineFilters";
import FlightEmissionFilter from "../../../screens/FlightResults/FlightFilters/FlightEmissionFilter";
import InternationalFlightFilters from "../../../screens/FlightResults/FlightFilters/InternationalFlightFilters";
import NoOfStopsFilters from "../../../screens/FlightResults/FlightFilters/NoOfStopsFilters";
import PriceFilter from "../../../screens/FlightResults/FlightFilters/PriceFilter";
import TimeFrameFilters from "../../../screens/FlightResults/FlightFilters/TimeFrameFilters";
import TravelBaggagesFilters from "../../../screens/FlightResults/FlightFilters/TravelBaggagesFilters";

export const renderFilters = (props) => {
  const {
    key,
    interfaceType,
    handleFilterChange,
    handleTimeFilterChange,
    minTime,
    maxTime,
    flightSearchOptions,
    activeFiltersSearchOptions,
    label,
    flightType,
    isDirectFlight,
  } = props;

  const { airlines, price, stops } = flightSearchOptions;
  const {
    airlines: airlinesFilter,
    price: currentTypeFilter,
    stops: stopsFilter,
  } = activeFiltersSearchOptions;

  switch (interfaceType) {
    case "price":
      return (
        <div className="px-4 py-6" key={key}>
          <PriceFilter
            priceData={price}
            activeTypeFilter={currentTypeFilter}
            handlePriceChange={handleTimeFilterChange}
            flightType={flightType}
            type={label}
          />
        </div>
      );
    case "stops":
      return (
        !isDirectFlight && (
          <div className="px-4 py-6" key={key}>
            <NoOfStopsFilters
              stopsFilter={stopsFilter}
              stopsData={stops}
              handleFilterChange={handleFilterChange}
            />
          </div>
        )
      );
    case "time":
      return (
        <div className="px-4 py-6" key={key}>
          <TimeFrameFilters
            type={label}
            header={label}
            minTime={minTime}
            maxTime={maxTime}
            handleTimeChange={handleTimeFilterChange}
            flightSearchOptions={flightSearchOptions}
            flightType={flightType}
          />
        </div>
      );
    case "baggage":
      return (
        <div className="px-4 py-6" key={key}>
          <TravelBaggagesFilters />
        </div>
      );
    case "emission":
      return (
        <div className="px-4 py-6" key={key}>
          <FlightEmissionFilter />
        </div>
      );
    case "airline":
      return (
        <div className="px-4 py-6" key={key}>
          <AirlineFilters
            airlinesFilter={airlinesFilter}
            airlinesData={airlines}
            handleFilterChange={handleFilterChange}
          />
        </div>
      );
    case "internationalFlights":
      return (
        <div className="px-4 py-6" key={key}>
          <InternationalFlightFilters />
        </div>
      );
    default:
      return null;
  }
};

export default renderFilters;
