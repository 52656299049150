import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { isEmpty, get } from "lodash";
import { Tooltip } from "react-tooltip";
import { v4 as uuid } from "uuid";
import Spinner, { SPINNER_NAMES } from "../../../components/organisms/Spinner";
import {
  getUserBookingInfo,
  selectUserBookingInfo,
  getAmadeusRefundCharges,
  getTBORefundCharges,
  setSelectedBooking,
} from "../index";
import {
  SEARCH_SECTION,
  TRIP_TYPES,
  DEFAULT_VALUES,
  BOOKING_STATUS_CODES,
  CANCELLATION_STATUS,
  CURRENCY_SYMBOLS,
  BOOKING_STATUS,
  FLIGHT_PROVIDERS,
} from "../../../constants";
import config from "../../../config.json";
import { selectCancellationStatus, setCancellationStatus } from "../../Booking/FlightBookings";
import {
  FlightInfo,
  PaymentInfo,
  AirlineContactInfo,
  WebCheckinInfo,
  TravelersInfo,
  SuccessBookingHeader,
  FailedBookingHeader,
  PartialBookingHeader,
} from "../../../components/organisms/FlightBookingInfoSections";
import {
  RenderSVG,
  TicketSlash,
  TicketOutline,
  InvoiceIcon,
} from "../../../assets/icons";
import { getAirportInfoByIata } from "../../../helper";
import { FlightBookingInfoSkeleton } from "../../../components/organisms/AppSkeletons";
import mapCancellationRequest from "../../../RequestMappers/CancellationRequestMapping";
import { setSelectedModal } from "../../../components/organisms/Modal";
import { MODALS } from "../../../components/organisms/AppModals/modals.constants";
import { selectCountryInfo } from "../../Profile";
import {
  getFormattedLongDate,
  formatDateWithLeadingZeros
} from "../../../helper";

const { FETCH_USER_BOOKING_INFO } = SPINNER_NAMES;
const { ROUND_TRIP } = TRIP_TYPES;
const { FLIGHT } = SEARCH_SECTION;
const { EMPTY_STRING, EMPTY_ARRAY, ZERO, ONE } = DEFAULT_VALUES;
const { FAILURE, PARTIAL, SUCCESS } = BOOKING_STATUS_CODES;
const { CONFIRMED } = BOOKING_STATUS;
const { INR } = CURRENCY_SYMBOLS;
const { TBO, AMADEUS } = FLIGHT_PROVIDERS;
const { BOOKING_CANCELLATION_MODAL } = MODALS;
const { SUCCESSFUL } = CANCELLATION_STATUS;
const { logo } = config;
const DATE_FORMATS = {
  NUMERIC: "numeric",
  TWO_DIGIT: "2-digit",
  SHORT: "short",
};
const { NUMERIC, TWO_DIGIT, SHORT } = DATE_FORMATS;
const getFailedJourneyInfo = (journey) => {
  const totalPrice = get(journey, "price.grandTotal", ZERO);
  const currency = get(journey, "price.currency", INR);
  const segments = get(journey, "itineraries.0.segments", EMPTY_ARRAY);
  const departIata = get(segments, "0.departure.iataCode", EMPTY_STRING);
  const arrivalIata = get(
    segments,
    `${segments.length - ONE}.arrival.iataCode`,
    EMPTY_STRING
  );
  const departLocation =
    getAirportInfoByIata(departIata).cityName || departIata;
  const arrivalLocation =
    getAirportInfoByIata(arrivalIata).cityName || arrivalIata;
  return {
    journeyData: `${departLocation}-${arrivalLocation}`,
    amount: totalPrice,
    currency: currency,
  };
};

const getJourneyInfo = (bookingInfo) => {
  return bookingInfo
    .map((booking) => booking.bookingJSON.journeyDetails)
    .flat();
};

const getCollectiveBookingStatus = (pnrList, bookingInfo) => {
  const filteredPnrs = pnrList?.filter((pnr) => pnr);
  if (!filteredPnrs?.length) return FAILURE;
  else if (filteredPnrs?.length && !isEmpty(bookingInfo) && bookingInfo.every(booking => booking.status !== CONFIRMED)) return FAILURE
  else if ((filteredPnrs?.length === bookingInfo?.length) && !isEmpty(bookingInfo) && bookingInfo.every(booking => booking.status === CONFIRMED)) return SUCCESS;
  else return PARTIAL;
};

const FlightBookingInfo = ({
  bookingId,
  isSpinnerActive,
  setIsSpinnerActive,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const selectedUserBookingInfo = useSelector(selectUserBookingInfo);
  const createdAt = get(
    selectedUserBookingInfo,
    "result.0.createdAt",
    EMPTY_STRING
  );
  const options = { hour: NUMERIC, minute: TWO_DIGIT, hour12: true };
  const bookingTime = createdAt
    ? getFormattedLongDate(
        `${formatDateWithLeadingZeros(createdAt)}`,
        options
      ).split(",")[ONE]
    : "";

  const [collectiveBookingStatus, setCollectiveBookingStatus] = useState();
  const [journeyInfo, setJourneyInfo] = useState([]);
  const [itineraries, setItineraries] = useState([]);
  const [travelerInfo, setTravelerInfo] = useState([]);
  const cancellationStatus = useSelector(selectCancellationStatus);
  const [tripType, setTripType] = useState(ROUND_TRIP);
  const [pnrList, setPnrList] = useState([]);
  const [bookingStatus, setBookingStatus] = useState([]);

  const { ip: endUserIp } = useSelector(selectCountryInfo) || EMPTY_OBJECT;
  const [failedJourneyInfo, setFailedJourneyInfo] = useState({
    journeyData: EMPTY_STRING,
    amount: ZERO,
    currency: INR,
  });

  useEffect(() => {
    dispatch(
      getUserBookingInfo({
        queryParams: {
          division: FLIGHT,
          id: bookingId,
        },
      })
    );
  }, []);

  useEffect(() => {
    if (cancellationStatus === SUCCESSFUL) {
      dispatch(setCancellationStatus(null));
    }
  })

  useEffect(() => {
    if (selectedUserBookingInfo) {
      const allPnrs = selectedUserBookingInfo.result?.map(
        (each) => each.pnr || each.allPnr
      );
      const allJourneyInfo = getJourneyInfo(selectedUserBookingInfo.result);
      const allItineraries = allJourneyInfo.reduce(
        (itineraryAcc, journey) => [...itineraryAcc, ...journey.itineraries],
        []
      );
      const failedJourneyIndex = allPnrs?.findIndex((pnr) => !pnr);

      const allBookingStatus = selectedUserBookingInfo.result?.map(
        (each) => each.status
      ) || [];

      setPnrList(allPnrs);
      setBookingStatus(allBookingStatus)
      setJourneyInfo(allJourneyInfo);
      setItineraries(allItineraries);
      setCollectiveBookingStatus(
        getCollectiveBookingStatus(allPnrs, selectedUserBookingInfo.result)
      );
      setTripType(get(allJourneyInfo, "0.tripType", ROUND_TRIP));
      setTravelerInfo(get(allJourneyInfo, "0.travelerDetails", EMPTY_ARRAY));

      if (failedJourneyIndex !== -1)
        setFailedJourneyInfo(
          getFailedJourneyInfo(allJourneyInfo[failedJourneyInfo])
        );
    }
  }, [selectedUserBookingInfo]);

  const bookingActions = [
    {
      id: "cancelTicket",
      label: "bookingResult.actions.cancelTicket",
      icon: TicketSlash,
      handleClick: () => {
        let source = get(selectedUserBookingInfo, "result.0.provider", EMPTY_STRING);
        let bookingResponse;
        switch (source) {
          case TBO:
            bookingResponse = get(selectedUserBookingInfo, "result.0", EMPTY_STRING);
            break;
          case AMADEUS:
            bookingResponse = get(selectedUserBookingInfo, "bookingInfo.0", EMPTY_STRING);
            break;
          default:
            break;
        }
        let finalbookingResponse = { ...bookingResponse };
        finalbookingResponse.endUserIp = endUserIp;
        const cancellationRequestBody = mapCancellationRequest(
          source,
          finalbookingResponse
        );
        source === TBO
          ? dispatch(getTBORefundCharges({ body: cancellationRequestBody }))
          : dispatch(
            getAmadeusRefundCharges({ body: cancellationRequestBody })
          );
        dispatch(setSelectedBooking(finalbookingResponse));
        dispatch(setSelectedModal(BOOKING_CANCELLATION_MODAL));
      },
    },
  ];

  return (
    <Spinner
      name={FETCH_USER_BOOKING_INFO}
      loaderComponent={<FlightBookingInfoSkeleton t={t} />}
      setIsSpinnerActive={setIsSpinnerActive}
    >
      {!isEmpty(selectedUserBookingInfo) && (
        <div className="relative z-0">
          {collectiveBookingStatus === SUCCESS && (
            <SuccessBookingHeader
              bookingId={bookingId}
              bookingStatus={collectiveBookingStatus}
              t={t}
            />
          )}
          {collectiveBookingStatus === PARTIAL && (
            <PartialBookingHeader
              bookingId={bookingId}
              bookingStatus={collectiveBookingStatus}
              failedJourneyInfo={failedJourneyInfo}
              t={t}
            />
          )}
          {collectiveBookingStatus === FAILURE && (
            <FailedBookingHeader
              bookingId={bookingId}
              bookingStatus={collectiveBookingStatus}
              t={t}
            />
          )}
          <Tooltip
            id="comingSoon-tooltip"
            className="!w-56 !sm:w-72 !z-50 !bg-primary-600 !rounded-lg text-center"
          />
          <div className="pb-16 relative">
            <div className="container px-8 -mt-12 mx-auto">
              <div className="grid grid-cols-12 gap-8">
                <div className="col-span-12 flex flex-col gap-4 xl:col-span-8">
                  <div className="flex flex-col gap-8">
                    <div className="bg-white rounded-lg border border-contrast-300 shadow-sm border-dashed">
                      <div className="flex flex-wrap px-4 py-3 border-b border-contrast-300 justify-between border-dashed">
                        <h4 className="text-contrast-900 font-bold text-sm">
                          {t(`searchSection.tripTypes.${tripType}`)}{" "}
                          {t("bookingResult.flight")}
                        </h4>
                        {!isSpinnerActive && (
                          <div className="flex flex-col p-1 gap-1">
                            <p className="font-semibold text-xs flex gap-2 items-center text-contrast-800">
                              <span>{t("bookingResult.bookingId")}</span>
                              <span className="text-primary-700 px-2 py-0.5 rounded-2xl bg-primary-100">
                                {bookingId || EMPTY_STRING}
                              </span>
                            </p>
                            {createdAt && (
                              <p className="font-semibold text-xs flex gap-2 items-center text-contrast-800">
                                Booked On
                                <span className="text-primary-700 px-2 py-0.5 rounded-2xl bg-primary-100">
                                  {getFormattedLongDate(createdAt, {
                                    day: NUMERIC,
                                    month: SHORT,
                                    year: NUMERIC,
                                  }) +
                                    " " +
                                    bookingTime || EMPTY_STRING}
                                </span>
                              </p>
                            )}
                          </div>
                        )}
                      </div>
                      {itineraries.map((itinerary, index) => (
                        <FlightInfo
                          key={uuid()}
                          pnrList={pnrList}
                          itinerary={itinerary}
                          index={index}
                          bookingStatus={bookingStatus}
                        />
                      ))}
                    </div>
                  </div>
                  <PaymentInfo journeyDetails={journeyInfo} />
                  <TravelersInfo travelerData={travelerInfo} />
                  {collectiveBookingStatus !== FAILURE && (
                    <WebCheckinInfo
                      itineraries={itineraries}
                      pnrList={pnrList}
                      tripType={tripType}
                    />
                  )}

                  <div className="rounded-lg border border-contrast-300 overflow-hidden shadow-sm">
                    <div className="px-6 py-4 border-b bg-contrast-50 border-contrast-300">
                      <h4 className="text-base text-contrast-900 flex-1 font-bold">
                        {t("bookingResult.importantInfo")}
                      </h4>
                    </div>
                    <div className="flex flex-col gap-6 py-4 px-6">
                      <div>
                        <h4 className="text-contrast-900 mb-2 text-sm font-bold">
                          {t("bookingResult.checkList")}
                        </h4>
                        <ul className="text-contrast-600 text-xs pl-5 flex flex-col gap-1 list-disc">
                          <li>{t("bookingResult.checkListPoints.point1")}</li>
                          <li>{t("bookingResult.checkListPoints.point2")}</li>
                          <li>{t("bookingResult.checkListPoints.point3")}</li>
                        </ul>
                      </div>

                      <div>
                        <h4 className="font-bold text-contrast-900 mb-2 text-sm">
                          {t("bookingResult.checkinAndBoarding")}
                        </h4>
                        <ul className="text-contrast-600 text-xs pl-5 flex flex-col gap-1 list-disc">
                          <li>{t("bookingResult.boardingPoints.point1")}</li>
                          <li>{t("bookingResult.boardingPoints.point2")}</li>
                        </ul>
                      </div>

                      <div>
                        <h4 className="font-bold text-contrast-900 mb-2 text-sm">
                          {t("bookingResult.kindAttention")}
                        </h4>
                        <ul className="text-contrast-600 text-xs pl-5 flex flex-col gap-1 list-disc">
                          <li>{t("bookingResult.attentionPoints.point1")}</li>
                          <li>{t("bookingResult.attentionPoints.point2")}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-span-12 xl:col-span-4">
                  <div className="rounded-lg mb-6 bg-white print:hidden border border-contrast-300">
                    <ul className="flex flex-col text-sm divide-y">
                      <li className="flex items-center px-4 py-3 gap-2">
                        <h4 className="text-base text-contrast-900 font-bold">
                          {t("bookingResult.manageTrip")}
                        </h4>
                      </li>
                      {cancellationStatus !== SUCCESSFUL && (collectiveBookingStatus === SUCCESS && bookingActions.map(
                        ({ id, icon, label, handleClick }) => (
                          <li
                            key={id}
                            className="flex items-center gap-2border-0 border-t border-solid border-contrast-300 px-4 py-3"
                          >
                            <button
                              className="flex gap-2 text-sm font-medium text-primary-600 items-center"
                              onClick={handleClick}
                            >
                              <div className="icon">
                                <RenderSVG Svg={icon} height="20" />
                              </div>
                              <span>{t(label)}</span>
                            </button>
                          </li>
                        )
                      ))}
                    </ul>
                  </div>

                  <div className="border border-contrast-300 rounded-lg divide-y divide-contrast-300 print:hidden mb-6">
                    <div className="p-6">
                      <h4 className="text-base mb-5 font-bold text-contrast-900">
                        {t("bookingResult.tickets")}
                      </h4>
                      <ul className="flex flex-col gap-3">
                        <li className="">
                          <button
                            className="flex gap-2 items-center font-medium text-primary-600 text-sm"
                            onClick={() => window.print()}
                            disabled={collectiveBookingStatus === FAILURE}
                          >
                            <div className="icon">
                              <RenderSVG Svg={TicketOutline} width="20" />
                            </div>
                            <span>{t("bookingResult.downloadTicket")}</span>
                          </button>
                        </li>
                        {/* <li className="">
                          <button
                            className="flex gap-2 items-center font-medium text-primary-600 cursor-pointer text-sm"
                            data-tooltip-id="comingSoon-tooltip"
                            data-tooltip-content={t("bookingResult.comingSoon")}
                            disabled
                          >
                            <div className="icon">
                              <RenderSVG
                                Svg={InvoiceIcon}
                                width="20"
                                className="text-white"
                              />
                            </div>
                            <span>{t("bookingResult.emailTicket")}</span>
                          </button>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                  <AirlineContactInfo itineraries={itineraries} />
                  <div className="border border-contrast-300 rounded-lg divide-contrast-300 mb-6 divide-y">
                    <div className="p-6">
                      <h4 className="font-bold text-base mb-5 text-contrast-900">
                        {t("bookingResult.contactUs")}
                      </h4>
                      <div className="flex items-center gap-3">
                        <div className="logo">
                          <img
                            src={logo}
                            width="30"
                            className="rounded border border-contrast-300"
                            alt="brand-logo"
                          />
                        </div>
                        <div className="flex-1">
                          <p className="text-xs text-contrast-600 mb-1">
                            {t("bookingResult.customerSupport")}
                          </p>
                          <h5 className="text-sm text-primary-600 underline font-semibold">
                            <a
                              target="_blank"
                              href="mailto:support@tavatrip.com"
                            >
                              support@tavatrip.com
                            </a>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Spinner>
  );
};

export default FlightBookingInfo;
