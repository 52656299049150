import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import {
  getLayoverDuration,
  getFormattedDuration,
  getCityNameByIata,
  calculateTotalDuration,
} from "../../helper";
import { setSelectedModal } from "../../components/organisms/Modal";
import { MODALS } from "../../components/organisms/AppModals";
import { getStops } from "../../utils/flights.utils";
import PackagedFlightDetails from "./PackagedFlightDetails";
import { DEFAULT_VALUES, INDEX, FLIGHT_SERVICE_TYPE } from "../../constants";
import { setSelectedFlightIndexInfo } from "./index";
import logEvent from "../../utils/GATracker";
import PriceAndPaxInfo from "./PriceAndPaxInfo";

const { ZERO, ONE, EMPTY_OBJECT } = DEFAULT_VALUES;
const { LAST } = INDEX;
const { AMADEUS } = FLIGHT_SERVICE_TYPE;
const { VIEW_FLIGHT_DETAILS_MODAL } = MODALS;
const MAX_CARDS_IN_ROW = 3;

const MultiCityCard = ({ flightData, totalPassengersCount }) => {
  const {
    flights,
    totalPrice,
    hasHighCO2Emission,
    isSelectedFlight,
    currency,
    handleSelectPackage,
    flightIndex,
  } = flightData;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    itineraries,
    source,
    isRefundable,
    miniFareRules,
    price = EMPTY_OBJECT,
  } = flights[flightIndex] || EMPTY_OBJECT;
  const { publishedPrice = 0 } = price;
  const areSimilarFlightsAvailable = flights.length > ONE;

  const handleViewMultiCityTripDetails = (flightIndexInfo) => {
    dispatch(setSelectedFlightIndexInfo(flightIndexInfo));
    dispatch(setSelectedModal(VIEW_FLIGHT_DETAILS_MODAL));
  };

  return (
    <div
      className={classNames(
        "border rounded-lg shadow-lg transition duration-1000 ease-in-out",
        {
          "border-primary-600 bg-primary-100": isSelectedFlight,
          "border-contrast-200 bg-white hover:border-contrast-400":
            !isSelectedFlight,
          "mb-3": areSimilarFlightsAvailable,
        }
      )}
    >
      <div className="flex p-4 flex-col sm:flex-row">
        <div
          className={classNames("flex w-full sm:border-r border-0", {
            "flex-col xl:flex-row ": itineraries.length <= MAX_CARDS_IN_ROW,
            "flex-col": itineraries.length > MAX_CARDS_IN_ROW,
          })}
        >
          {itineraries.map((itinerary, index) => {
            const { segments, duration } = itinerary;
            const startLocation = segments[ZERO];
            const {
              noOfAvailableSeats,
              departure = EMPTY_OBJECT,
              baggageDetails = EMPTY_OBJECT,
            } = startLocation;
            const finalDestination = segments.at(LAST);
            const totalNumberOfStops = segments.length - ONE;
            const layoverDuration = getLayoverDuration(segments);
            const { arrival } = finalDestination;

            return (
              <div
                key={`${departure.date}${departure.time}`}
                className="flex flex-col gap-4 flex-1 justify-between py-2"
              >
                <div
                  className={classNames(
                    "pr-4 mr-4 mb-1 flex flex-wrap gap-4 justify-between",
                    {
                      "border-gray-700": index === ZERO,
                    }
                  )}
                >
                  <PackagedFlightDetails
                    arrival={arrival}
                    startLocation={startLocation}
                    hasHighCO2Emission={hasHighCO2Emission}
                    baggage={baggageDetails}
                    noOfAvailableSeats={noOfAvailableSeats}
                    isRefundable={isRefundable}
                    miniFareRules={miniFareRules}
                  />
                  <div className="whitespace-nowrap md:w-20">
                    <div className="text-base text-gray-900 font-semibold mb-1">
                      {source === AMADEUS
                        ? getFormattedDuration(duration)
                        : calculateTotalDuration(layoverDuration, duration)}
                      <div className="text-xs text-gray-600 font-bold whitespace-normal">
                        {getCityNameByIata(departure.iataCode)} -
                        {getCityNameByIata(arrival.iataCode)}
                      </div>
                    </div>
                  </div>
                  <div className="flex whitespace-nowrap md:w-20">
                    {!totalNumberOfStops ? (
                      <div className="flex text-base text-gray-900 font-semibold mb-1 justify-end md:justify-start">
                        {t("flightResults.stopTypes.nonStop")}
                      </div>
                    ) : (
                      <div className="justify-end text-base md:justify-start">
                        <h5 className="flex text-gray-900 font-semibold mb-1">
                          {totalNumberOfStops}
                          {t("flightResults.stopTypes.stop")}
                        </h5>
                        <div className="text-xs text-gray-600">
                          {getStops(segments)}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="flex justify-between sm:flex-col sm:ml-auto gap-1 items-center sm:items-stretch sm:w-auto w-full py-0 sm:pl-4 flex-wrap">
          <PriceAndPaxInfo
            flightIndex={flightIndex}
            totalPrice={totalPrice}
            publishedPrice={publishedPrice}
            currencySymbol={currency}
            totalPassengersCount={totalPassengersCount}
            translationHandler={t}
          />
          <div className="flex flex-row py-2 pl-2 pr-0 sm:px-0 md:mb-auto">
            <div className="flex flex-row gap-3 justify-end mb-2 border-b-contrast-200">
              <div className="flex">
                <button
                  className={classNames(
                    "py-2 px-4 flex items-center gap-2 rounded-md hover:bg-primary-300 active:bg-primary-200 border-none shadow-sm lg:text-sm text-primary-700 font-medium whitespace-nowrap text:xs",
                    {
                      "bg-primary-200": isSelectedFlight,
                      "bg-primary-100": !isSelectedFlight,
                    }
                  )}
                  onClick={() => {
                    logEvent(`flight_selected_from_multi_city_flight_results`)
                    handleSelectPackage(flights)
                  }}
                >
                  {t("flightResults.selectFlight")}
                </button>
              </div>
            </div>
          </div>
          <button
            onClick={() => {
              logEvent('view_details_multicity_flight_btn_clicked')
              handleViewMultiCityTripDetails(flights[flightIndex])
            }}
            className="py-2 px-[12px] flex items-center font-medium whitespace-nowrap text-sm text-primary-700"
          >
            {t("flightResults.viewDetails")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default MultiCityCard;
