import classNames from "classnames";
import { useCollapse } from "react-collapsed";
import { useTranslation } from "react-i18next";
import { ChevronDown, Checkbox, RenderSVG } from "../../../assets/icons";
import { getAirlineIconUrlByCarrierCode } from "../../../helper";
import { DEFAULT_VALUES } from "../../../constants";

const { EMPTY_ARRAY, EMPTY_OBJECT } = DEFAULT_VALUES;

const AirlineFilters = ({
  airlinesFilter = EMPTY_ARRAY,
  airlinesData = EMPTY_OBJECT,
  handleFilterChange,
}) => {
  const { t } = useTranslation();

  const filteredAirlines = Object.entries(airlinesData);

  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    duration: 200,
    defaultExpanded: true,
  });

  return (
    <>
      <div className="flex items-center gap-2" {...getToggleProps()}>
        <h4 className="flex-1 text-lg font-semibold text-contrast-800">
          {t("flightResults.filters.airlines")}
        </h4>
        <span {...getToggleProps()}>
          <RenderSVG
            Svg={ChevronDown}
            alt="Chevron Down Icon"
            className={classNames({ "rotate-180": isExpanded })}
          />
        </span>
      </div>
      <div {...getCollapseProps()}>
        <div className="pt-6">
          <div className="grid grid-cols-2 gap-6">
            {filteredAirlines.map(([airline, { count, name }]) => (
              <div className="col-span-2" key={airline}>
                <div className="checbox-tab">
                  <label className="cursor-pointer">
                    <input
                      className="peer hidden"
                      type="checkbox"
                      id="airlines"
                      value={airline}
                      onChange={handleFilterChange}
                      checked={airlinesFilter?.includes(airline)}
                    />
                    <div
                      className={classNames(
                        "flex items-center gap-4  border-2  p-4 rounded-lg",
                        {
                          "bg-primary-100 border-primary-600 text-primary-900":
                            airlinesFilter?.includes(airline),
                          "border-contrast-200 text-contrast-900":
                            !airlinesFilter?.includes(airline),
                        }
                      )}
                    >
                      <div
                        className={classNames(
                          "w-4 h-4 rounded grid place-content-center border",
                          {
                            "bg-primary-600 border-primary-600":
                              airlinesFilter?.includes(airline),
                            "border-contrast-300":
                              !airlinesFilter?.includes(airline),
                          }
                        )}
                      >
                        <RenderSVG
                          Svg={Checkbox}
                          width="9"
                          height="9"
                          alt="Checkbox"
                        />
                      </div>
                      <div className="logo">
                        <img
                          src={getAirlineIconUrlByCarrierCode(airline)}
                          width="40"
                          className="shrink-0 rounded border border-contrast-300"
                          alt={`${airline}_logo`}
                        />
                      </div>
                      <div>
                        <span className="text-sm font-medium flex capitalize">
                          {name}
                        </span>
                        <span
                          className={classNames("text-sm flex", {
                            "text-primary-700":
                              airlinesFilter?.includes(airline),
                            "text-contrast-500":
                              !airlinesFilter?.includes(airline),
                          })}
                        >
                          {count} {t("flightResults.flights")}
                        </span>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
export default AirlineFilters;
