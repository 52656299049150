import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce, get, isEmpty, uniqueId } from "lodash";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import ErrorMessage from "../../atoms/ErrorMessage";
import HotelListItem from "../../atoms/HotelListItem";
import {
  getHotelLocations,
  selectHotelLocations,
  setCurrentSearchLevel,
  setSelectedPrediction,
} from "../../organisms/Search";
import Spinner, { SPINNER_NAMES } from "../../organisms/Spinner";
import { LocationMarker, RenderSVG } from "../../../assets/icons";
import popularCitiesData from "../../../assets/json/popularHotelLocations.json";
import {
  DEFAULT_VALUES,
  WINDOWS_EVENTS,
  SEARCH_SECTION,
  KEYBOARD_KEY_CODES,
  ROUTES,
  ESTABLISHMENT,
} from "../../../constants";
import { selectSelectedPrediction } from "../../organisms/Search/search.selectors";

const { EMPTY_STRING, EMPTY_OBJECT, ZERO, ONE, TWO, THREE, FOUR } =
  DEFAULT_VALUES;
const { UP_KEY_CODE, DOWN_KEY_CODE, ENTER_KEY_CODE } = KEYBOARD_KEY_CODES;
const { CLICK } = WINDOWS_EVENTS;
const { HOTEL } = SEARCH_SECTION;
const { FETCH_LOCATIONS } = SPINNER_NAMES;
const { LOCALITY, POLITICAL, SUBLOCALITY } = ESTABLISHMENT;
const {
  REACT_APP_GOOGLE_PLACES_API_URL = "",
  REACT_APP_GOOGLE_PLACES_API_KEY = "",
} = process.env;

const PredictionsList = ({ hotelData }) => {
  const { name, icon, formatted_address } = hotelData;
  return (
    <div>
      <div className="flex gap-2">
        <div className="icon shrink-0">
          <img
            src={icon}
            width="24"
            height="24"
            className="text-contrast-400"
            alt="location-icon"
          />
        </div>
        <div className="flex-1 flex gap-3 items-center w-full">
          <div className="flex-1">
            <h6 className="text-base font-semibold text-contrast-900 mb-1 w-full truncate">
              {name}
            </h6>
            <span className="text-xs text-contrast-500 flex">
              {formatted_address}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const HotelsLocationPicker = ({ name, handleLocationChange, source }) => {
  const {
    validateForm,
    setFieldTouched,
    setFieldValue,
    touched,
    errors,
    values,
  } = useFormikContext();
  const pathname = window.location.pathname;
  const locations = useSelector(selectHotelLocations);
  const [searchValue, setSearchValue] = useState(
    get(values, `${name}.hotelName`) ||
      get(values, `${name}.cityName`, EMPTY_STRING)
  );
  const [selectedValueIndex, setSelectedValueIndex] = useState(null);
  const [show, setShow] = useState();
  const [hotelLocations, setHotelLocations] = useState();
  const selectedPrediction = useSelector(selectSelectedPrediction);
  const [currentState, setCurrentState] = useState(ONE);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const locationsOptionsRef = useRef(null);

  const inputRef = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const sourceLocationCode = get(values, name, EMPTY_OBJECT);
  const KEY_CODES = [UP_KEY_CODE, DOWN_KEY_CODE, ENTER_KEY_CODE];

  const renderFieldError = () =>
    get(errors, name, false) &&
    get(touched, name, false) && (
      <ErrorMessage errorMessage={get(errors, name)} />
    );

  const handleDestinationCodeChange = (value) => setFieldValue(name, value);
  const handleLocationSelect = (item, arePopularDestinations) => {
    delete item.geometry
    delete item.photos
    delete item.opening_hours
    setCurrentState(ONE);
    dispatch(setCurrentSearchLevel(ONE));
    dispatch(setSelectedPrediction(item));
    handleLocationChange({
      inputValue: arePopularDestinations
        ? searchValue
        : item.structured_formatting?.main_text,
    });
    setShow(false);
  };

  useEffect(() => {
    if (!isEmpty(selectedPrediction)) {
      const mostRelevantHotel = findMostRelevantLocation(
        selectedPrediction,
        locations,
        currentState
      );
      const matchedLocation = selectedPrediction?.cityName
        ? selectedPrediction
        : mostRelevantHotel;

      const {
        CountryName,
        StateProvince,
        objectID,
        _highlightResult,
        ...locationData
      } = matchedLocation || get(locations, "0", EMPTY_OBJECT);

      handleDestinationCodeChange(locationData);
    }
  }, [selectedPrediction, locations]);

  const fetchMapHotels = (searchQuery) => {
    if (searchQuery.trim()) {
      const placesService = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );
      const localityRequest = {
        query: `${searchQuery}`
      };

      let combinedResults = [];
      const filterResultsByTypes = (results) => {
        return results.filter((result) => {
            return result.types.some((type) =>
                [LOCALITY, POLITICAL, SUBLOCALITY].includes(type)
            );
        })
      };
      placesService.textSearch(localityRequest, (results) => {
        const filteredResults = filterResultsByTypes(results);

        combinedResults.push(...filteredResults);
        !isEmpty(combinedResults) && setHotelLocations(combinedResults);
      });

      const lodgingRequest = {
        query: `${searchQuery} hotel`,
        type: "lodging",
      };
      placesService.textSearch(lodgingRequest, (results) => {
        combinedResults.push(...results);
        !isEmpty(combinedResults) && setHotelLocations(combinedResults);
      });
    } else setHotelLocations([]);
  };

  const debouncedDispatch = debounce((searchValue) => {
    fetchMapHotels(searchValue);
  }, 1000);

  useEffect(() => {
    isScriptLoaded && debouncedDispatch(searchValue);
    return () => {
      debouncedDispatch.cancel();
    };
  }, [searchValue, dispatch, isScriptLoaded]);

  useEffect(() => {
    if (!window.google?.maps) {
      const script = document.createElement("script");
      script.src = `${REACT_APP_GOOGLE_PLACES_API_URL}/maps/api/js?key=${REACT_APP_GOOGLE_PLACES_API_KEY}&libraries=places&loading=async`;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
  
      script.onload = () => {
        setIsScriptLoaded(true);
      };
  
      return () => {
        document.body.removeChild(script);
      };
    } else {
      setIsScriptLoaded(true);
    }
  }, []);

  const findMostRelevantLocation = (
    selectedOption,
    locations,
    currentState
  ) => {
    let mostRelevantHotel = null;
    if (isEmpty(selectedOption) || selectedOption.cityName) return null;
    const selectedOptionAddress = get(selectedOption, 'formatted_address', '');
    const addressParts = selectedOptionAddress.split(', ');
    const selectedCityName = addressParts[addressParts.length - 3] ||
    addressParts[0]?.toLowerCase();
    const selectedOptionWords = selectedOption?.name;
    const searchKeywords = selectedOptionWords?.split(", ");
    const selectedState = addressParts[addressParts.length - 2];

    locations.forEach((hotel) => {
      const isHotelNameSimilar =
        !hotel.hotelName ||
        searchKeywords[0]?.toLowerCase() === hotel.hotelName?.toLowerCase();
      const isCityNameSimilar = selectedCityName
        ?.toLowerCase()
        ?.includes(hotel?.cityName?.toLowerCase());

      if (isHotelNameSimilar && isCityNameSimilar) {
        mostRelevantHotel = hotel;
      }
    });
    if (!mostRelevantHotel) {
      if (currentState === ONE) {
        dispatch(getHotelLocations({ key: searchKeywords[0] }));
        dispatch(setCurrentSearchLevel(TWO));
        setCurrentState(TWO);
      }
      if (currentState === TWO && selectedCityName) {
        dispatch(getHotelLocations({ key: selectedCityName }));
        dispatch(setCurrentSearchLevel(THREE));
        setCurrentState(THREE);
      }
      if (currentState === THREE && selectedState) {
        dispatch(getHotelLocations({ key: selectedState }));
        dispatch(setCurrentSearchLevel(FOUR));
        setCurrentState(FOUR);
      }
    } else setCurrentState(ONE);
    return mostRelevantHotel;
  };

  useEffect(() => {
    if (!show) {
      pathname !== ROUTES.HOTEL_RESULTS
        ? setSearchValue(
            get(selectedPrediction, "formatted_address") ||
              get(sourceLocationCode, "hotelName") ||
              get(sourceLocationCode, "cityName", "Gurugram")
          )
        : setSearchValue(
            selectedPrediction?.formatted_address ||
              sourceLocationCode?.hotelName ||
              sourceLocationCode?.cityName
          );
    }
    validateForm();
  }, [show, selectedPrediction, sourceLocationCode, pathname]);

  const locationChange = (e) => {
    setCurrentState(ONE);
    const inputValue = e.target.value;
    setSearchValue(inputValue);
  };

  const handleKeyDown = ({ keyCode }) => {
    if (KEY_CODES.includes(keyCode)) {
      let updatedSelectedIndex = selectedValueIndex;

      if (selectedValueIndex !== null) {
        if (
          keyCode === DOWN_KEY_CODE &&
          selectedValueIndex < hotelLocations?.length - ONE
        )
          updatedSelectedIndex = +selectedValueIndex + ONE;
        else if (keyCode === UP_KEY_CODE && selectedValueIndex !== ZERO)
          updatedSelectedIndex = +selectedValueIndex - ONE;
        else if (keyCode === ENTER_KEY_CODE) {
          updatedSelectedIndex = +selectedValueIndex;
          document.body.click();
          setCurrentState(ONE);
        }
      } else if (
        (selectedValueIndex === null ||
          selectedValueIndex > hotelLocations?.length - ONE) &&
        keyCode === DOWN_KEY_CODE
      )
        updatedSelectedIndex = ZERO;

      const selectedLocation = hotelLocations[updatedSelectedIndex];

      dispatch(setSelectedPrediction(selectedLocation));
      setSearchValue(selectedLocation?.formatted_address);
      setSelectedValueIndex(updatedSelectedIndex);
    }
  };

  useEffect(() => {
    const selected = locationsOptionsRef.current?.querySelector(".active");

    if (selected)
      selected.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
  }, [selectedValueIndex]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (inputRef.current?.contains(e.target)) setShow(true);
      else setShow(false);
    };
    document.addEventListener(CLICK, checkIfClickedOutside);

    return () => {
      document.removeEventListener(CLICK, checkIfClickedOutside);
    };
  }, [searchValue]);

  useEffect(() => {
    !show && setFieldTouched(name, true);
  }, [show]);

  const renderHotelLocations = ({
    hotelLocations = [],
    arePopularDestinations,
  }) => (
    <div className="flex flex-col" ref={locationsOptionsRef}>
      {hotelLocations?.map((item, index) => (
        <button
          key={item.destinationId || uniqueId()}
          className={classNames(
            "py-2 px-3 flex items-center gap-3 hover:bg-blue-300 cursor-pointer text-start",
            { "bg-blue-300 active": selectedValueIndex === index }
          )}
          onClick={() => handleLocationSelect(item, arePopularDestinations)}
        >
          {arePopularDestinations ? (
            <HotelListItem
              hotelData={item}
              arePopularDestinations={arePopularDestinations}
            />
          ) : (
            <PredictionsList hotelData={item} />
          )}
        </button>
      ))}
    </div>
  );

  return (
    <div
      className={classNames("col-span-2 sm:col-span-1 text-black", {
        "lg:col-span-4 col-span-2": source === HOTEL,
      })}
    >
      <div>
        <div className="relative">
          <div className="icon absolute left-3 top-1/2 -translate-y-1/2">
            <RenderSVG
              Svg={LocationMarker}
              className="text-contrast-400"
              alt="Location Icon"
            />
          </div>
          <input
            autoComplete="off"
            type="search"
            className="bg-white rounded-md shadow-sm hover:ring-2 hover:ring-primary-50 border border-contrast-300 w-full flex items-center gap-2 py-3.5 px-3 pl-10"
            placeholder={t(`searchSection.whereTo`)}
            ref={inputRef}
            value={searchValue}
            onChange={locationChange}
            onFocus={(e) => {
              e.target.select();
              setSearchValue("");
              handleLocationChange({ inputValue: "" });
            }}
            onKeyDown={handleKeyDown}
            selected
          />
          <div>{renderFieldError()}</div>
        </div>
        {show && (
          <div
            className={classNames(
              `p-6 bg-white shadow-2xl rounded-lg absolute z-30 w-full sm:min-w-[498px] max-h-80 min-h-[150px] overflow-y-scroll top-full mt-2`,
              {
                "no-scrollbar": isEmpty(hotelLocations),
                "scrollbar-light": !isEmpty(hotelLocations),
              }
            )}
          >
            {searchValue && (
              <Spinner name={FETCH_LOCATIONS}>
                {!isEmpty(hotelLocations) ? (
                  renderHotelLocations({
                    hotelLocations,
                    arePopularDestinations: false,
                  })
                ) : (
                  <p className="text-center">No results found</p>
                )}
              </Spinner>
            )}
            {(!searchValue || isEmpty(hotelLocations)) && (
              <div>
                <div className="text-light font-bold">Popular Destinations</div>
                {renderHotelLocations({
                  hotelLocations: popularCitiesData,
                  arePopularDestinations: true,
                })}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default HotelsLocationPicker;
