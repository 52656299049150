import { DEFAULT_VALUES } from "../../constants";
import { priceFormatter } from "../../helper";

const { ONE, ZERO } = DEFAULT_VALUES;

const PriceAndPaxInfo = ({
  flightIndex,
  totalPrice,
  publishedPrice,
  currencySymbol,
  totalPassengersCount,
  translationHandler,
}) => {
  return (
    <>
      {flightIndex === ZERO && (
        <div className="flex justify-end text-[10px] text-primary-700">
          {translationHandler("flightResults.pricesStartingAt")}
        </div>
      )}
      {totalPrice !== publishedPrice && (
        <h5 className="text-base text-center text-gray-600 font-bold line-through">
          {currencySymbol}
          &nbsp;
          {priceFormatter(parseFloat(publishedPrice))}
        </h5>
      )}
      <h5 className="text-lg text-center text-gray-900 font-bold mb-1">
        {currencySymbol}
        &nbsp;
        {priceFormatter(parseFloat(totalPrice))}
      </h5>
      {totalPassengersCount > ONE && (
        <div className="text-[10px] text-primary-700 flex justify-end font-semibold">
          {translationHandler("flightResults.forNumberOfTravelers", {
            numOfPax: totalPassengersCount,
          })}
        </div>
      )}
    </>
  );
};

export default PriceAndPaxInfo;
