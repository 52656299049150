import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCollapse } from "react-collapsed";
import { Helmet } from "react-helmet-async";
import parse from "html-react-parser";
import { Carousel } from "@material-tailwind/react";
import { isEmpty, get } from "lodash";
import classNames from "classnames";
import {
  RenderSVG,
  LocationMarker,
  ChevronUp,
  ChevronDown,
} from "../../assets/icons";
import {
  setHotelInfoReqBody,
  setPricePolicyInfo,
  setPricePolicyReqBody,
  setSelectedRoomInfo,
} from "./index";
import {
  formatPriceWithCommas,
  getFormattedAddress,
  getFromSessionStorage,
  getPricePolicyReqBody,
  renderStars,
  setToSessionStorage,
} from "../../helper";
import { selectHotelInfo, selectHotelInfoReqBody } from "./hotelInfo.selector";
import { selectHotelSearchFilters } from "../../components/organisms/Search";
import Spinner, { SPINNER_NAMES } from "../../components/organisms/Spinner";
import AmentiesCard from "../../components/molecules/HotelAmenities";
import { HotelRoomOptions } from "../../components/molecules";
import { selectCountryInfo } from "../Profile";
import {
  DEFAULT_VALUES,
  CACHE_KEYS,
  CURRENCY_SYMBOLS,
  ROUTES,
} from "../../constants";
import config from "../../config.json";
import { MODALS } from "../../components/organisms/AppModals";
import {
  selectSelectedModal,
  setSelectedModal,
} from "../../components/organisms/Modal";
import CarousalImageModal from "../../components/organisms/AppModals/CarousalImageModal/CarousalImageModal";

const { brandName } = config;
const { EMPTY_STRING, ZERO, ONE, TWO, EMPTY_OBJECT } = DEFAULT_VALUES;
const { CAROUSAL_IMAGE_MODAL } = MODALS;
const {
  HOTEL_INFO_REQUEST_BODY,
  HOTEL_SEARCH_FILTERS,
  PRICE_POLICY_REQUEST_BODY,
} = CACHE_KEYS;
const { FETCH_HOTEL_INFO } = SPINNER_NAMES;
const { HOTEL_REVIEW } = ROUTES;
const { INR } = CURRENCY_SYMBOLS;

export const parseDescription = (description) => {
  const decodeHTML = (htmlSection) => {
    let pricePolicyInfo = document.createElement("textarea");
    pricePolicyInfo.innerHTML = htmlSection;
    return pricePolicyInfo.value;
  };

  const sections = description
    ?.split("|")
    .map((section) => decodeHTML(section?.trim()))
    .filter((section) => section.length > ZERO);

  let pricePolicyHTML = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Hotel Information</title>
      <style>
        body {
          margin: 20px;
        }
        h1 {
          color: text-contrast-600;
        }
        .section {
          margin-bottom: 20px;
        }
        .section h2 {
          color: text-contrast-500;
        }
        .section ul {
          list-style-type: disc;
          margin-left: 20px;
        }
        li{
          display: block;
        }
      </style>
    </head>
    <body>
      <h1>Hotel Information</h1>
  `;

  sections?.forEach((section, index) => {
    pricePolicyHTML += `
      <div class="section">
        <p>${section.replace(/\n/g, "<br>")}</p>
      </div>
    `;
  });

  pricePolicyHTML += `
    </body>
    </html>
  `;

  return parse(pricePolicyHTML);
};

const getPriceBreakdown = (defaultSelectedRooms) => {
  return defaultSelectedRooms.reduce(
    (totalPrice, room) => {
      const {
        roomPrice = ZERO,
        tax = ZERO,
        otherCharges = ZERO,
        discount = ZERO,
        tavaMarkup = ZERO,
      } = get(room, "price", {});

      totalPrice.totalRoomPrice += roomPrice + tavaMarkup;
      totalPrice.totalTax += tax + otherCharges;
      totalPrice.totalDiscount += discount;

      return totalPrice;
    },
    { totalRoomPrice: ZERO, totalTax: ZERO, totalDiscount: ZERO }
  );
};

const PriceBreakdownCard = ({
  defaultSelectedRooms,
  noOfRooms,
  noOfNights,
  handleDefaultRoomSelection,
  scrollToSection,
  roomCombinations,
  setHighlightedComboIndex,
}) => {
  const areAllRoomsSelected =
    defaultSelectedRooms.length === parseInt(noOfRooms, 10);

  const selectedRoomIndices = new Set(
    defaultSelectedRooms.map((room) => room.roomIndex)
  );

  const [isBookNowClicked, setIsBookNowClicked] = useState(false);

  useEffect(() => {
    const notSelectedComboIndices = roomCombinations
      .map((combo, index) => ({
        index,
        hasSelectedRoom: combo.roomIndex.some((roomIndex) =>
          selectedRoomIndices.has(roomIndex)
        ),
      }))
      .filter((combo) => !combo.hasSelectedRoom)
      .map((combo) => combo.index);
    isBookNowClicked && setHighlightedComboIndex(notSelectedComboIndices[0]);
  }, [isBookNowClicked, defaultSelectedRooms]);

  const handleBookNowClick = () => {
    setIsBookNowClicked(true);
    areAllRoomsSelected && handleDefaultRoomSelection();
  };

  const { getToggleProps, getCollapseProps, isExpanded } = useCollapse({
    duration: 300,
    defaultExpanded: true,
  });
  const { totalRoomPrice, totalTax } =
    getPriceBreakdown(defaultSelectedRooms);

  const totalAmount = (
    parseFloat(totalRoomPrice.toFixed(TWO)) +
    parseFloat(totalTax)
  ).toFixed(TWO);
  const selectedCountryInfo = useSelector(selectCountryInfo);
  const currencySymbol = get(selectedCountryInfo, "currency.symbol", INR);

  const priceBreakdownData = [
    {
      label: `${noOfRooms} Room${
        noOfRooms > ONE ? "s" : ""
      } X ${noOfNights} Night${noOfNights > ONE ? "s" : ""}`,
      amount: totalRoomPrice,
    },
    {
      label: "Tax & Service Fees",
      amount: totalTax,
    },
  ];

  return (
    <div className="bg-white border border-contrast-300 rounded-lg mb-6">
      <ul className="flex flex-col text-sm !divide-y divide-grey-200">
        <li className="px-4 py-3 flex justify-between">
          <h2 className="text-contrast-900 font-bold text-base">
            Price Breakup
          </h2>
          <div {...getToggleProps()}>
            <span className="flex gap-2 items-center text-sm text-primary-600">
              <RenderSVG
                Svg={isExpanded ? ChevronUp : ChevronDown}
                className="w-4 h-4 text-black"
                alt={isExpanded ? "Up Icon" : "Down Icon"}
              />
            </span>
          </div>
        </li>

        <div {...getCollapseProps()}>
          {priceBreakdownData.map(({ label, amount }) => (
            <div key={label}>
              {!!amount && (
                <li className="flex items-center gap-2 px-4 py-3">
                  <span className="mr-auto text-contrast-900">{label}</span>
                  <span className="font-medium whitespace-nowrap">
                    {currencySymbol}&nbsp;
                    {formatPriceWithCommas(amount.toFixed(2))}
                  </span>
                </li>
              )}
            </div>
          ))}
        </div>

        <li className="flex items-center gap-2 px-4 py-3 bg-primary-100/50">
          <span className="text-base font-bold mr-auto text-primary-600">
            Total Amount
          </span>
          <span className="text-base font-bold text-primary-600 whitespace-nowrap">
            {currencySymbol}&nbsp;
            {formatPriceWithCommas(Number(totalAmount).toFixed(2))}
          </span>
        </li>
      </ul>
      <div className="flex justify-between px-5 py-3 font-semibold rounded-b-lg">
        <button
          onClick={scrollToSection}
          className="py-2 px-4 mr-2 rounded-md bg-white hover:bg-contrast-50 active:bg-white border border-contrast-300 shadow-sm text-xs md:text-sm text-contrast-700 font-medium"
        >
          VIEW OTHER ROOMS
        </button>
        <button
          onClick={handleBookNowClick}
          className="py-2 px-4 rounded-md bg-primary-600 hover:bg-primary-700 active:bg-primary-600 shadow-sm text-xs md:text-sm text-white font-medium disabled:cursor-not-allowed"
        >
          BOOK THIS NOW
        </button>
      </div>
    </div>
  );
};

const CarouselImage = ({ imageUrl, hotelName, imagesLength, handleOnClick}) => {
  return (
    <>
      <img
        src={imageUrl}
        alt={hotelName}
        className="h-full w-full object-cover"
      />
      <div className="absolute bottom-2 left-2 text-white font-bold text-base">
         {imagesLength}{imagesLength > 1 ? ' images' : ' image'}
      </div>
      <button
        className="top-0 left-0 w-full h-full bg-transparent absolute hidden xs:block"
        onClick={handleOnClick}
      ></button>
    </>
  );
};

const HotelDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const roomOptionsRef = useRef(null);
  const roomOptionsErrorRef = useRef(null);
  const hotelInfoReqBody = useSelector(selectHotelInfoReqBody);
  const selectedHotelInfo = useSelector(selectHotelInfo);
  const selectedModal = useSelector(selectSelectedModal);
  const { ip: endUserIp, code: guestNationality } =
    useSelector(selectCountryInfo) || EMPTY_OBJECT;
  const selectedHotelSearchFilters =
    useSelector(selectHotelSearchFilters) ||
    getFromSessionStorage(HOTEL_SEARCH_FILTERS) ||
    EMPTY_OBJECT;

  const [parsedDescription, setParsedDescription] = useState(EMPTY_STRING);
  const [hotelImageIndex, setHotelImageIndex] = useState(ZERO);
  const [isViewMore, setIsViewMore] = useState(false);
  const [descriptionView, setDescriptionView] = useState(EMPTY_STRING);
  const {
    images,
    description,
    hotelName,
    starRating,
    address,
    email,
    hotelCode,
    hotelContactNo,
    faxNumber,
  } = get(selectedHotelInfo, "priceResult.hotelDetails", EMPTY_OBJECT);
  const roomCombinationsArray = get(
    selectedHotelInfo,
    "priceResult.hotelRoomsResult.roomCombinationsArray",
    []
  );
  const roomsDetails = get(
    selectedHotelInfo,
    "priceResult.hotelRoomsResult.hotelRoomsDetails",
    []
  );
  const hotelInfoResult = get(selectedHotelInfo, "priceResult", EMPTY_OBJECT);
  const { categoryId } = hotelInfoReqBody;
  const { noOfNights, noOfRooms } = selectedHotelSearchFilters || EMPTY_OBJECT;

  const [selectedRooms, setSelectedRooms] = useState([]);

  const [highlightedComboIndex, setHighlightedComboIndex] = useState(null);

  const handleRoomSelection = (selectedRoom) => {
    setSelectedRooms((prevSelectedRooms) => {
      if (isFixedCombination) {
        return prevSelectedRooms;
      }

      const roomIndexInCombo = prevSelectedRooms.findIndex(
        (room) => room.comboIndex === selectedRoom.comboIndex
      );

      if (roomIndexInCombo !== -1) {
        const newSelectedRooms = [...prevSelectedRooms];
        newSelectedRooms[roomIndexInCombo] = selectedRoom;
        return newSelectedRooms;
      }
      
      return [...prevSelectedRooms, selectedRoom];
    });
  };

  // Todo need to update caregoryId filter logic
  // const roomCombinations = roomCombinationsArray.filter(
  //   (roomCombinations) => roomCombinations.categoryId === categoryId
  // );

  const roomCombinations = roomCombinationsArray;
  const { infoSource, roomCombination = [] } = roomCombinations[ZERO] || {};
  const isFixedCombination = infoSource === "FixedCombination";
  let defaultSelectedRooms = [];

  // Todo need to update caregoryId filter logic
  // !isEmpty(roomCombination) &&
  //   isFixedCombination &&
  //   roomCombination[ZERO].roomIndex.map((roomIndex) => {
  //     const roomDetail = roomsDetails.find(
  //       (room) => room.roomIndex === roomIndex && room.categoryId === categoryId
  //     );
  //     defaultSelectedRooms = [...defaultSelectedRooms, roomDetail];
  //   });

  !isEmpty(roomCombination) &&
    isFixedCombination &&
    roomCombination[ZERO].roomIndex.map((roomIndex) => {
      const roomDetail = roomsDetails.find(
        (room) => room.roomIndex === roomIndex
      );
      defaultSelectedRooms = [...defaultSelectedRooms, roomDetail];
    });

  !isEmpty(roomCombination) &&
    !isFixedCombination &&
    (defaultSelectedRooms = isEmpty(selectedRooms[ZERO]) ? [] : selectedRooms[0]);

  useEffect(() => {
    if (isEmpty(hotelInfoReqBody)) {
      const data = getFromSessionStorage(HOTEL_INFO_REQUEST_BODY);
      dispatch(setHotelInfoReqBody(data));
    }
  }, [dispatch]);

  useEffect(() => {
    const parsedContent = parseDescription(descriptionView);
    setParsedDescription(parsedContent);
  }, [descriptionView]);

  useEffect(()=>{
    setDescriptionView(description?.slice(0, 100));
  }, [description])

  const setViewMore = () => {
    if(isViewMore){
      setIsViewMore(false);
      setDescriptionView(description?.slice(0, 100));
    }
    else{
      setIsViewMore(true);
      setDescriptionView(description);
    }
  }

  const handleDefaultRoomSelection = () => {
    const { resultIndex, categoryId, hotelCode, checkInDate, checkOutDate } = hotelInfoReqBody;

    const pricePolicyReq =
      defaultSelectedRooms &&
      getPricePolicyReqBody(
        hotelInfoResult,
        defaultSelectedRooms,
        hotelCode,
        endUserIp,
        resultIndex,
        noOfRooms,
        categoryId,
        guestNationality,
        checkInDate,
        checkOutDate
      );
    dispatch(setSelectedRoomInfo(defaultSelectedRooms));
    setToSessionStorage(PRICE_POLICY_REQUEST_BODY, pricePolicyReq);
    dispatch(setPricePolicyReqBody(pricePolicyReq));
    dispatch(setPricePolicyInfo(EMPTY_OBJECT));
    navigate(HOTEL_REVIEW.replace(":hotelId", hotelCode));
  };
  const scrollToSection = () => {
    if (roomOptionsRef.current) {
      roomOptionsRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (roomOptionsErrorRef.current) {
      roomOptionsErrorRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const mappedContactDetails = [
    {
      key: "phone",
      label: "Phone Number(s)",
      value: hotelContactNo,
    },
    {
      key: "email",
      label: "email",
      value: email,
    },
    {
      key: "fax",
      label: "Fax Number",
      value: faxNumber,
    },
    {
      key: "hotelCode",
      label: "Hotel Code",
      value: hotelCode,
    },
  ].filter(({ value }) => value);

  return (
    <div className="flex items-center min-h-[80vh]">
      <Helmet>
        <title>{brandName} | Hotel Details</title>
      </Helmet>
      <Spinner
        name={FETCH_HOTEL_INFO}
        size="w-10 h-10"
        message={"Fetching Hotel Details..."}
        spinnerClassName="w-full"
      >
        {!isEmpty(get(selectedHotelInfo, "priceResult.hotelDetails")) && (
          <div className="bg-white">
            <header className="relative bg-primary-800">
              <div className="absolute w-full h-full top-0 left-0 bg-gradient-to-r from-contrast-900/0 to-primary-900/50"></div>
              <div className="container px-8 pt-8 pb-16 mx-auto relative">
                <h4 className="text-xl text-white mb-1 font-bold">
                  Review Hotel & Choose Room(s)
                </h4>
              </div>
            </header>
            <main className="pb-16 relative">
              <div className="container px-4 md:px-8 mx-auto -mt-8">
                <div className="grid grid-cols-12 gap-3 md:gap-8 relative">
                  <div className="col-span-12 xl:col-span-8 flex flex-col gap-2">
                    <div className="flex flex-col gap-8">
                      <div>
                        {!isEmpty(images) && (
                          <div className="flex h-80">
                            <div className="w-full md:w-2/3 bg-contrast-200 relative rounded-lg mr-2">
                            {images.length > 1 ? (
                                <Carousel
                                  className="rounded-lg"
                                  navigation={() => ""}
                                >
                                  {images.map((imageUrl, index) => (
                                    <CarouselImage
                                      imageUrl={imageUrl}
                                      key={index}
                                      hotelName={hotelName}
                                      imagesLength={images.length}
                                      handleOnClick={() => {
                                        setHotelImageIndex(index);
                                        dispatch(
                                          setSelectedModal(CAROUSAL_IMAGE_MODAL)
                                        );
                                      }}
                                    />
                                  ))}
                                </Carousel>
                              ) : (
                                <CarouselImage
                                  imageUrl={images[0]}
                                  hotelName={hotelName}
                                  imagesLength={images.length}
                                  handleOnClick={() => {
                                    setHotelImageIndex(ZERO);
                                    dispatch(
                                      setSelectedModal(CAROUSAL_IMAGE_MODAL)
                                    );
                                  }}
                                />
                              )}
                            </div>
                            {images?.length > TWO && (
                              <div className="w-1/3 hidden md:flex flex-col gap-5">
                                <div className="max-h-[47%] w-full h-full bg-contrast-200 rounded-lg relative">
                                  <button
                                    className="h-full w-full"
                                    onClick={() => {
                                      setHotelImageIndex(ONE);
                                      dispatch(
                                        setSelectedModal(CAROUSAL_IMAGE_MODAL)
                                      );
                                    }}
                                  >
                                    <img
                                      src={images?.[ONE]}
                                      className="h-full w-full object-cover rounded-lg"
                                      alt={hotelName}
                                    />
                                  </button>
                                </div>
                                <div className="max-h-[47%] w-full h-full bg-contrast-200 rounded-lg relative">
                                  <button
                                    className="h-full w-full"
                                    onClick={() => {
                                      setHotelImageIndex(TWO);
                                      dispatch(
                                        setSelectedModal(CAROUSAL_IMAGE_MODAL)
                                      );
                                    }}
                                  >
                                    <img
                                      src={images?.[TWO]}
                                      className="h-full w-full object-cover rounded-lg"
                                      alt={hotelName}
                                    />
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="text-xl flex font-black mt-4 leading-none">
                      <span>{hotelName}</span>
                      <span className="mx-3">{renderStars(starRating)}</span>
                    </div>
                    <div className="flex gap-1">
                      <span>
                        <RenderSVG
                          Svg={LocationMarker}
                          width="18"
                          height="18"
                          className="text-primary-600"
                        />
                      </span>
                      <span className="text-xs text-contrast-500">
                        {getFormattedAddress(address)}
                      </span>
                    </div>
                    {!isEmpty(mappedContactDetails) && (
                      <div className="mt-4">
                        <div className="font-black leading-none text-lg">
                          Hotel Contact Details
                        </div>
                        <div className="mt-1">
                          {mappedContactDetails.map(({ key, label, value }) => (
                            <div key={key} className="text-sm font-semibold">
                              {label}:{" "}
                              <span className="font-normal text-xs text-contrast-500">
                                {value}
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    {parsedDescription && <div className='mt-4'>
                      <div className="font-black leading-none text-lg">
                        About {hotelName}
                      </div>
                      <div className="mt-1 text-xs text-contrast-600">
                        {parsedDescription}
                        <span
                          onClick={setViewMore}
                          className="mt-1 text-xs font-medium text-primary-700 cursor-pointer"
                        >
                          {isViewMore ? (
                            <>{"(View Less)"}</>
                          ) : (
                            <>...View More</>
                          )}
                        </span>
                      </div>
                    </div>}
                    <AmentiesCard />
                    <HotelRoomOptions
                      roomOptionsRef={roomOptionsRef}
                      roomOptionsErrorRef={roomOptionsErrorRef}
                      roomCombinations={roomCombination}
                      isFixedCombination={isFixedCombination}
                      categoryId={categoryId}
                      onRoomSelection={handleRoomSelection}
                      highlightedComboIndex={highlightedComboIndex}
                    />
                  </div>
                  <div className={classNames("col-span-12 xl:col-span-4")}>
                    {!isEmpty(defaultSelectedRooms) && (
                      <PriceBreakdownCard
                        defaultSelectedRooms={defaultSelectedRooms}
                        noOfNights={noOfNights}
                        noOfRooms={noOfRooms}
                        handleDefaultRoomSelection={handleDefaultRoomSelection}
                        scrollToSection={scrollToSection}
                        roomCombinations={roomCombinations[0]?.roomCombination}
                        setHighlightedComboIndex={setHighlightedComboIndex}
                      />
                    )}
                  </div>
                </div>
              </div>
            </main>
          </div>
        )}
        {selectedModal === CAROUSAL_IMAGE_MODAL && (
          <CarousalImageModal imageIndex={hotelImageIndex} />
        )}
      </Spinner>
    </div>
  );
};

export default HotelDetails;
