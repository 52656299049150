import { useState } from "react";
import { useCollapse } from "react-collapsed";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { ChevronDown, Checkbox, RenderSVG } from "../../../assets/icons";

const FlightEmissionFilter = () => {
  const { t } = useTranslation();

  const [emission, setEmission] = useState(false);
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    duration: 200,
    defaultExpanded: true,
  });

  return (
    <div>
      <div className="flex items-center gap-2">
        <h4 className="flex-1 text-lg font-semibold text-contrast-800">
          {t("flightResults.filters.flightEmissions.header")}
        </h4>
        <span {...getToggleProps()}>
          <RenderSVG
            Svg={ChevronDown}
            alt="Chevron Down Icon"
            className={classNames({ "rotate-180": isExpanded })}
          />
        </span>
      </div>
      <div {...getCollapseProps()}>
        <div className="pt-6">
          <div className="grid grid-cols-2 gap-4">
            <div className="col-span-2">
              <div className="checbox-tab">
                <label className="cursor-pointer">
                  <input
                    type="checkbox"
                    className="peer hidden"
                    id="emission"
                    value={emission}
                    onChange={() => setEmission(!emission)}
                    checked={emission}
                  />
                  <div
                    className={classNames(
                      "flex items-center gap-2  border-2  p-4 rounded-lg",
                      {
                        "bg-primary-100 border-primary-600 text-primary-900":
                          emission,
                        "border-contrast-200 text-contrast-900": !emission,
                      }
                    )}
                  >
                    <div
                      className={classNames(
                        "w-4 h-4 shrink-0 rounded grid place-content-center border",
                        {
                          "bg-primary-600 border-primary-600": emission,
                          "border-contrast-300": !emission,
                        }
                      )}
                    >
                      <RenderSVG
                        Svg={Checkbox}
                        width="9"
                        height="9"
                        alt="Checkbox Icon"
                      />
                    </div>
                    <div>
                      <span className="text-sm font-medium flex">
                        {t(
                          "flightResults.filters.flightEmissions.showWithLowerCO2emissions"
                        )}
                      </span>
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlightEmissionFilter;
