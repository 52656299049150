import { get, isEmpty } from "lodash";
import { DEFAULT_VALUES, TBO_FARE_TYPES, FLIGHT_PROVIDERS, SPECIAL_SERVICES_TYPES } from "../constants";

const { EMPTY_STRING, EMPTY_OBJECT, ZERO, ONE, EMPTY_ARRAY } = DEFAULT_VALUES;
const { BAGGAGE } = SPECIAL_SERVICES_TYPES;
const { REGULAR } = TBO_FARE_TYPES;
const { TBO } = FLIGHT_PROVIDERS;

const PASSPORT_DOCUMENT_TYPE = "PASSPORT";
const PAN_DOCUMENT_TYPE = "PAN";
const MEALDYNAMIC = "MealDynamic";
const INFANT = "Infant";
const MEAL = "Meal";

const getSSRDetails = (key, value, itineraries, index, defaultValues) =>
  itineraries.reduce((acc, { segments }) => {
    let allSegmentSSRData = [];
    segments.forEach((segment) => {
      const { flightNumber } = segment;
      const selectedSegmentData = get(value, `${flightNumber}.${index}`);
      const segmentData = isEmpty(selectedSegmentData)
        ? defaultValues && defaultValues[flightNumber]
        : selectedSegmentData;
      allSegmentSSRData = segmentData
        ? [...allSegmentSSRData, segmentData]
        : allSegmentSSRData;
    });
    if (key === BAGGAGE && allSegmentSSRData.length) {
      allSegmentSSRData = allSegmentSSRData.slice(0, 1);
    }
    return [...acc, ...allSegmentSSRData];
  }, []);

const getTotalPassenger = (travelerPricings) =>
  travelerPricings.reduce(
    (totalPassengerCount, { travelersCount }) =>
      totalPassengerCount + travelersCount,
    ZERO
  );

const travelerMapping = { 1: "Adult", 2: "Child", 3: "Infant" };

const getFormattedFareBreakdown = (fareBreakdownDetails = []) =>
  fareBreakdownDetails.reduce(
    (updatedFareBreakdownDetails, fare) => {
      const {
        PassengerType,
        BaseFare,
        PassengerCount,
        Tax,
        YQTax,
        AdditionalTxnFeeOfrd,
        AdditionalTxnFeePub,
        TransactionFee,
        AirTransFee,
      } = fare;
      updatedFareBreakdownDetails[travelerMapping[PassengerType]] = {
        BaseFare: BaseFare / PassengerCount,
        Tax: Tax / PassengerCount,
        YQTax: YQTax / PassengerCount,
        AdditionalTxnFeeOfrd: AdditionalTxnFeeOfrd / PassengerCount,
        AdditionalTxnFeePub: AdditionalTxnFeePub / PassengerCount,
        TransactionFee: TransactionFee || ZERO,
        AirTransFee: AirTransFee || ZERO,
      };
      return updatedFareBreakdownDetails;
    },

    {}
  );

const mapDocumentInfo = ({
  isPanCardRequired,
  isPassportRequired,
  panCardDetails,
  passportDetails,
  document,
  fareType,
  isAmadeusAndInternational,
  isGoingToNepal,
  travelerType,
}) => {
  const { expireDate, issuedDate, passport, issueCountry } = passportDetails;
  const { panCardNumber } = panCardDetails;

  let documents = [
    {
      documentType: EMPTY_STRING,
      expiryDate: EMPTY_STRING,
      issuanceDate: EMPTY_STRING,
      issuanceCountry: EMPTY_STRING,
      holder: true,
    },
  ];

  if (
    isPassportRequired ||
    isAmadeusAndInternational ||
    (isGoingToNepal && travelerType !== INFANT)
  )
    documents[ZERO] = {
      documentType: PASSPORT_DOCUMENT_TYPE,
      expiryDate: expireDate,
      issuanceDate: issuedDate,
      issuanceCountry: get(issueCountry, "isoCode", EMPTY_STRING),
      holder: true,
      number: passport,
    };
  if (isPanCardRequired)
    documents = [
      ...documents,
      {
        documentType: PAN_DOCUMENT_TYPE,
        number: panCardNumber,
      },
    ];
  if (fareType !== REGULAR) {
    const { number, type } = document;
    documents = [
      ...documents,
      {
        number,
        documentType: type.value,
      },
    ];
  }
  return documents;
};

export const mapTravelersInfo = ({
  isLCC,
  travelersInformation,
  specialServicesData,
  itineraries,
  isAmadeusAndInternational,
  selectedFlightPriceInformation = [],
  fareType,
  resultIndex,
  isGoingToNepal,
  source,
  allowedBaggage,
}) => {
  const isPANRequired = get(travelersInformation, "0.isPanCardRequired", false);
  const isPassportRequired = get(
    travelersInformation,
    "0.isPassportRequired",
    false
  );
  const {
    price = EMPTY_OBJECT,
    taxes = EMPTY_OBJECT,
    travelerPricings = EMPTY_ARRAY,
  } = selectedFlightPriceInformation[ZERO];

  const { basePrice = null, currency = EMPTY_STRING } = price;
  const { taxAmount = null } = taxes;
  const passengerCount = getTotalPassenger(travelerPricings);
  const { defaultValues, SeatPreferences, Meal, ...SSRSelectedData } =
    specialServicesData;
  const selectedSSRDataNonLCC = { SeatPreferences, Meal };

  const fareBreakdownDetails = get(
    selectedFlightPriceInformation,
    `0.fareBreakdown`,
    EMPTY_ARRAY
  );
  const fareBreakdown = getFormattedFareBreakdown(
    fareBreakdownDetails[resultIndex]
  );

  return travelersInformation.map(
    (
      {
        travelerId,
        travelerType,
        profileDetails,
        gstDetails,
        passportDetails = EMPTY_OBJECT,
        panCardDetails = EMPTY_OBJECT,
        document,
        IsLeadPax,
      },
      index
    ) => {
      let SSRDataLCC = EMPTY_OBJECT;
      let SSRDataNonLCC = EMPTY_OBJECT;

      if (source === TBO) {
        SSRDataLCC = Object.entries(SSRSelectedData).reduce((acc, service) => {
          const [key, value] = service;
          const defaultKey = key.charAt(ZERO).toLowerCase() + key.slice(ONE);
          if (travelerType === INFANT && key !== MEALDYNAMIC) return acc;
          return {
            ...acc,
            [key]: getSSRDetails(
              key,
              value,
              itineraries,
              index,
              defaultValues[defaultKey]
            ),
          };
        }, {});

        SSRDataNonLCC =
          !isLCC &&
          Object.entries(selectedSSRDataNonLCC).reduce((acc, [key, value]) => {
            if (travelerType === INFANT && key !== MEAL) return acc;
            return {
              ...acc,
              [key]: value?.[resultIndex]?.[index] || {},
            };
          }, {});
      }

      const {
        dateOfBirth,
        email,
        firstName,
        gender,
        lastName,
        phoneNumber,
        phoneCode,
        nationality,
        title,
        address,
        city,
      } = profileDetails;

      return {
        id: travelerId,
        IsLeadPax,
        fareOption: null,
        travelerType: travelerType.toUpperCase(),
        Fare: fareBreakdown[travelerType] || {
          Currency: currency,
          travelerType: travelerType.toUpperCase(),
          BaseFare: basePrice / passengerCount || basePrice,
          Tax: taxAmount / passengerCount || taxAmount,
        },
        gender,
        email,
        givenName: firstName,
        familyName: lastName,
        phoneNumber: phoneNumber.slice(phoneCode.length - 1),
        phoneCode,
        dateOfBirth,
        title,
        nationality: get(nationality, "isoCode", EMPTY_STRING),
        countryCode: get(nationality, "isoCode", EMPTY_STRING),
        countryName: get(nationality, "name", EMPTY_STRING),
        address: address || EMPTY_STRING,
        city: city || EMPTY_STRING,
        documents: mapDocumentInfo({
          isPANRequired,
          isPassportRequired,
          panCardDetails,
          passportDetails,
          document,
          fareType,
          isAmadeusAndInternational,
          isGoingToNepal,
          travelerType,
        }),
        gstDetails,
        ...(isLCC ? SSRDataLCC : SSRDataNonLCC),
        allowedBaggage,
      };
    }
  );
};
