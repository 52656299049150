import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Country } from "country-state-city";
import get from "lodash/get";
import { useTranslation } from "react-i18next";
import Asterisk from "../../components/atoms/Asterisk";
import DropdownSelectField from "../../components/atoms/DropdownSelectField";
import DateSearch from "../../components/atoms/DateSearch";
import Modal from "../../components/organisms/Modal";
import { PhoneNumberInput } from "../../components/atoms/PhoneNumberInput";
import {
  selectTravelerInfo,
  addTraveler,
  updateTraveler,
  selectUserInfo,
} from "./index";
import {
  getFormattedTravelerInitialDate,
  getRequiredErrorMessage,
  mapTravelerReqBody,
  renderFieldError,
} from "../../helper";
import logEvent from "../../utils/GATracker";
import Spinner, { SPINNER_NAMES } from "../../components/organisms/Spinner";
import {
  DEFAULT_VALUES,
  REGEX,
  TRAVELER_TYPE,
  MODAL_SIZE,
  TITLES,
} from "../../constants";
import TravelerPrimaryDetailFeilds from "../../components/organisms/Traveler/TravelerPrimaryDetailFeilds";

const { UPDATE_TRAVELER, ADD_TRAVELER } = SPINNER_NAMES;
const { EMPTY_STRING, ONE, ZERO, TWO, THREE, TWELVE } = DEFAULT_VALUES;
const { EMAIL, NAME, PHONE_NUMBER } = REGEX;
const { ADULT, CHILD, HELD_INFANT } = TRAVELER_TYPE;
const { LG } = MODAL_SIZE;
const MALE = "male";

const ManageProfileTraveler = ({
  travelerType,
  showManageTraveler,
  setShowManageTraveler,
  travelerId,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isSpinnerActive, setIsSpinnerActive] = useState(false);
  const travelerInfo = useSelector(selectTravelerInfo);
  const selectedUserInfo = useSelector(selectUserInfo);
  const countryList = Country.getAllCountries();
  const isPaxAdult = travelerType === ADULT;

  const initialFormValues = {
    travelerType: travelerType || EMPTY_STRING,
    profileDetails: {
      title: TITLES[travelerType][ZERO],
      firstName: EMPTY_STRING,
      middleName: EMPTY_STRING,
      lastName: EMPTY_STRING,
      dateOfBirth: getFormattedTravelerInitialDate(travelerType),
      email: EMPTY_STRING,
      phoneNumber: EMPTY_STRING,
      gender: MALE,
      nationality: { isoCode: EMPTY_STRING },
    },
    primary: false,
  };
  const [initialValues, setInitialValues] = useState(initialFormValues);

  const nameValidation = Yup.string()
    .matches(NAME, t("profilePage.errors.nameFormat"))
    .min(THREE, t("profilePage.errors.minLength"))
    .max(25, t("profilePage.errors.maxLength"));

  const getValidatedDates = (years, daysToSkip = ZERO) => {
    const currentDate = new Date();
    currentDate.setFullYear(currentDate.getFullYear() - years);
    if (isPaxAdult) currentDate.setDate(currentDate.getDate() - daysToSkip);
    else currentDate.setDate(currentDate.getDate() + daysToSkip);

    return currentDate;
  };

  const dateValidation = {
    [ADULT]: {
      maxDate: getValidatedDates(TWELVE, ONE),
      minDate: EMPTY_STRING,
    },
    [CHILD]: {
      maxDate: getValidatedDates(TWO),
      minDate: getValidatedDates(TWELVE),
    },
    [HELD_INFANT]: {
      maxDate: getValidatedDates(ZERO),
      minDate: getValidatedDates(TWO, ONE),
    },
  };

  const validationSchema = () =>
    Yup.object({
      travelerType: Yup.string().required(),
      profileDetails: Yup.object({
        firstName: nameValidation.required(
          getRequiredErrorMessage("profilePage.firstName", t)
        ),
        middleName: Yup.string()
          .matches(NAME, t("profilePage.errors.nameFormat"))
          .max(25, t("profilePage.errors.maxLength")),
        lastName: Yup.string()
          .matches(NAME, t("profilePage.errors.nameFormat"))
          .max(25, t("profilePage.errors.maxLength")),
        email: Yup.string().when([], (values, schema) =>
          isPaxAdult
            ? schema
                .matches(EMAIL, t("profilePage.errors.emailFormat"))
                .required(getRequiredErrorMessage("profilePage.email", t))
            : schema
        ),
        phoneNumber: Yup.string()
          .matches(PHONE_NUMBER, t("profilePage.errors.phone"))
          .when([], (values, schema) =>
            isPaxAdult
              ? schema.required(getRequiredErrorMessage("profilePage.phone", t))
              : schema
          ),
        dateOfBirth: Yup.string().required(
          getRequiredErrorMessage("profilePage.dob", t)
        ),
        nationality: Yup.object().shape({
          isoCode: Yup.string().required(
            getRequiredErrorMessage("profilePage.nationality", t)
          ),
        }),
      }),
    });

  const getSelectedTravelerDetails = () => {
    const travelerData = travelerInfo.find(
      (eachTraveler) => eachTraveler.travelerId === travelerId
    );
    const savedItaCode = get(travelerData, "profileDetails.nationality");
    const nationality =
      countryList.find(({ isoCode }) => isoCode === savedItaCode) ||
      initialFormValues.nationality;
    const updatedTravelerData = {
      ...travelerData,
      profileDetails: {
        ...travelerData.profileDetails,
        nationality,
      },
    };
    return updatedTravelerData;
  };

  useEffect(() => {
    if (travelerId) {
      const selectedTraveler = getSelectedTravelerDetails();

      if (selectedTraveler)
        setInitialValues({ ...selectedTraveler, travelerType });
    } else setInitialValues({ ...initialFormValues, travelerType });
  }, [travelerId, travelerType, travelerInfo]);

  const handleOnSubmit = (values, { resetForm }) => {
    if (!selectedUserInfo) return;
    const { id: userId } = selectedUserInfo || {};

    if (travelerId) {
      logEvent(`update_traveler_btn_clicked_from_profile`)
      dispatch(
        updateTraveler({
          body: mapTravelerReqBody(values),
          id: travelerId,
        })
      ).then(() => {
        handleFormClose(resetForm);
      });
    } else {
      logEvent(`save_traveler_btn_clicked_from_profile`)
      dispatch(
        addTraveler({
          body: mapTravelerReqBody(values),
          id: userId,
        })
      ).then((res) => {
        handleFormClose(resetForm);
      });
    }
  };

  const handleFormClose = (resetForm) => {
    setShowManageTraveler(false);
    resetForm();
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema()}
      validateOnMount={true}
      onSubmit={handleOnSubmit}
      enableReinitialize
    >
      {({
        values,
        errors,
        touched,
        isValid,
        dirty,
        setFieldError,
        validateForm,
        resetForm,
        handleChange,
        setFieldTouched,
        setFieldValue,
      }) => (
        <Modal
          size={LG}
          handleClose={() => handleFormClose(resetForm)}
          shouldShowModalFromProps={showManageTraveler}
          title={
            <p className="flex-1 text-2xl font-bold text-contrast-900">
              {t("profilePage.manageTraveler")}
            </p>
          }
        >
          <Form>
            <div className="py-6 px-3 sm:px-6">
              <div className="grid grid-cols-12 gap-6">
                <TravelerPrimaryDetailFeilds travelerType={travelerType} />
                <div className="col-span-12 sm:col-span-6 md:col-span-4">
                  <div className="w-full">
                    <label className="block text-sm font-medium mb-1 text-contrast-900">
                      {t("profilePage.dob")}
                      <Asterisk />
                    </label>
                    <div className="bg-white rounded-md text-contrast-900 text-sm font-normal w-full">
                      <DateSearch
                        name="profileDetails.dateOfBirth"
                        touched={touched}
                        values={values}
                        minDate={dateValidation[travelerType].minDate}
                        maxDate={dateValidation[travelerType].maxDate}
                        required
                        showCalendarIcon={true}
                        setFieldValue={setFieldValue}
                        onChange={handleChange}
                        setFieldError={setFieldError}
                        setFieldTouched={setFieldTouched}
                      />
                    </div>
                    <div>
                      {renderFieldError(
                        "profileDetails.dateOfBirth",
                        errors,
                        touched
                      )}
                    </div>
                  </div>
                </div>
                {isPaxAdult && (
                  <>
                    <div className="col-span-12 sm:col-span-6 md:col-span-4">
                      <div className="form-group">
                        <label className="block text-sm font-medium mb-1 text-contrast-900">
                          {t("profilePage.email")}
                          <Asterisk />
                        </label>
                        <Field
                          id="email"
                          name="profileDetails.email"
                          type="text"
                          className="form-control h-[38px] block w-full text-sm py-2 px-3 border-contrast-300 rounded-md placeholder:text-blue-contrast-500"
                          placeholder={t("profilePage.placeholders.email")}
                        />
                        <div>
                          {renderFieldError(
                            "profileDetails.email",
                            errors,
                            touched
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-12 sm:col-span-6 md:col-span-4">
                      <div className="form-group">
                        <label className="block text-sm font-medium mb-1 text-contrast-900">
                          {t("profilePage.phone")}
                          <Asterisk />
                        </label>
                        <div className=" mb-2 w-full justify-between rounded-md border h-[38px] bg-white flex space-x-1">
                          <PhoneNumberInput
                            name="profileDetails.phoneNumber"
                            values={values}
                            placeholder={"loginAndSignup.placeholders.phone"}
                            showFormattedNumber
                            setFieldTouched={setFieldTouched}
                            setFieldValue={setFieldValue}
                            t={t}
                          />
                        </div>
                        <div>
                          {renderFieldError(
                            "profileDetails.phoneNumber",
                            errors,
                            touched
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className="col-span-12 sm:col-span-6 md:col-span-4">
                  <div className="form-group">
                    <label className="block text-sm font-medium mb-1 text-contrast-900">
                      {t("profilePage.nationality")}
                      {<Asterisk />}
                    </label>
                    <DropdownSelectField
                      name="profileDetails.nationality"
                      value={values.profileDetails.nationality}
                      setFieldTouched={setFieldTouched}
                      setFieldValue={setFieldValue}
                      selectableValues={countryList}
                      valueToShow="name"
                      validateForm={validateForm}
                      placeholder={t("profilePage.placeholders.nationality")}
                    />
                    <div>
                      {renderFieldError(
                        "profileDetails.nationality.isoCode",
                        errors,
                        touched
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-6 flex gap-4 justify-end border-t border-contrast-200">
              <button
                type="reset"
                className="border-contrast-300 shadow-sm text-sm text-contrast-700 font-medium py-[10px] px-4 flex items-center gap-2 rounded-md bg-white hover:bg-contrast-50 active:bg-white border"
                onClick={() => setShowManageTraveler(false)}
              >
                {t("profilePage.actions.cancel")}
              </button>
              <button
                className="text-white font-medium disabled:cursor-not-allowed disabled:bg-primary-400 px-4 rounded-md bg-primary-600 hover:bg-primary-700 active:bg-primary-600 shadow-sm text-sm"
                type="submit"
                disabled={!isValid || !dirty || isSpinnerActive}
              >
                <Spinner
                  name={travelerId ? UPDATE_TRAVELER : ADD_TRAVELER}
                  setIsSpinnerActive={setIsSpinnerActive}
                  persistSize
                >
                  {travelerId
                    ? t("profilePage.actions.update")
                    : t("profilePage.actions.save")}
                </Spinner>
              </button>
            </div>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default ManageProfileTraveler;
