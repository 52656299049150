import { useRef } from "react";
import get from "lodash/get";
import DatePicker from "react-multi-date-picker";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { useFormikContext } from "formik";
import {
  getCalendarDatePrices,
  selectCalendarDatePrices,
} from "../../organisms/Search";
import { Calendar as CalendarSVG, RenderSVG } from "../../../assets/icons";
import {
  SEARCH_SECTION,
  TRIP_TYPES,
  DEFAULT_VALUES,
  DEFAULT_USER_IP_ADDRESS,
  DATE_FORMAT,
  CURRENCY_SYMBOLS,
} from "../../../constants";
import { selectCountryInfo } from "../../../screens/Profile";
const { ROUND_TRIP } = TRIP_TYPES;
const { ONE, TWO, EMPTY_OBJECT, EMPTY_STRING } = DEFAULT_VALUES;
const { HOTEL } = SEARCH_SECTION;
const DASH_STRING = "--";
const noOfMonthsInCalendar = window.innerWidth > 750 ? TWO : ONE;

const DateSelector = ({
  name,
  defaultDates,
  minDate,
  maxDate,
  tripType,
  travelType,
  range = false,
  height = "36px",
  placeholder,
  showPrices = false,
  showCalendarIcon = false,
  inputClasses,
  handleChangeFromProps = () => {},
  fareCalendarPrices = EMPTY_OBJECT,
  onClose = () => {},
  portal = false,
}) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const prices = useSelector(selectCalendarDatePrices);
  const selectedCountryInfo = useSelector(selectCountryInfo);
  const { setFieldValue, setFieldTouched, values } = useFormikContext();
  const selectedDate = get(values, `${name}`);
  const currencySymbol = get(
    selectedCountryInfo,
    "currency.symbol",
    CURRENCY_SYMBOLS.INR
  );
  const handleOnChange = (value) => {
    if (tripType === ROUND_TRIP || travelType === HOTEL) {
      value.length === TWO && ref.current.closeCalendar();
      return handleChangeFromProps(value);
    }
    setFieldTouched(name, true);
    const selectedDate = value.format(DATE_FORMAT);
    setFieldValue(name, selectedDate);
  };
  const handleCalendarPrices = () => {
    const { origin, destination } = fareCalendarPrices;
    showPrices &&
      dispatch(
        getCalendarDatePrices({
          queryParams: {
            origin,
            destination,
          },
          body: {
            JourneyType: "1",
            EndUserIp: DEFAULT_USER_IP_ADDRESS,
            PreferredAirlines: null,
            Segments: [
              {
                Origin: origin,
                Destination: destination,
                FlightCabinClass: "1",
                PreferredDepartureTime: new Date().toISOString(),
              },
            ],
            Sources: null,
          },
        })
      );
  };
  const getPrice = (date) =>
    prices[date] ? currencySymbol + prices[date] : DASH_STRING;
  const getDateMapppings = ({ date }) => {
    return {
      children: (
        <>
          <p
            className={classNames("text-sm m-[-8px]", {
              "pt-5": showPrices,
              "py-5": !showPrices,
            })}
          >
            {date.format("D")}
          </p>
          {showPrices && (
            <p className="text-[10px] font-thin">
              {getPrice(date.format(DATE_FORMAT))}
            </p>
          )}
        </>
      ),
    };
  };
  return (
    <div
      id="date-selector"
      className="h-fit relative flex items-center bg-white w-full rounded-md hover:ring-2 hover:ring-primary-50"
    >
      {showCalendarIcon && (
        <RenderSVG
          Svg={CalendarSVG}
          alt="Calendar Icon"
          className="absolute z-10 ml-3 cursor-pointer text-contrast-400"
          onClick={() => !ref.current.isOpen && ref.current.openCalendar()}
        />
      )}
      <input
        type="text"
        value={selectedDate || EMPTY_STRING}
        onClick={() => !ref.current.isOpen && ref.current.openCalendar()}
        onChange={() => {}}
        placeholder={placeholder}
        className={classNames(
          `border border-contrast-300 absolute h-[53px] bg-white !text-black cursor-pointer !w-full ${inputClasses} focus:border-contrast-300 focus:outline-none focus:shadow-none rounded-lg`,
          {
            "!pl-10": showCalendarIcon,
          }
        )}
      />
      <DatePicker
        ref={ref}
        numberOfMonths={noOfMonthsInCalendar}
        onOpen={handleCalendarPrices}
        placeholder={placeholder}
        shadow={false}
        range={range}
        rangeHover={range}
        format={DATE_FORMAT}
        highlightToday={false}
        mapDays={getDateMapppings}
        dateSeparator="  |  "
        value={
          tripType !== ROUND_TRIP && travelType !== HOTEL
            ? selectedDate
            : defaultDates
        }
        onChange={handleOnChange}
        editable={false}
        onClose={onClose}
        minDate={minDate}
        maxDate={maxDate}
        portal={portal}
        className="date-selector"
        zIndex={1000}
        style={{
          width: "100%",
          boxSizing: "border-box",
          height,
        }}
        inputClass={classNames(
          `border border-contrast-300 !text-black cursor-pointer !w-full ${inputClasses} focus:border-contrast-300 focus:outline-none focus:shadow-none rounded-lg`,
          {
            "!pl-8": showCalendarIcon,
          }
        )}
        containerClassName="black !w-full !overflow-hidden !rounded-md calendar"
      />
    </div>
  );
};
export default DateSelector;
