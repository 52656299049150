import { useEffect, useState, memo } from "react";
import { get, isEmpty } from "lodash";
import { Tooltip } from "react-tooltip";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import {
  Lightning,
  Star,
  IndianCoinIcon,
  DeparturePlaneIcon,
  ArrivalPlaneIcon,
  RenderSVG,
} from "../../assets/icons";
import {
  DEFAULT_VALUES,
  FLIGHTS_RESULT_TYPE,
  SORTING_HEADERS,
  ACTIVE_SORT_MESSAGE,
  ROUNDTRIP_RESULT_FORMATS,
} from "../../constants";
import { getSortedFlights } from "../../helper";
import {
  selectRoundTripResultsFormat,
  selectSelectedFlightId,
} from "./flightResults.selectors";
import {
  selectFilteredFlights,
  setActiveSortOrder,
  setSortedFlights,
} from "../../components/organisms/Search";
import { actions as flightActions } from "./flightResults.reducer";
import {
  selectSortedFlights,
  selectActiveSortOrder,
} from "../../components/organisms/Search/search.selectors";
import { selectFlightInfo } from "./index";
import logEvent from "../../utils/GATracker";

const { ZERO, ONE, EMPTY_ARRAY } = DEFAULT_VALUES;
const { OUTBOUND_RESULT, PACKAGE_RESULT } = FLIGHTS_RESULT_TYPE;
const { setSelectedFlightId, setSelectedFlightInfo } = flightActions;
const { cheapest, nonStopFirst, fastest, best, arrival, departure } =
  SORTING_HEADERS;
const { ISOLATED } = ROUNDTRIP_RESULT_FORMATS;

const SortingHeaders = ({ numberOfNonStops = ZERO, type = PACKAGE_RESULT }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const sortedFlights = useSelector(selectSortedFlights);
  const selectedFlightId = useSelector(selectSelectedFlightId);
  const selectedFlightInfo = useSelector(selectFlightInfo);
  const roundTripResultsFormat = useSelector(selectRoundTripResultsFormat);
  const filteredFlights = useSelector(selectFilteredFlights);
  const selectedActiveSort = useSelector(selectActiveSortOrder);

  const [sortedFlightLength, setSortedFlightLength] = useState();
  const [availableSortOrders, setAvailableSortOrders] = useState(EMPTY_ARRAY);

  const isolated = { ...sortedFlights.isolated };
  const packages = { ...sortedFlights.packages };

  const updateActiveSortOrder = () => {
    const isDifferentNoOfStops =
      numberOfNonStops !== ZERO &&
      numberOfNonStops < sortedFlights.packages.length;
    isDifferentNoOfStops &&
      selectedActiveSort.packages === fastest.name &&
      dispatch(
        setActiveSortOrder({ activeSort: nonStopFirst.name, PACKAGE_RESULT })
      );

    !isDifferentNoOfStops &&
      selectedActiveSort.packages === nonStopFirst.name &&
      dispatch(
        setActiveSortOrder({ activeSort: fastest.name, PACKAGE_RESULT })
      );
  };

  useEffect(() => {
    if (
      !sortedFlights ||
      (isEmpty(sortedFlights.packages) && isEmpty(sortedFlights.isolated))
    )
      return;
    else {
      if (!isEmpty(sortedFlights.packages) && type === PACKAGE_RESULT)
        setSortedFlightLength(sortedFlights?.packages.length);
      else if (!isEmpty(sortedFlights.isolated) && type !== PACKAGE_RESULT)
        setSortedFlightLength(sortedFlights.isolated[type].length);
    }
  }, [sortedFlights]);

  useEffect(() => {
    if (!roundTripResultsFormat) return;
    if (roundTripResultsFormat === ISOLATED) {
      setAvailableSortOrders([
        {
          name: cheapest.name,
          icon: IndianCoinIcon,
          tooltip: ACTIVE_SORT_MESSAGE[cheapest.name],
          title: cheapest.title,
        },
        {
          name: departure.name,
          icon: DeparturePlaneIcon,
          tooltip: ACTIVE_SORT_MESSAGE[departure.name],
          title: departure.title,
        },
        {
          name: arrival.name,
          icon: ArrivalPlaneIcon,
          tooltip: ACTIVE_SORT_MESSAGE[arrival.name],
          title: arrival.title,
        },
        {
          name: fastest.name,
          icon: Lightning,
          tooltip: ACTIVE_SORT_MESSAGE[fastest.name],
          title: fastest.title,
        },
      ]);
    } else if (!isEmpty(packages)) {
      updateActiveSortOrder();

      setAvailableSortOrders([
        {
          name: cheapest.name,
          icon: IndianCoinIcon,
          tooltip: ACTIVE_SORT_MESSAGE[cheapest.name],
          title: cheapest.title,
        },
        {
          name:
            numberOfNonStops !== ZERO &&
              numberOfNonStops < sortedFlights.packages.length
              ? nonStopFirst.name
              : fastest.name,
          icon: Lightning,
          tooltip:
            availableSortOrders[ONE]?.name === nonStopFirst
              ? ACTIVE_SORT_MESSAGE[nonStopFirst.name]
              : ACTIVE_SORT_MESSAGE[fastest.name],
          title:
            numberOfNonStops !== ZERO &&
              numberOfNonStops < sortedFlights.packages.length
              ? nonStopFirst.title
              : fastest.title,
        },
        {
          name: best.name,
          icon: Star,
          tooltip: ACTIVE_SORT_MESSAGE[best.name],
          title: best.title,
        },
      ]);
    }
  }, [sortedFlightLength, roundTripResultsFormat, sortedFlights]);

  useEffect(() => {
    if (roundTripResultsFormat === ISOLATED) {
      const targetOutBoundFlights = get(
        sortedFlights,
        "isolated.outbound",
        EMPTY_ARRAY
      );
      const targetInBoundFlights = get(
        sortedFlights,
        "isolated.inbound",
        EMPTY_ARRAY
      );
      const sortedOutBoundArray = getSortedFlights(
        targetOutBoundFlights,
        selectedActiveSort.outbound
      );
      const sortedInBoundArray = getSortedFlights(
        targetInBoundFlights,
        selectedActiveSort.inbound
      );
      dispatch(
        setSortedFlights({
          isolated: {
            outbound: sortedOutBoundArray,
            inbound: sortedInBoundArray,
          },
          packages: sortedFlights.packages,
        })
      );
    } else {
      const targetPackagedFlights = get(sortedFlights, "packages", EMPTY_ARRAY);
      const sortedPackages = getSortedFlights(
        targetPackagedFlights,
        selectedActiveSort.packages
      );
      dispatch(
        setSortedFlights({
          isolated,
          packages: sortedPackages,
        })
      );
    }
  }, [
    sortedFlightLength,
    roundTripResultsFormat,
    selectedActiveSort,
    filteredFlights,
  ]);

  const handleSorting = (name, type) => {
    logEvent(`shortby_${name}_flights_selected`)
    dispatch(setSelectedFlightId({ ...selectedFlightId, [type]: null }));
    if (type === PACKAGE_RESULT)
      dispatch(
        setSelectedFlightInfo({
          ...selectedFlightInfo,
          [OUTBOUND_RESULT]: null,
        })
      );
    else
      dispatch(
        setSelectedFlightInfo({
          ...selectedFlightInfo,
          [type]: null,
        })
      );
    dispatch(setActiveSortOrder({ activeSort: name, type }));
    const sortSection =
      roundTripResultsFormat === ISOLATED
        ? sortedFlights.isolated[type]
        : sortedFlights.packages;
    const sortedArray = getSortedFlights(sortSection, name);
    if (type !== PACKAGE_RESULT)
      dispatch(
        setSortedFlights({
          isolated: {
            ...isolated,
            [type]: sortedArray,
          },
          packages: sortedFlights.packages,
        })
      );
    else
      dispatch(
        setSortedFlights({
          isolated,
          packages: sortedArray,
        })
      );
  };

  return (
    <div className="mb-2 shadow-lg gap-3">
      <div className="flex border rounded-t-xl overflow-hidden">
        <Tooltip
          id="sortHeader-tooltip"
          className="!bg-primary-900 !rounded-lg !z-50"
          render={({ content }) => (
            <span className="text-xs text-justify">{t(content)}</span>
          )}
        />
        {availableSortOrders.map(({ name, icon, tooltip, title }, index) => (
          <button
            className={classNames(
              "flex-1 lg:p-0.5 p-2 cursor-pointer h-11 relative border-r-2 text-xs lg:text-lg font-bold items-center",
              { "!border-r-0": index === availableSortOrders.length - 1 },
              {
                "bg-indigo-100 border-b-4 border-b-indigo-500":
                  selectedActiveSort[type] === name,
              }
            )}
            type="button"
            onClick={() => handleSorting(name, type)}
            key={name}
            data-tooltip-id="sortHeader-tooltip"
            data-tooltip-content={tooltip}
            data-tooltip-place="top"
          >
            <div className="flex gap-2 justify-center items-center">
              <div className="hidden lg:flex">
                <RenderSVG Svg={icon} className="text-sm" alt="icon" />
              </div>
              <div className="flex">
                <span>{t(title)}</span>
              </div>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};

export default memo(SortingHeaders);
