import React, { useState } from "react";
import { cloneDeep, get, uniqueId } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { Cross, Gender, RenderSVG } from "../../../../assets/icons";
import {
  DEFAULT_VALUES,
  CURRENCY_SYMBOLS,
  SEAT_TYPE_INFO,
  TRAVELER_TYPE,
} from "../../../../constants";
import { selectSelectedTravelers } from "../flightBookings.selectors";
import { priceFormatter } from "../../../../helper";
import { selectSelectedLCCSeats } from "../index";
import { actions } from "../flightBookings.reducer";
import { selectCountryInfo } from "../../../Profile";
import logEvent from "../../../../utils/GATracker";

const { setselectedLCCSeats } = actions;

const { ZERO, ONE, SIX, EMPTY_STRING, EMPTY_ARRAY } = DEFAULT_VALUES;
const { HELD_INFANT } = TRAVELER_TYPE;

const BLOCKED = 0;
const RESERVED = 3;
const SIXTY_FIVE = 65;
const AISLE = "Aisle";
const EXIT = "Exit";
const RECLINE = "Recline";

const generateEmptySeat = (xCoordinate, yCoordinate) => (
  <li
    className="seat mx-auto fw-medium"
    key={`sequence_${xCoordinate}_${yCoordinate}`}
  ></li>
);

const HeaderFooter = ({ t, isEndRow }) => {
  return (
    <div className={`flex  ${isEndRow ? "flex-col-reverse" : "flex-col"}`}>
      <div className="flex gap-6 py-2">
        <div className="p-2 flex-1 bg-contrast-200 border border-contrast-400 rounded-lg">
          <RenderSVG
            className="mx-auto"
            Svg={Gender}
            width="40"
            height="40"
            alt="Gender Icon"
          />
        </div>
        <div className="p-2 flex-1 bg-contrast-200 border border-contrast-400 rounded-lg">
          <RenderSVG
            className="mx-auto"
            Svg={Gender}
            width="40"
            height="40"
            alt="Gender Icon"
          />
        </div>
      </div>
      <div className="flex gap-6 text-red-900 text-xs font-semibold justify-between">
        <div className="border-l-2 pl-2 border-red-900">
          {t("seatSelection.exit")}
        </div>
        <div className="border-r-2 pr-2 border-red-900">
          {t("seatSelection.exit")}
        </div>
      </div>
    </div>
  );
};

const getMinAndMaxSeatPrices = (seatData) => {
  let lowestPrice = Number.MAX_VALUE; // Initialize with a very high value
  let highestPrice = 0; // Initialize with zero
  let hasValidPrice = false;

  seatData.forEach((row) => {
    row.seats.forEach((seat) => {
      const price = seat.Price;
      if (price > 0) {
        hasValidPrice = true;
        if (price < lowestPrice && price != 0) lowestPrice = price;
        if (price > highestPrice) highestPrice = price;
      }
    });
  });

  if (!hasValidPrice) lowestPrice = 0;

  lowestPrice = Math.floor(lowestPrice / 100) * 100;
  highestPrice = Math.ceil(highestPrice / 100) * 100;
  return { lowestPrice, highestPrice };
};

const LegendCard = ({
  t,
  lowestPrice,
  highestPrice,
  averagePrice,
  currencySymbol,
}) => {
  return (
    <div className="inline-flex flex-col text-start bg-white border border-gray-200 rounded-lg p-6 mt-auto">
      <h4 className="text-base font-bold text-gray-900 mb-4 pb-4 border-b border-gray-200">
        {t("seatSelection.legendCard.title")}
      </h4>
      <ul className="grid grid-cols-2 gap-4">
        <li className="flex items-center gap-4">
          <div className="flex items-center gap-4 flex-1">
            <div className="w-8 h-8 border border-gray-400 bg-[#14ae5c] rounded"></div>
            <div className="flex flex-col">
              <h6 className="text-md font-medium text-gray-900">
                {t("seatSelection.legendCard.seatClass3")}
              </h6>
            </div>
          </div>
        </li>
        {highestPrice > 0 && (
          <>
            <li className="flex items-center gap-4">
              <div className="flex items-center gap-4 flex-1">
                <div className="w-8 h-8 border border-gray-400 bg-gray-400 rounded"></div>
                <div className="flex flex-col">
                  <h6 className="text-md font-medium text-gray-900">{`${currencySymbol} ${priceFormatter(
                    lowestPrice
                  )}-${priceFormatter(averagePrice)}`}</h6>
                </div>
              </div>
            </li>
            <li className="flex items-center gap-4">
              <div className="flex items-center gap-4 flex-1">
                <div className="w-8 h-8 border border-gray-400 bg-gray-600 rounded"></div>
                <div className="flex flex-col">
                  <h6 className="text-md font-medium text-gray-900">{`${currencySymbol} ${priceFormatter(
                    averagePrice + ONE
                  )}-${priceFormatter(highestPrice)}`}</h6>
                </div>
              </div>
            </li>
          </>
        )}

        <li className="flex items-center gap-4">
          <div className="flex items-center gap-4 flex-1">
            <div className="w-8 h-8 border border-gray-400 bg-white rounded relative pr-1 before:absolute before:top-0 before:right-0 before:w-3 before:h-3 before:bg-[#e74c3c] before:rounded-bl-full"></div>
            <div className="flex flex-col">
              <h6 className="text-md font-medium text-gray-900">
                {t("seatSelection.legendCard.seatInfo3")}
              </h6>
            </div>
          </div>
        </li>
        <li className="flex items-center gap-4">
          <div className="flex items-center gap-4 flex-1">
            <div className="w-8 h-8 border border-gray-400 bg-white rounded ">
              <div className="h-full border-b-4 border-black rounded"></div>
            </div>
            <div className="flex flex-col">
              <h6 className="text-md font-medium text-gray-900">
                {t("seatSelection.legendCard.seatInfo4")}
              </h6>
            </div>
          </div>
        </li>
        <li className="flex items-center gap-4">
          <div className="flex items-center gap-4 flex-1">
            <div className="w-8 h-8 grid place-content-center">
              <RenderSVG Svg={Cross} width="40" height="40" alt="Cross Icon" />
            </div>
            <h6 className="text-md font-medium text-gray-900">
              {t("seatSelection.legendCard.seatInfo1")}
            </h6>
          </div>
        </li>
        <li className="flex items-center gap-4">
          <div className="flex items-center gap-4 flex-1">
            <div className="w-8 h-8 border border-indigo-600 bg-indigo-600 text-white grid place-content-center font-bold rounded text-xs">
              AA
            </div>
            <h6 className="text-md font-medium text-gray-900">
              {t("seatSelection.legendCard.seatInfo2")}
            </h6>
          </div>
        </li>
      </ul>
    </div>
  );
};

const TravellerCard = ({
  t,
  filteredTravelers,
  totalNumberOfTravelers,
  selectedFlightSeats,
  flightId,
  selectionProgress,
  setSelectionProgress,
  nextFlightNumber,
  selectedTravellerIndex,
  setSelectedTravellerIndex,
  highestPrice,
  handleExternalTabSelection,
}) => {
  const selectedSeats = get(selectedFlightSeats, flightId, EMPTY_ARRAY);
  const noOfSelectedSeats = selectedSeats.filter((seat) => seat?.Code).length;

  return (
    <div
      className={`inline-flex flex-col text-start bg-white border border-gray-200 rounded-2xl pt-4 px-6 pb-2 mt-auto w-full ${
        highestPrice ? " max-h-[300px]" : "max-h-[350px]"
      }`}
    >
      <div className="flex justify-between border-b border-gray-200 mb-4 pb-2">
        <h4 className="text-base font-bold text-gray-900">
          {t("seatSelection.travelerCard.title")}
        </h4>
        <div className="font-semibold text-base">
          {noOfSelectedSeats}/{totalNumberOfTravelers}&nbsp;
          <span className="font-normal">
            {t("seatSelection.legendCard.seatInfo2")}
          </span>
        </div>
      </div>
      <ul className="flex flex-col gap-3 overflow-auto">
        {filteredTravelers.map((traveler, index) => {
          const shouldSelect = selectedTravellerIndex === index;

          return (
            <button
              onClick={() => setSelectedTravellerIndex(index)}
              key={traveler.travelerId}
            >
              <li
                className={`border  border-solid text-primary-600 text-lg font-bold p-4 rounded flex justify-between cursor-pointer ${
                  shouldSelect
                    ? "bg-indigo-50 border-primary-600"
                    : "bg-white border-gray-200"
                }`}
              >
                <div>
                  <span className="bg-primary-600 text-white p-2 mr-2 rounded-lg text-base">
                    {traveler.profileDetails.firstName.charAt(0).toUpperCase()}
                    {traveler.profileDetails.lastName.charAt(0).toUpperCase()}
                  </span>
                  {traveler.profileDetails.firstName}{" "}
                  {traveler.profileDetails.middleName}{" "}
                  {traveler.profileDetails.lastName}
                </div>
                <div>
                  {get(
                    selectedFlightSeats,
                    `${flightId}[${index}].Code`,
                    EMPTY_STRING
                  )}
                </div>
              </li>
            </button>
          );
        })}
      </ul>

      <div className="py-2 w-full mt-3 border-t border-gray-200 ">
        <button
          type="button"
          className="py-2 px-4 rounded-md  text-white w-full text-sm font-medium bg-primary-600"
          onClick={() => {
            if (nextFlightNumber > ZERO) {
              logEvent(`continue_next_flight_seat_selection_btn_selected`)
              handleExternalTabSelection(nextFlightNumber)
            } else {
              logEvent(`continue_to_meal_selection_btn_selected`)
              setSelectionProgress({
                ...selectionProgress,
                seatSelection: true,
              })
            }
          }
          }
        >
          {nextFlightNumber > ZERO
            ? t("seatSelection.travelerCard.button.title")
            : t("seatSelection.continue")}
        </button>
      </div>
    </div>
  );
};

const Decks = ({
  deck,
  flightId,
  selectionProgress,
  setSelectionProgress,
  nextFlightNumber,
  handleExternalTabSelection,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedTravellerIndex, setSelectedTravellerIndex] = useState(0);
  const selectedTravellers = useSelector(selectSelectedTravelers);
  const selectedFlightSeats = useSelector(selectSelectedLCCSeats);
  const selectedCountryInfo = useSelector(selectCountryInfo);

  const currencySymbol = get(
    selectedCountryInfo,
    "currency.symbol",
    CURRENCY_SYMBOLS.INR
  );

  const filteredTravelers = selectedTravellers.filter(
    ({ travelerType }) => travelerType !== HELD_INFANT
  );
  const totalNumberOfTravelers = filteredTravelers.length;
  const { lowestPrice, highestPrice } = getMinAndMaxSeatPrices(deck);
  const averagePrice = Math.ceil((lowestPrice + highestPrice) / 2 / 100) * 100;

  const isSeatOccupied = ({ AvailablityType }) =>
    AvailablityType === BLOCKED || AvailablityType === RESERVED;

  const groupSeatsByXCoordinate = (deck) =>
    deck.reduce((acc, { row, seats }) => ({ ...acc, [row]: seats }), {});

  const generateSeatItem = (seat, index, seatGroup) => {
    const isAisle = SEAT_TYPE_INFO[seat.SeatType]?.includes(AISLE);
    const isExitRowSeat = SEAT_TYPE_INFO[seat.SeatType]?.includes(EXIT);
    const isReclineSeat = SEAT_TYPE_INFO[seat.SeatType]?.includes(RECLINE);
    const nextSeat = seatGroup[index + ONE];
    const nextIsAisle = nextSeat
      ? SEAT_TYPE_INFO[nextSeat.SeatType]?.includes(AISLE)
      : false;
    const previousSeat = seatGroup[index - ONE];
    const isNextAlphabet =
      previousSeat &&
      seat.SeatNo.charCodeAt(ZERO) - previousSeat.SeatNo.charCodeAt(ZERO) ===
        ONE;
    const hasSpaceNext = isAisle && (nextIsAisle || !nextSeat);
    const hasSpacePrevious = isAisle && !isNextAlphabet;

    const handleSeatSelection = (selectedSeat) => {
      const updatedSeats = cloneDeep({ ...selectedFlightSeats });
      if (updatedSeats[flightId]) {
        if (
          updatedSeats[flightId].some((seat) => seat.Code === selectedSeat.Code)
        ) {
          const selectedSeatIndex = updatedSeats[flightId].findIndex(
            (seat) => seat.Code === selectedSeat.Code
          );
          updatedSeats[flightId][selectedSeatIndex] = {};
          setSelectedTravellerIndex(selectedSeatIndex);
        } else {
          updatedSeats[flightId][selectedTravellerIndex] = selectedSeat;
          setSelectedTravellerIndex((pre) =>
            pre === totalNumberOfTravelers - ONE ? ZERO : pre + ONE
          );
        }
      } else {
        updatedSeats[flightId] = Array(totalNumberOfTravelers)
          .fill({})
          .map(() => ({}));
        updatedSeats[flightId][selectedTravellerIndex] = selectedSeat;
        setSelectedTravellerIndex((pre) =>
          pre === totalNumberOfTravelers - ONE ? ZERO : pre + ONE
        );
      }
      logEvent(`seat_selected`)
      dispatch(setselectedLCCSeats(updatedSeats));
    };

    return (
      <React.Fragment key={uniqueId()}>
        <li
          className="seat mx-auto fw-medium"
          data-tooltip-id="seat-tooltip"
          data-tooltip-content={
            !isSeatOccupied(seat)
              ? `${seat.Code} ${SEAT_TYPE_INFO[seat.SeatType].replace(
                  /([a-z])([A-Z])/g,
                  "$1 $2"
                )} | ${currencySymbol} ${priceFormatter(
                  get(seat, "Price", "--")
                )}`
              : "Sorry! This seat is booked"
          }
        >
          <input
            type="checkbox"
            id={`${seat.Code}`}
            readOnly={true}
            checked={selectedFlightSeats[flightId]?.some(
              (selectedSeat) => selectedSeat.Code === seat.Code
            )}
            disabled={isSeatOccupied(seat)}
            onClick={() => handleSeatSelection(seat)}
          />

          <label
            className={classNames(
              "text-[0.6rem] xs:text-xs",
              { "!bg-[#14ae5c]": seat.Price === 0 && !isSeatOccupied(seat) },
              {
                "!bg-gray-400":
                  seat.Price < averagePrice &&
                  seat.Price >= lowestPrice &&
                  !isSeatOccupied(seat),
              },
              {
                "!bg-gray-600":
                  seat.Price > averagePrice && !isSeatOccupied(seat),
              },
              {
                "relative pr-1 before:absolute before:top-0 before:right-0 before:w-3 before:h-3 before:bg-[#e74c3c] before:rounded-bl-full":
                  isExitRowSeat && !isSeatOccupied(seat),
              },
              {
                "!border-b-4 !border-b-black":
                  !isReclineSeat && isExitRowSeat && !isSeatOccupied(seat),
              }
            )}
            htmlFor={`${seat.Code}`}
          >
            {seat.Code}
          </label>
        </li>
        {hasSpaceNext && (
          <li className="seat mx-auto fw-medium font-bold">{seat.RowNo}</li>
        )}
      </React.Fragment>
    );
  };

  const renderSeatGroups = () => {
    const groupedSeats = groupSeatsByXCoordinate(deck);
    const seatGroups = [];
    const maxRowSeats = Object.values(groupedSeats).reduce(
      (maxLength, seatsArray) =>
        maxLength < seatsArray.length ? seatsArray.length : maxLength,
      ZERO
    );
    Object.keys(groupedSeats).forEach((xCoordinate) => {
      const seatGroup = groupedSeats[xCoordinate];
      const seatItems = Array(
        maxRowSeats > SIX ? maxRowSeats + ONE : maxRowSeats
      )
        .fill(ZERO)
        .map((_, yCoordinate) => generateEmptySeat(xCoordinate, yCoordinate));

      seatGroup.forEach((seatData, index) => {
        seatItems[seatData.SeatNo.charCodeAt(ZERO) - SIXTY_FIVE] =
          generateSeatItem(seatData, index, seatGroup);
      });
      seatGroups.push(
        <div className="seat-group" key={`seatGroup_${xCoordinate}`}>
          <div className="row--1">
            <ol className="seats align-items-center text-center">
              {seatItems}
            </ol>
          </div>
        </div>
      );
    });

    return seatGroups;
  };

  return (
    <div className="my-6 flex flex-col md:flex-row gap-6">
      <div className="cabin flex flex-col gap-4 fuselage overflow-y-auto max-h-[600px]">
        <div className="shrink-0 flex-1 plane w-[360px] bg-contrast-100 border border-contrast-200 p-2 sm:p-6 rounded-lg">
          <div className="cabin flex flex-col gap-4 fuselage">
            <Tooltip
              id="seat-tooltip"
              className="!bg-contrast-500 !rounded-lg !z-10"
            />
            <HeaderFooter t={t} />
            {renderSeatGroups()}
            <HeaderFooter t={t} isEndRow={true} />
          </div>
        </div>
      </div>
      <div className="">
        <div className="pb-4">
          <LegendCard
            t={t}
            deck={deck}
            lowestPrice={lowestPrice}
            highestPrice={highestPrice}
            averagePrice={averagePrice}
            currencySymbol={currencySymbol}
          />
        </div>
        <div>
          <TravellerCard
            t={t}
            filteredTravelers={filteredTravelers}
            totalNumberOfTravelers={totalNumberOfTravelers}
            selectedFlightSeats={selectedFlightSeats}
            flightId={flightId}
            selectionProgress={selectionProgress}
            setSelectionProgress={setSelectionProgress}
            nextFlightNumber={nextFlightNumber}
            selectedTravellerIndex={selectedTravellerIndex}
            setSelectedTravellerIndex={setSelectedTravellerIndex}
            highestPrice={highestPrice}
            handleExternalTabSelection={handleExternalTabSelection}
          />
        </div>
      </div>
    </div>
  );
};

export default Decks;
