import React, { useState, useEffect } from "react";
import { v4 as uuid } from "uuid";
import { MODAL_SIZE } from "../../../../constants";
import Modal, { setSelectedModal } from "../../Modal";
import { useDispatch, useSelector } from "react-redux";
import { getAmenityIcon } from "../../../../assets/icons/Amenities";
import { isEmpty } from "lodash";
import { getAllHotelsImages } from "../../../../helper";
import { Carousel } from "@material-tailwind/react";
import classNames from "classnames";
import { DEFAULT_VALUES } from "../../../../constants";
import { selectSelectedHotelViewMore } from "../../../../screens/HotelInfo";

const { ZERO, EMPTY_ARRAY } = DEFAULT_VALUES;
const { MD } = MODAL_SIZE;
const PHOTOS = "photos";
const AMENITIES = "amenities";
const POLICIES = "policies";

const HotelViewMoreModal = () => {
  const dispatch = useDispatch();
  const [showAllHotels, setShowAllHotels] = useState(false);
  const [activeTab, setActiveTab] = useState(null);
  let  activeTabs=[];
  const { hotelAmenities, hotelImages, hotelPolicy, hotelName } = useSelector(
    selectSelectedHotelViewMore
  );

  useEffect(() => {
    setActiveTab(activeTabs[ZERO])
  }, EMPTY_ARRAY)

  const transformPolicyObject = (policyObject) => {
    return Object.entries(policyObject).map(([key, value]) => ({
      policyName: key
        .replace(/([A-Z])/g, " $1")
        .replace(/^./, (str) => str.toUpperCase()),
      policyValue: value,
    }));
  };

  const policies = transformPolicyObject(hotelPolicy);
  const handleClose = () => dispatch(setSelectedModal(null));
  const hotelImgs = getAllHotelsImages(hotelImages);

  const renderTabButton = (tabKey, label) => {
    const isTabDataEmpty = {
      [PHOTOS]: isEmpty(hotelImgs),
      [AMENITIES]: isEmpty(hotelAmenities),
      [POLICIES]: isEmpty(policies),
    }[tabKey];

    return (
      !isTabDataEmpty && activeTabs.push(tabKey) && (
        <button
          onClick={() => setActiveTab(tabKey)}
          className={`p-4 w-full text-md font-semibold transition-all duration-500 ${
            activeTab === tabKey
              ? "border-b-4 text-primary-600 border-primary-600"
              : "border-b-4 text-contrast-600"
          }`}
        >
          {label}
        </button>
      )
    );
  };

  return (
    <Modal
      size={MD}
      handleClose={handleClose}
      title={hotelName}
      modalClassname='!h-[86dvh] !w-[78vw] flex flex-col oveflow-auto'
      titleClassname='top-0 sticky border-b bg-white z-50'
    >
      <div className={classNames("p-4 pt-0 relative", {
        "overflow-y-auto": activeTab === AMENITIES,
      })}>
        <div className='sticky top-0 z-10 text-xs md:text-sm lg:text-md flex border-b border-gray-200 justify-evenly bg-white'>
          {renderTabButton(PHOTOS, "Photo Gallery")}
          {renderTabButton(AMENITIES, "Amenities")}
          {renderTabButton(POLICIES, "Policies")}
        </div>

        <div className=''>
          {activeTab === POLICIES && (
            <div>
              {!isEmpty(policies) && (
                <div className='rounded-lg shadow-md p-5 mt-5'>
                  <div>
                    {policies.map((policy, index) => (
                      <p
                        className='text-contrast-500 md:text-base text-xs p-3 font-semibold'
                        key={index}
                      >
                        {index + 1}.{" "}
                        <span className='text-primary-600'>
                          {policy.policyName}:
                        </span>{" "}
                        {policy.policyValue}
                      </p>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}

          {activeTab === AMENITIES && (
            <div>
              {!isEmpty(hotelAmenities) && (
                <div className='rounded-lg shadow-md p-2'>
                  <div className='grid grid-cols-3'>
                    {hotelAmenities.map((amenity) => (
                      <div
                        key={amenity}
                        className='col-span-5 md:col-span-1 flex m-1 self-center text-left p-3 rounded-md'
                      >
                        <img
                          src={getAmenityIcon(amenity)}
                          className='w-4 md:w-5'
                          alt='hotel-amenity'
                        />
                        <div className='text-xs md:text-sm lg:text-md flex items-center capitalize ml-3 text-contrast-500'>
                          {amenity}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}

          {activeTab === PHOTOS && (
            <div>
              {!isEmpty(hotelImgs) && (
                <div className='flex h-[60dvh]'>
                  <div className='w-fit md:w-2/3 bg-contrast-200 relative rounded-lg mr-2'>
                    <Carousel className='rounded-lg' 
                          navigation={({ setActiveIndex, activeIndex, length }) => ( showAllHotels &&
                          <div className="fixed right-[12%] h-[60dvh] w-[25%] overflow-auto hidden md:grid md:grid-cols-3 gap-3 p-3">
                            {new Array(length).fill("").map((_, i) => (
                              <div key={i}
                              className={`h-20 w-full cursor-pointer outline-indigo-600 outline-4 hover:outline hover:outline-offset-4 `}
                              onClick={() => {setActiveIndex(i), console.log(length)}}>
                                <img
                                  key={uuid()}
                                  src={hotelImgs[i]}
                                  alt={hotelName}
                                  className={classNames('h-full w-full object-cover',{
                                    'border-4 border-black':i===activeIndex
                                  })}
                                />
                              </div>
                              
                            ))}
                          </div>
                          )}
                      >
                      {hotelImgs.map((imageUrl) => (
                        <>
                          <img
                            key={uuid()}
                            src={imageUrl}
                            alt={hotelName}
                            className='h-full w-full object-cover'
                          />
                          <div className='absolute bottom-2 left-2 text-white font-bold text-base'>
                            {hotelImgs.length} images
                          </div>
                        </>
                      ))}
                    </Carousel>
                  </div>
                  {hotelImgs?.length > 2 && (!showAllHotels &&
                    <div className='w-1/3 hidden md:flex flex-col gap-5'>
                      <div className='max-h-[47%] w-full h-full bg-contrast-200  rounded-lg relative'>
                        <img
                          src={hotelImgs?.[1]}
                          className='h-full w-full object-cover rounded-lg'
                          alt={hotelName}
                        />
                      </div>
                      <div className='relative max-h-[47%] w-full h-full bg-contrast-200 rounded-lg'>
                        <img
                          src={hotelImgs?.[2]}
                          className='h-full w-full object-cover rounded-lg blur-[2px]'
                          alt={hotelName}
                        />
                        <div className='absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center cursor-pointer' onClick={()=> setShowAllHotels(true)}>
                          <div className='text-white px-4 py-2 rounded-md text-center bg-black/30'>
                            +{hotelImgs.length - 2} more images
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};
export default HotelViewMoreModal;
