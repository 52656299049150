import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { get, isEmpty } from "lodash";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import ErrorMessage from "../../atoms/ErrorMessage";
import {
  selectFromCityCodes,
  selectToCityCodes,
} from "../../organisms/Search/search.selectors";
import {
  LocationMarker,
  Aeroplane,
  RenderSVG,
  StartLocationMarker,
  ChevronDown,
  ChevronUp,
} from "../../../assets/icons";
import Spinner, { SPINNER_NAMES } from "../../organisms/Spinner";
import popularCitiesData from "../../../assets/json/popularCities.json";
import {
  DEFAULT_VALUES,
  LOCATION_TYPE,
  WINDOWS_EVENTS,
  SEARCH_SECTION,
} from "../../../constants";

const { EMPTY_STRING, EMPTY_OBJECT, ZERO, ONE, EMPTY_ARRAY } = DEFAULT_VALUES;
const { FROM, TO } = LOCATION_TYPE;
const { CLICK } = WINDOWS_EVENTS;
const { HOTEL } = SEARCH_SECTION;
const { FETCH_LOCATIONS } = SPINNER_NAMES;
const UP_KEY_CODE = 38;
const DOWN_KEY_CODE = 40;
const ENTER_KEY_CODE = 13;

const LocationPicker = ({
  name,
  handleLocationChange,
  type,
  index = ZERO,
  source,
}) => {
  const {
    validateForm,
    setFieldTouched,
    setFieldValue,
    touched,
    errors,
    values,
  } = useFormikContext();
  const fromLocations = useSelector(selectFromCityCodes);
  const toLocations = useSelector(selectToCityCodes);
  const sourceLocationCode = get(values, `${name}`, EMPTY_OBJECT);

  const [locations, setLocations] = useState();
  const [searchValue, setSearchValue] = useState(sourceLocationCode?.cityName);
  const [selectedValueIndex, setSelectedValueIndex] = useState(null);
  const [show, setShow] = useState();
  const [formattedLocations, setFormattedLocations] = useState(EMPTY_ARRAY);
  const [openLocationGroups, setOpenLocationGroups] = useState(EMPTY_OBJECT);
  const locationsOptionsRef = useRef(null);
  const inputRef = useRef();
  const dropdownRef = useRef();
  const { t } = useTranslation();
  const KEY_CODES = [UP_KEY_CODE, DOWN_KEY_CODE, ENTER_KEY_CODE];

  const renderFieldError = () =>
    get(errors, name, false) &&
    get(touched, name, false) &&
    !show && <ErrorMessage errorMessage={get(errors, name)} />;

  const handleDestinationCodeChange = (value) => {
    setFieldValue(name, value);
    if (source === HOTEL) setFieldValue("destination", value.cityName);
    else if (name.includes("destCode"))
      values.journey[index + ONE] &&
        setFieldValue(`journey[${index + ONE}].originCode`, value);
  };

  const handleLocationSelect = (item) => {
    handleDestinationCodeChange(item);
    setSearchValue(item.cityName);
    setShow(false);
  };

  useEffect(() => {
    if (type === FROM) setLocations(fromLocations);
    else if (type === TO) setLocations(toLocations);
  }, [type, fromLocations, toLocations]);

  useEffect(() => {
    !show && setSearchValue(sourceLocationCode?.cityName);
    validateForm();
  }, [show, sourceLocationCode]);

  const locationChange = (e) => {
    const inputValue = e.target.value;
    setSearchValue(inputValue);
    handleLocationChange({ inputValue, type });
  };

  const handleKeyDown = ({ keyCode }) => {
    if (KEY_CODES.includes(keyCode)) {
      let updatedSelectedIndex = selectedValueIndex;

      if (selectedValueIndex !== null) {
        if (
          keyCode === DOWN_KEY_CODE &&
          selectedValueIndex < locations?.output?.length - ONE
        )
          updatedSelectedIndex = +selectedValueIndex + ONE;
        else if (keyCode === UP_KEY_CODE && selectedValueIndex !== ZERO)
          updatedSelectedIndex = +selectedValueIndex - ONE;
        else if (keyCode === ENTER_KEY_CODE) {
          updatedSelectedIndex = +selectedValueIndex;
          document.body.click();
        }
      } else if (
        (selectedValueIndex === null ||
          selectedValueIndex > locations?.output?.length - ONE) &&
        keyCode === DOWN_KEY_CODE
      ) {
        updatedSelectedIndex = ZERO;
      }
      const selectedLocation = get(
        locations,
        `output[${updatedSelectedIndex}]`,
        EMPTY_OBJECT
      );
      handleDestinationCodeChange(selectedLocation);
      setSearchValue(selectedLocation?.cityName);
      setSelectedValueIndex(updatedSelectedIndex);
    }
  };

  const setScrollBarPosition = () => {
    const selected = locationsOptionsRef?.current?.querySelector(".active");

    if (selected) {
      selected?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        inputRef.current?.contains(e.target) ||
        dropdownRef.current?.contains(e.target)
      )
        setShow(true);
      else setShow(false);
    };
    document.addEventListener(CLICK, checkIfClickedOutside);

    return () => {
      document.removeEventListener(CLICK, checkIfClickedOutside);
    };
  }, [searchValue]);

  useEffect(() => {
    show && setFieldTouched(name, true);
  }, [show]);

  const getFormattedList = (locationsList) => {
    const groupedLocations = {};
    locationsList.forEach((item) => {
      const { cityName } = item;
      if (!groupedLocations[cityName]) {
        groupedLocations[cityName] = [];
      }
      groupedLocations[cityName].push(item);
    });
    return groupedLocations;
  };

  useEffect(() => {
    if (!isEmpty(formattedLocations)) {
      const defaultOpenStates = {};
      Object.keys(formattedLocations).forEach((cityName) => {
        defaultOpenStates[cityName] = true;
      });
      setOpenLocationGroups(defaultOpenStates);
    }
  }, [formattedLocations]);

  useEffect(() => {
    if (!isEmpty(locations?.output)) {
      const formattedLocations = getFormattedList(locations.output);
      setFormattedLocations(formattedLocations);
    }
  }, [locations]);

  const toggleOpen = (cityName) => {
    setOpenLocationGroups({
      ...openLocationGroups,
      [cityName]: !openLocationGroups[cityName],
    });
  };

  const getRenderedLocations = (groupedLocations) => {
    setTimeout(() => {
      setScrollBarPosition();
    }, [100]);

    const renderLocationItem = (cityName, item, index) => (
      <li
        key={item.id}
        className={classNames(
          "py-2 px-3 flex items-center gap-3 hover:bg-primary-100 rounded",
          { "text-blue-600": selectedValueIndex === index },
          { "ml-6": groupedLocations[cityName].length > ONE }
        )}
        role="button"
        onClick={() => handleLocationSelect({ cityName, iata: item.iata })}
      >
        <div className="icon shrink-0">
          <RenderSVG
            Svg={Aeroplane}
            width="24"
            height="24"
            alt="Aeroplane Icon"
            className="rotate-45"
            color="#D1D5DB"
          />
        </div>
        <div className="flex-1 flex gap-3 items-center">
          <div className="flex-1">
            {groupedLocations[cityName].length === ONE && (
              <h6 className="text-base font-semibold text-contrast-900 mb-1 text-ellipsis whitespace-nowrap  max-w-[220px] sm:max-w-none overflow-hidden">
                {cityName}
              </h6>
            )}
            <span className="text-xs text-contrast-500 flex">
              {item.airportName !== "N/a" && `${item.airportName}, `}{" "}
              {item.country}
            </span>
          </div>
          <span className="text-contrast-400 font-semibold">
            {item.iata.toUpperCase()}
          </span>
        </div>
      </li>
    );

    return (
      <ul className="flex flex-col" ref={locationsOptionsRef}>
        {Object.keys(groupedLocations).map((cityName) => (
          <div key={cityName}>
            {groupedLocations[cityName].length > ONE && (
              <button
                type="button"
                className="py-2 px-3 w-full font-semibold text-base text-contrast-900 flex gap-3 hover:bg-primary-100 cursor-pointer rounded"
                onClick={() => toggleOpen(cityName)}
              >
                <div className="icon shrink-0">
                  <RenderSVG
                    Svg={LocationMarker}
                    width="24"
                    height="24"
                    alt="Location Icon"
                    color="#D1D5DB"
                  />
                </div>
                <div> {cityName}</div>
                <div className="icon shrink-0 ml-auto">
                  <RenderSVG
                    Svg={openLocationGroups[cityName] ? ChevronUp : ChevronDown}
                    width="24"
                    height="24"
                    alt="chevron Icon"
                    color="#4F46E5"
                  />
                </div>
              </button>
            )}
            {groupedLocations[cityName].map((item, index) => (
              <div key={item.id}>
                {((openLocationGroups[cityName] &&
                  groupedLocations[cityName].length > ONE) ||
                  groupedLocations[cityName].length === ONE) &&
                  renderLocationItem(cityName, item, index)}
              </div>
            ))}
          </div>
        ))}
      </ul>
    );
  };

  return (
    <div
      className={classNames("col-span-2 sm:col-span-1 text-black rounded-md ", {
        "lg:col-span-4 col-span-2": source === HOTEL,
      })}
    >
      <div className="relative">
        <div className="relative">
          <div className="icon absolute left-3 top-1/2 -translate-y-1/2">
            <RenderSVG
              Svg={type === TO ? LocationMarker : StartLocationMarker}
              className="text-contrast-400"
              alt="Location Icon"
            />
          </div>
          <input
            autoComplete="off"
            type="search"
            className="bg-white rounded-md cursor-pointer shadow-sm border border-contrast-300 w-full flex items-center gap-2 py-3.5 px-3 pl-10 focus:border-contrast-300 focus:outline-none focus:shadow-none hover:ring-2 hover:ring-primary-50"
            placeholder={t(`searchSection.where${type}`)}
            ref={inputRef}
            value={searchValue || EMPTY_STRING}
            onChange={locationChange}
            onFocus={(e) => {
              e.target.select();
              setSearchValue(EMPTY_STRING);
              handleLocationChange({ inputValue: EMPTY_STRING, type });
            }}
            onKeyDown={(e) => handleKeyDown(e)}
            selected={true}
          />
        </div>
        <div>{renderFieldError()}</div>
        {show && (
          <div
            ref={dropdownRef}
            className="p-2 md:p-4 bg-white shadow-2xl rounded-lg absolute z-30 w-full sm:min-w-[498px] max-h-[348px] min-h-[150px] overflow-x-hidden overflow-auto top-full mt-2"
          >
            {searchValue && (
              <Spinner name={FETCH_LOCATIONS}>
                {!isEmpty(locations?.output) ? (
                  getRenderedLocations(formattedLocations)
                ) : (
                  <p className="text-center">{t("searchSection.noResults")}</p>
                )}
              </Spinner>
            )}
            {(!searchValue || isEmpty(locations?.output)) && (
              <div>
                <div className="text-light font-bold">
                  {t("searchSection.popularCities")}
                </div>
                {getRenderedLocations(getFormattedList(popularCitiesData))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default LocationPicker;
