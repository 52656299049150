import { createAsyncThunk } from "@reduxjs/toolkit";
import asyncAction from "../../../infrastructure/asyncAction";
import { SPINNER_NAMES } from "../../../components/organisms/Spinner";
import { DEFAULT_VALUES, REQUEST_METHODS } from "../../../constants";
import { createQueryString } from "../../../helper";

const { SPECIAL_SERVICES, PAYMENT_METHOD, FLIGHT_PAYMENT, BOOK_CALLBACK } =
  SPINNER_NAMES;
const { POST, GET, PATCH } = REQUEST_METHODS;
const { EMPTY_STRING } = DEFAULT_VALUES;

export const fetchSpecialServices = createAsyncThunk(
  "fetch-special-services",
  async (requestBody, thunkArgs) => {
    const params = {
      url: `special-services`,
      methodType: POST,
      errorMessage: "Failed to load seat map.",
      showErrorToast: true,
      spinner: SPECIAL_SERVICES,
      ...thunkArgs,
    };

    return await Promise.all(
      requestBody.map((request) =>
        asyncAction({
          body: JSON.stringify(request),
          ...params,
        })
      )
    );
  }
);

export const initiateBooking = createAsyncThunk(
  "initiate-booking",
  async ({ requestBody, url }, thunkArgs) => {
    return await asyncAction({
      url,
      methodType: POST,
      body: JSON.stringify(requestBody),
      spinner: PAYMENT_METHOD,
      showErrorToast: true,
      errorMessage: "Failed to book Flight",
      ...thunkArgs,
    });
  }
);

export const initiatePayment = createAsyncThunk(
  "flight-payment",
  async (requestBody, thunkArgs) => {
    return await asyncAction({
      url: "payment",
      methodType: POST,
      body: requestBody,
      spinner: FLIGHT_PAYMENT,
      errorMessage: "Checkout payment failed.",
      ...thunkArgs,
    });
  }
);

export const paymentGateway = createAsyncThunk(
  "payment-gateway",
  async ({ requestBody, bookingId }, thunkArgs) => {
    const queryString = createQueryString({ bookingId });

    return await asyncAction({
      url: `/paymentGatewayCallback${queryString}`,
      methodType: POST,
      body: requestBody,
      errorMessage: "Failed to generate booking.",
      ...thunkArgs,
    });
  }
);

export const getPromoCodes = createAsyncThunk(
  "get-promocodes",
  async (thunkArgs) => {
    return await asyncAction({
      url: "promocodes",
      methodType: GET,
      errorMessage: "Failed to fetch promocodes",
      ...thunkArgs,
    });
  }
);

export const bookCallback = createAsyncThunk(
  "book-callback",
  async ({ queryParams }, thunkArgs) => {
    const queryString = createQueryString(queryParams);
    return await asyncAction({
      url: `/v2/book-callback${queryString}`,
      methodType: GET,
      spinner: BOOK_CALLBACK,
      abortOnPageChange: false,
      errorMessage:
        "Unable to complete booking currently. Please try again later.",
      ...thunkArgs,
    });
  }
);

export const getPaymentMethod = createAsyncThunk(
  "get-payment-method",
  async ({ queryParams }, thunkArgs) => {
    const queryString = createQueryString(queryParams);
    return await asyncAction({
      url: `/getPaymentDetails${queryString}`,
      methodType: GET,
      abortOnPageChange: false,
      errorMessage:
        "Unable to get the payment method. Please try again later.",
      ...thunkArgs,
    });
  }
);

export const reissuanceCallback = createAsyncThunk(
  "reissuance-callback",
  async ({ requestBody, queryParams }, thunkArgs) => {
    const queryString = queryParams
      ? createQueryString(queryParams)
      : EMPTY_STRING;

    return await asyncAction({
      url: `/reissuance-callback${queryString}`,
      methodType: POST,
      body: JSON.stringify(requestBody),
      spinner: BOOK_CALLBACK,
      abortOnPageChange: false,
      errorMessage:
        "Unable to complete booking currently. Please try again later.",
      ...thunkArgs,
    });
  }
);

export const updateBookingStatus = createAsyncThunk(
  "update-booking-status",
  async ({ requestBody, id }, thunkArgs) => {
    return await asyncAction({
      url: `/bookings/${id}`,
      methodType: PATCH,
      body: requestBody,
      abortOnPageChange: false,
      errorMessage:
        "Unable to update the status of booking, please try again later.",
      ...thunkArgs,
    });
  }
);
