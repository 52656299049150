import { createAsyncThunk } from "@reduxjs/toolkit";
import asyncAction from "../../infrastructure/asyncAction";
import { createQueryString } from "../../helper/createQueryString";
import { SPINNER_NAMES } from "../../components/organisms/Spinner";
import {
  DEFAULT_VALUES,
  REQUEST_METHODS,
  shouldEnableHotels,
  BOOKING_SEARCH_OPTIONS,
} from "../../constants";

const { BOOKINGS, FETCH_LOGS, FETCH_ADMIN_BOOKING_INFO } = SPINNER_NAMES;
const { EMPTY_STRING } = DEFAULT_VALUES;
const { GET } = REQUEST_METHODS;
const { PNR } = BOOKING_SEARCH_OPTIONS;

export const fetchBookings = createAsyncThunk(
  "admin/bookings",
  async (queryParams, thunkArgs) => {
    const queryString = queryParams
      ? createQueryString(queryParams)
      : EMPTY_STRING;
    const commonRequestBody = {
      methodType: GET,
      spinner: BOOKINGS,
      isAuthRequired: true,
      abortOnPageChange: true,
      abortAwaitingRequests: true,
      errorMessage: "Unable to fetch bookings, please try again later",
      ...thunkArgs,
    };
    const requestBody = {
      flightBooking: {
        url: `bookings${queryString}`,
        ...commonRequestBody,
      },
      ...(!queryString.includes(PNR) && {
        hotelBooking: {
          url: `hotel/bookings${queryString}`,
          ...commonRequestBody,
        },
      }),
    };
    const responseArray = [];
    if (shouldEnableHotels && requestBody.hotelBooking) {
      const hotelBookingsRes = await asyncAction(requestBody.hotelBooking);
      responseArray.push(hotelBookingsRes);
    }
    if (requestBody.flightBooking) {
      const flightBookingsRes = await asyncAction(requestBody.flightBooking);
      responseArray.push(flightBookingsRes);
    }
    return responseArray;
  }
);

export const fetchBookingsById = createAsyncThunk(
  "admin/bookingsById",
  async (queryParams, thunkArgs) => {
    const isPnrSearch = queryParams?.hasOwnProperty(PNR);
    let queryString = queryParams
      ? Object.values(queryParams)[0]
      : EMPTY_STRING;
    const commonRequestBody = {
      methodType: GET,
      spinner: BOOKINGS,
      isAuthRequired: true,
      abortOnPageChange: true,
      abortAwaitingRequests: true,
      errorMessage: "Unable to fetch bookings, please try again later",
      ...thunkArgs,
    };
    const requestBody = {
      flightBooking: {
        url: `bookings${isPnrSearch ? 'ByPnr': EMPTY_STRING}/${queryString}`,
        ...commonRequestBody,
      },
      ...(!isPnrSearch && {
        hotelBooking: {
          url: `hotel/bookings/${queryString}`,
          ...commonRequestBody,
        },
      }),
    };

    const responseArray = [];
    if (shouldEnableHotels && requestBody.hotelBooking) {
      const hotelBookingsRes = await asyncAction(requestBody.hotelBooking);
      responseArray.push(hotelBookingsRes);
    }
    if (requestBody.flightBooking) {
      const flightBookingsRes = await asyncAction(requestBody.flightBooking);
      responseArray.push(flightBookingsRes);
    }
    return responseArray;
  }
);

export const fetchBookingInfo = createAsyncThunk(
  "admin/booking",
  async (bookingId, thunkArgs) => {
    return await asyncAction({
      url: `bookings/${bookingId}`,
      methodType: GET,
      spinner: FETCH_ADMIN_BOOKING_INFO,
      abortOnPageChange: false,
      isAuthRequired: true,
      errorMessage:
        "Unable to retrieve booking information, please try again later.",
      ...thunkArgs,
    });
  }
);

export const getLogsInformation = createAsyncThunk(
  "logs",
  async (queryParams, thunkArgs) => {
    const queryString = queryParams
      ? createQueryString(queryParams)
      : EMPTY_STRING;
    return await asyncAction({
      url: `/logs${queryString}`,
      methodType: GET,
      spinner: FETCH_LOGS,
      abortOnPageChange: false,
      errorMessage: "Failed to get logs information",
      ...thunkArgs,
    });
  }
);
