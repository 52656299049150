import classNames from "classnames";
import {
  getCancellationCharges,
  getFormattedLongDate,
  getFormattedTime,
  getCurrentDateTime,
  getFormattedDate,
} from "../../helper";
import { CANCELLATION_CHARGE_TYPES, DEFAULT_VALUES } from "../../constants";

const { PERCENTAGE } = CANCELLATION_CHARGE_TYPES;
const { ZERO, ONE } = DEFAULT_VALUES;

const CancellationPolicyInfo = ({ cancellationPolicies = [] }) => {
  const nonRefundable = [];
  const partialRefundable = [];

  cancellationPolicies.forEach((item) => {
    if (item.charge === 100 && item.chargeType === PERCENTAGE) {
      nonRefundable.push(item);
    } else {
      partialRefundable.push(item);
    }
  });

  const nonRefundableToDate = nonRefundable[nonRefundable.length - ONE]?.toDate;
  const nonRefundableFromDate = nonRefundable[ZERO]?.fromDate;
  const currentDate = getCurrentDateTime();
  
  const isCurrentAndnonRefundableFromDateSame = () => {
    return currentDate >= getFormattedDate(nonRefundableFromDate) ? true : false;
  }

  const isCurrentDateCrossedPartialRefundDate = () => {
    let RefundDate = "";
    if (partialRefundable) {
      if ((partialRefundable[ZERO]?.chargeType !== ONE) || (partialRefundable[0]?.chargeType === ONE && partialRefundable[ZERO]?.charge))
        RefundDate = partialRefundable[ZERO]?.fromDate;
      else if (partialRefundable?.length > ONE)
        RefundDate = partialRefundable[ONE]?.fromDate;
    }
    if (RefundDate)
      return currentDate >= getFormattedDate(RefundDate) ? true : false;
    else
      return false;
  }

  return (
    <div className="max-w-xs md:max-w-md flex items-center mb-14">
      {!isCurrentDateCrossedPartialRefundDate() && !isCurrentAndnonRefundableFromDateSame() && (
        <div className="w-full bg-green-700 text-[10px] text-white text-center rounded-s-2xl flex items-center py-0.5 px-1">
          <div className="w-3 h-3 bg-white rounded-full border-contrast-200 relative">
            <div className="absolute top-full left-1/2 -translate-x-1/2 whitespace-nowrap pt-2">
              <h6 className="font-semibold text-contrast-800 text-[10px]">Now</h6>
              <p className="text-[10px] text-contrast-500">Pay ₹0</p>
            </div>
          </div>
          <div className="flex-1 whitespace-nowrap">100% refund</div>
        </div>
      )}

      {partialRefundable.map(
          ({ charge, chargeType, fromDate, toDate, currency }, index) => {
            const refundability = `${getCancellationCharges(
              charge,
              chargeType,
              currency
            )} ${chargeType == ONE ? "Charged" : "Refund"}`

          if (charge) {
            return (
              <div className="w-full" key={fromDate}>
                <div className="relative self-stretch">
                  <div className="absolute top-full -translate-x-1/2 whitespace-nowrap pt-2">
                    <h6 className="font-semibold text-contrast-800 text-[10px] mt-4">
                      {getFormattedLongDate(fromDate, {
                        day: "numeric",
                        month: "short",
                      })}
                    </h6>
                    <h6 className="font-semibold text-contrast-800 text-[10px]">
                      {getFormattedTime(fromDate)}
                    </h6>
                  </div>
                </div>
                <div
                  className={
                    "flex-1 bg-amber-200 text-[10px] text-amber-800 text-center flex items-center py-0.5 px-2 border-l border-black"
                  }
                >
                  <div className='flex-1 whitespace-nowrap'>
                    {refundability}
                  </div>
                  {index === cancellationPolicies.length - 1 && (
                    <div className="relative">
                      <div className="w-3 h-3 ml-1 bg-white rounded-full border-contrast-200 relative"></div>
                      <div className="absolute whitespace-nowrap">
                        <h6 className="font-semibold text-contrast-800 text-[10px] mt-2">
                          {getFormattedLongDate(toDate, {
                            day: "numeric",
                            month: "short",
                          })}
                        </h6>
                        <h6 className="font-semibold text-contrast-800 text-[10px]">
                          {getFormattedTime(toDate)}
                        </h6>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          }
          }
      )}

      <div className="w-full" key={nonRefundableFromDate}>
          <div className='relative self-stretch'>
            <div className={classNames("absolute top-full -translate-x-1/2 whitespace-nowrap pt-2",{
              "ml-3": isCurrentAndnonRefundableFromDateSame(),
            })}>
              <h6 className="font-semibold text-contrast-800 text-[10px] mt-4">
                {getFormattedLongDate(nonRefundableFromDate, {
                  day: "numeric",
                  month: "short",
                })}
              </h6>
              <h6 className="font-semibold text-contrast-800 text-[10px]">
                {getFormattedTime(nonRefundableFromDate)}
              </h6>
            </div>
          </div>
          <div
            className={classNames("flex-1 bg-red-200 rounded-e-2xl text-red-800 text-[10px] text-center flex items-center py-0.5 px-2", {
              "border-l border-black rounded-none" : !isCurrentAndnonRefundableFromDateSame(),
              "border-0 rounded-s-2xl": isCurrentAndnonRefundableFromDateSame(),
            })}
          >
            {isCurrentAndnonRefundableFromDateSame() &&
              <div className="w-3 h-3 bg-white rounded-full border-contrast-200 relative"></div>
            }
            <div className="flex-1 whitespace-nowrap">{"No Refund"}</div>
            <div className="relative">
              <div className="w-3 h-3 ml-1 bg-white rounded-full border-contrast-200 relative"></div>
              <div className="absolute whitespace-nowrap -ml-2">
                <h6 className="font-semibold text-contrast-800 text-[10px] mt-2">
                  {getFormattedLongDate(nonRefundableToDate, {
                    day: "numeric",
                    month: "short",
                  })}
                </h6>
                <h6 className="font-semibold text-contrast-800 text-[10px]">
                  {getFormattedTime(nonRefundableToDate)}
                </h6>
              </div>
            </div>
          </div>
      </div> 
    </div>
  );
};

export default CancellationPolicyInfo;
