import { useDispatch, useSelector } from "react-redux";
import { get, isEmpty } from "lodash";
import { Carousel } from "@material-tailwind/react";
import { v4 as uuid } from "uuid";
import { selectHotelInfo } from "../../../../screens/HotelInfo";
import Modal, { setSelectedModal } from "../../Modal";
import { DEFAULT_VALUES, MODAL_SIZE } from "../../../../constants";

const { EMPTY_STRING, EMPTY_ARRAY, ZERO } = DEFAULT_VALUES;
const { LG } = MODAL_SIZE;

const CarousalImageModal = ({ imageIndex = ZERO }) => {
  const dispatch = useDispatch();
  const hotelInfo = useSelector(selectHotelInfo);
  const hotelDetails = get(
    hotelInfo,
    "HotelRoomInfo.HotelInfoResult.HotelDetails",
    EMPTY_ARRAY
  );
  const { HotelName = EMPTY_STRING, Images = EMPTY_ARRAY } = hotelDetails;

  const handleClose = () => dispatch(setSelectedModal(null));
  const rotateArray = (arr, index) => {
    if (index < ZERO || index >= arr.length) {
      return arr;
    }
    return [...arr.slice(index), ...arr.slice(ZERO, index)];
  };

  const orderedImages = [...rotateArray(Images, imageIndex)];

  return (
    <Modal
      size={LG}
      shouldShowModalFromProps
      handleClose={handleClose}
      shouldCloseOnClickOutside
      shouldShowBorder = {false}
    >
      <div className="font-[inter] overflow-auto font-medium text-xs sm:text-sm text-contrast-900 flex flex-col md:flex-row">
        <div className="w-full">
          <div className="bg-contrast-200 relative h-full">
            {!isEmpty(orderedImages) && (
              <Carousel className="" navigation={() => ""}>
                {orderedImages.map((imageUrl) => (
                  <div className="h-full w-full" key={uuid()}>
                    <img
                      src={imageUrl}
                      alt={HotelName}
                      className="h-full w-full object-cover"
                    />
                    <div className="absolute bottom-2 left-2 text-white font-bold text-base">
                      {Images.length} Images
                    </div>
                  </div>
                ))}
              </Carousel>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CarousalImageModal;
