import { DEFAULT_VALUES } from "../constants";

const { EMPTY_OBJECT, EMPTY_STRING } = DEFAULT_VALUES;

export const mapTravelerReqBody = ({
  profileDetails,
  passportDetails,
  isPrimary,
}) => {
  const {
    firstName: firstname,
    middleName: middlename,
    lastName: lastname,
    dateOfBirth: dateofbirth,
    gender,
    phoneNumber: phonenumber,
    email,
    title,
    nationality,
  } = profileDetails || EMPTY_OBJECT;
  const { passport: passportnumber, issueCountry: country = EMPTY_OBJECT } =
    passportDetails || EMPTY_OBJECT;
  const { isoCode: countrycode, name: countryname } = country;
  const formattedPhoneNumber = phonenumber
    ? `+${phonenumber.replaceAll("+", "")}`
    : EMPTY_STRING;

  const getNationality = () => {
    if (typeof nationality == "object") return nationality.isoCode;
    else if (typeof nationality === "string") return nationality;
    else return EMPTY_STRING;
  };

  return {
    title,
    firstname,
    middlename,
    lastname,
    dateofbirth,
    passportnumber,
    email,
    gender,
    primary: isPrimary,
    countrycode,
    countryname,
    nationality: getNationality(),
    phonenumber: formattedPhoneNumber,
  };
};
