import { createSlice } from "@reduxjs/toolkit";
import {
  fetchSpecialServices,
  initiateBooking,
  bookCallback,
  getPaymentMethod,
  getPromoCodes,
  reissuanceCallback,
} from "./index";
import { DEFAULT_VALUES } from "../../../constants";
import { getFormattedSpecialServicesData } from "../../../ResponseMapper";
import { get } from "lodash";

const { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } = DEFAULT_VALUES;

const initialState = {
  selectedTravelers: EMPTY_ARRAY,
  bookingTravelers: EMPTY_ARRAY,
  cancellationStatus: EMPTY_STRING,
  flightBookingInfo: null,
  promoCodes: EMPTY_ARRAY,
  selectedPromoCode: EMPTY_OBJECT,
  bookCallbackResponse: EMPTY_OBJECT,
  specialServices: EMPTY_ARRAY,
  totalSSRPrice: null,
  gstDetails: null,
  contactDetails: null,
  addressDetails: null,
  SSRDefaultValuesLCC: EMPTY_OBJECT,
  selectedLCCSeats: EMPTY_OBJECT,
  selectedLCCBaggages: EMPTY_OBJECT,
  selectedLCCMeals: EMPTY_OBJECT,
  selectedNonLCCMeal: EMPTY_OBJECT,
  selectedNonLCCSeats: EMPTY_OBJECT,
  flightBookingReqBody: EMPTY_OBJECT,
  paymentMethod: null,
};

const slice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    setBookingTravelers(state, action) {
      state.bookingTravelers = action.payload;
    },
    setCancellationStatus(state, action) {
      state.cancellationStatus = action.payload;
    },
    setSelectedTravelers(state, action) {
      state.selectedTravelers = action.payload;
    },
    setselectedLCCSeats(state, action) {
      state.selectedLCCSeats = action.payload;
    },
    setPromoCode(state, action) {
      state.selectedPromoCode = action.payload;
    },
    setSelectedLCCMeals(state, action) {
      state.selectedLCCMeals = action.payload;
    },
    setSelectedNonLCCMeals(state, action) {
      state.selectedNonLCCMeal = action.payload;
    },
    setSelectedLCCBaggages(state, action) {
      state.selectedLCCBaggages = action.payload;
    },
    setTotalSSRPrice(state, action) {
      state.totalSSRPrice = action.payload;
    },
    clearSelectedValues(state, action) {
      state.selectedTravelers = EMPTY_ARRAY;
      state.selectedLCCSeats = EMPTY_OBJECT;
      state.selectedNonLCCMeal = EMPTY_OBJECT;
      state.selectedLCCMeals = EMPTY_OBJECT;
      state.selectedLCCBaggages = EMPTY_OBJECT;
      state.selectedPromoCode = EMPTY_OBJECT;
      state.selectedNonLCCSeats = EMPTY_OBJECT;
    },
    setSpecialServices(state, action) {
      state.specialServices = action.payload;
    },
    setBookCallbackResponse(state, action) {
      state.bookCallbackResponse = action.payload;
    },
    setGstDetails(state, action) {
      state.gstDetails = action.payload;
    },
    setContactDetails(state, action) {
      state.contactDetails = action.payload;
    },
    setAddressDetails(state, action) {
      state.addressDetails = action.payload;
    },
    setFlightBooking(state, action) {
      if (action.payload) return action.payload;
    },
    setSelectedNonLCCSeats(state, action) {
      state.selectedNonLCCSeats = action.payload;
    },
    setFlightBookingReqBody(state, action) {
      state.flightBookingReqBody = action.payload;
    },
    setSelectedPaymentMethod(state, action) {
      state.paymentMethod = action.payload;
    },
    removeInvalidPax(state, action) {
      const cloneSelectedPax = state.selectedTravelers;
      const cloneInvalidPax = action.payload;
      const validPax = cloneSelectedPax.filter(
        ({ travelerId }) => !cloneInvalidPax.some((id) => id === travelerId)
      );
      state.selectedTravelers = validPax;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSpecialServices.fulfilled, (state, action) => {
      if (action.payload)
        state.specialServices = getFormattedSpecialServicesData(
          action.payload,
          state
        );
    });
    builder.addCase(initiateBooking.fulfilled, (state, action) => {
      if (action.payload) state.flightBookingInfo = action.payload.output;
    });
    builder.addCase(bookCallback.fulfilled, (state, action) => {
      const { payload } = action;
      if (payload) {
        state.bookCallbackResponse = payload;

        payload.output.length &&
          payload.output.forEach((each) => {
            if (each.hasOwnProperty("output"))
              state.paymentMethod = each.output.paymentMethod;
            else if (each.hasOwnProperty("response")) {
              each.response.length &&
                each.response.forEach((res) => {
                  state.paymentMethod = res.paymentMethod;
                });
            }
          });
      }
    });
    builder.addCase(reissuanceCallback.fulfilled, (state, action) => {
      if (action.payload) state.bookCallbackResponse = action.payload.output;
    });
    builder.addCase(getPromoCodes.fulfilled, (state, action) => {
      if (action.payload) state.promoCodes = action.payload;
    });
    builder.addCase(getPaymentMethod.fulfilled, (state, action) => {
      const { payload } = action;
      if (payload) {
        const items = get(payload, 'paymentResponse.items')
        items.length &&
          items.forEach(
            (each) => (state.paymentMethod = each.method)
          );
      }
    });
  },
});

export default slice;

export const { name, reducer, actions } = slice;
