import { useState } from "react";
import { Tooltip } from "react-tooltip";
import { useSelector } from "react-redux";
import { get, isEmpty } from "lodash";
import classNames from "classnames";
import { selectCountryInfo } from "../../../../screens/Profile";
import {
  formatPriceWithCommas,
  getHotelImageUrl,
  renderStars,
} from "../../../../helper";
import { Check, InfoIcon, RenderSVG } from "../../../../assets/icons";
import { CURRENCY_SYMBOLS, DEFAULT_VALUES } from "../../../../constants";

const { INR } = CURRENCY_SYMBOLS;
const { ZERO, TWO, THREE } = DEFAULT_VALUES;

const HotelCard = ({
  hotel = {},
  handleSelectHotel = () => {},
  defaulSelectedHotel = {},
  isToolTip = false,
}) => {
  const selectedCountryInfo = useSelector(selectCountryInfo);
  const [imageError, setImageError] = useState(false);
  const {
    hotelCode,
    hotelName,
    starRating,
    price: { otherCharges, tax, roomPrice, tavaMarkup },
    hotelPicture,
    hotelImages,
    hotelAmenities,
    userRatings,
    hotelReviewCount,
    freeBreakfast,
    freeCancellation,
    payAtHotel,
  } = hotel;
  const displayedAmenities = hotelAmenities?.slice(ZERO, THREE);
  const totaltax = otherCharges + tax;
  const currencySymbol = get(selectedCountryInfo, "currency.symbol", INR);
  const hotelImage = getHotelImageUrl(hotelImages);

  return (
    <div
      className={classNames(
        "border rounded-lg mb-3 hover:cursor-pointer hover:bg-primary-100 transition duration-400 ease-in-out w-full  relative ",
        {
          "max-w-[250px] max-h-[400px]": isToolTip,
          "shadow-lg bg-primary-100":
            defaulSelectedHotel?.hotelCode === hotelCode,
          "bg-white": defaulSelectedHotel?.hotelCode !== hotelCode,
        }
      )}
      onClick={() => handleSelectHotel(hotel)}
    >
      {freeBreakfast && !isToolTip && (
        <div className='z-10 absolute top-[30px] -left-0.5'>
          <div className='absolute w-[18px] h-[12px] skew-x-[45deg] rotate-45 -bottom-1 -left-0.5 bg-green-800' />
          <div className='relative bg-green-800 text-white/90 text-xs rounded-md rounded-bl-none p-1'>
            Breakfast included
          </div>
        </div>
      )}
      <div className='w-full'>
        <div
          className={classNames("w-full flex", {
            "flex-col lg:flex-row": !isToolTip,
            "flex-col": isToolTip,
          })}
        >
          <div
            className={classNames("flex", {
              "p-2 lg:max-w-[35%] lg:min-w-[35%]": !isToolTip,
            })}
          >
            {imageError ? (
              <div className='flex justify-center items-center rounded-lg h-full w-full bg-black bg-opacity-30 px-4 py-2'>
                <span className='text-white font-bold'>{hotelName}</span>
              </div>
            ) : (
              <img
                src={hotelImage || hotelPicture}
                alt={hotelName}
                onError={() => setImageError(true)}
                className={classNames("h-full w-full object-cover", {
                  "rounded-md": !isToolTip,
                  "max-h-[150px]": isToolTip,
                })}
              />
            )}
          </div>
          <div className='flex flex-col min-w-[65%] px-1'>
            <div
              className={classNames("flex justify-between", {
                "flex-col": isToolTip,
              })}
            >
              <h2
                className={classNames(
                  "font-semibold text-wrap text-start text-primary-600",
                  { "text-lg": !isToolTip, "text-[15px] pt-1": isToolTip }
                )}
              >
                {hotelName}
              </h2>
              {starRating > ZERO && (
                <div
                  className={classNames("text-sm", {
                    "py-1 px-2": !isToolTip,
                  })}
                >
                  {renderStars(starRating)}
                </div>
              )}
            </div>
            <div
              className={classNames("flex items-center gap-1", {
                "pt-2": !isToolTip,
              })}
            >
              {userRatings && (
                <span className='p-2 text-white text-end bg-primary-600 text-sm rounded-md font-medium rounded-bl-none'>
                  {userRatings}
                </span>
              )}
              <div className='flex flex-col'>
                {userRatings && (
                  <span className='ml-1 text-[10px] text-gray-800'>
                    ({formatPriceWithCommas(hotelReviewCount)} reviews)
                  </span>
                )}
              </div>
            </div>

            <div
              className={classNames(
                "flex flex-col sm:flex-row justify-between",
                { "mt-2": !isToolTip }
              )}
            >
              <div>
                <div>
                  {freeCancellation && (
                    <div className='flex gap-2'>
                      <RenderSVG
                        Svg={Check}
                        width='14'
                        className='text-green-700'
                      />
                      <p className='mt-1 text-green-800 font-semibold text-xs'>
                        Free Cancellation
                      </p>
                    </div>
                  )}
                  {payAtHotel && (
                    <div className='flex gap-2'>
                      <RenderSVG
                        Svg={Check}
                        width='14'
                        className='text-green-700'
                      />
                      <p className='mt-1 text-green-800 font-semibold text-xs text-start flex'>
                        No prepayment needed - pay at the property
                      </p>
                    </div>
                  )}
                </div>
                {!isToolTip && (
                  <div className='flex flex-col border-l-2 pt-1 text-start'>
                    {displayedAmenities?.map((amenity, index) => (
                      <div key={amenity} className='text-[10px] px-2'>
                        {amenity}
                        {index === TWO &&
                          !isEmpty(hotelAmenities) &&
                          hotelAmenities.length > THREE && (
                            <span className='text-primary-700 text-[12px] font-medium whitespace-nowrap pl-1'>
                              +{hotelAmenities.length - THREE}
                            </span>
                          )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>

            <div
              className={classNames(
                "flex md:flex-col md:items-end items-start justify-between w-full",
                { "p-2": !isToolTip }
              )}
            >
              <p
                className={classNames("font-semibold", {
                  "text-xl": !isToolTip,
                  "text-base": isToolTip,
                })}
              >
                {currencySymbol}
                {formatPriceWithCommas(roomPrice + tavaMarkup)}
              </p>
              <p className='text-[10px] text-gray-800 text-end'>
                + {currencySymbol} {formatPriceWithCommas(totaltax)} taxes and
                charges
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HotelCard;
