import reducer from "./hotelBooking.reducer";

export const selectSlice = (state) => state[reducer.name];
export const selectLeadGuestInfo = (state) => selectSlice(state).leadGuestInfo;
export const selectOtherGuests = (state) => selectSlice(state).otherGuests;
export const selectHotelBookReqBody = (state) =>
  selectSlice(state).hotelBookReqBody;
export const selectHotelBookRes = (state) => selectSlice(state).hotelBookRes;
export const selectHotelBookCallbackRes = (state) =>
  selectSlice(state).hotelBookCallbackRes;
